import React from 'react';
import { MessageSquare, Calendar, Clock, ArrowUpRight } from 'lucide-react';

const StatsCard = ({ title, value, unit, trend, additionalInfo = {}, icon, borderColor }) => {
  const { text, isPositive } = additionalInfo;
  
  // Determine icon and color based on title
  let cardIcon = icon;
  let cardBorderColor = borderColor || 'border-l-blue-500';
  let iconColor = 'text-blue-500';
  
  if (!icon) {
    if (title.toLowerCase().includes('conversation') || title.toLowerCase().includes('call')) {
      if (title.toLowerCase().includes('minute')) {
        cardIcon = <Clock className="h-4 w-4 text-orange-500" />;
        cardBorderColor = 'border-l-orange-500';
        iconColor = 'text-orange-500';
      } else {
        cardIcon = <MessageSquare className="h-4 w-4 text-blue-500" />;
        cardBorderColor = 'border-l-blue-500';
        iconColor = 'text-blue-500';
      }
    } else if (title.toLowerCase().includes('book')) {
      cardIcon = <Calendar className="h-4 w-4 text-violet-500" />;
      cardBorderColor = 'border-l-violet-500';
      iconColor = 'text-violet-500';
    } else if (title.toLowerCase().includes('minute')) {
      cardIcon = <Clock className="h-4 w-4 text-orange-500" />;
      cardBorderColor = 'border-l-orange-500';
      iconColor = 'text-orange-500';
    }
  }

  return (
    <div className={`bg-white rounded-lg shadow overflow-hidden border-l-4 ${cardBorderColor}`}>
      <div className="flex flex-row items-center justify-between p-4 pb-2 space-y-0">
        <h3 className="text-sm font-medium text-muted-foreground">{title}</h3>
        {cardIcon}
      </div>
      <div className="p-4 pt-0">
        <div className="flex items-baseline gap-1">
          <span className="text-2xl font-semibold">{value}</span>
          <span className="text-sm text-muted-foreground">{unit}</span>
        </div>
        
        {(text || trend) && (
          <div className="mt-3 flex items-center text-xs text-muted-foreground">
            {isPositive && <ArrowUpRight className="mr-1 h-3 w-3 text-emerald-500" />}
            {isPositive && <span className="text-emerald-500 font-medium">{text}</span>}
            {!isPositive && text}
            {trend && <span>{trend}</span>}
          </div>
        )}
      </div>
    </div>
  );
};

export default StatsCard; 