import React from 'react';
import ChatInterface from './ChatInterface';
import CallDetails from './CallDetails';
import { fetchCallAnalytics } from '../../services/vapiService';
import { fetchWhatsAppConversations } from '../../services/whatsappService';
import { fetchPropertyMappings, getCanonicalPropertyName } from '../../services/propertyService';
import { getReasonColor, tagColors, getReasonDisplayText } from '../../lib/tagColors';
import { getTypeIcon, getStatusIcon, getTypeColor, getStatusColor } from '../../lib/statusIcons';
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from "../ui/select";
import { Button } from "../ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../ui/popover";
import { Checkbox } from "../ui/checkbox";
import { useLocation } from 'react-router-dom';
import { LoadingSpinner } from '../ui/loading';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../ui/command";
import { Filter, Search, X, Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../lib/utils";

const getTagColor = (type, value) => {
  if (type === 'reason_of_call') {
    return getReasonColor(value);
  } else if (type === 'callback') {
    return 'bg-red-100 text-red-700';
  } else if (type === 'forwarded') {
    return 'bg-amber-100 text-amber-700';
  } else if (type === 'call_outcome') {
    if (value === 'Confirmed') {
      return 'bg-emerald-100 text-emerald-700';
    } else if (value === 'Cancelled') {
      return 'bg-red-100 text-red-700';
    } else {
      return 'bg-amber-100 text-amber-700';
    }
  }
  return tagColors[type] || tagColors.call_outcome[value] || tagColors.call_outcome.default;
};

const ConversationsScreen = () => {
  const [selectedCall, setSelectedCall] = React.useState(null);
  const location = useLocation();
  const [calls, setCalls] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [selectedAssistant, setSelectedAssistant] = React.useState('inbound');
  const [propertyMappings, setPropertyMappings] = React.useState(null);
  const [limit, setLimit] = React.useState(100);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [selectedFilters, setSelectedFilters] = React.useState({
    property: [],
    reason: [],
    outcome: [],
    forwarded: [],
    callback: [],
    channel: [],
    media: []
  });
  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [showDropdown, setShowDropdown] = React.useState(false);

  // Keep track of navigation state
  const navigationRef = React.useRef({
    isNavigating: false,
    selectedCallId: null
  });

  // Handle incoming selected call from notifications
  React.useEffect(() => {
    const navigationState = location.state;
    if (navigationState?.selectedCall && !navigationRef.current.isNavigating) {
      navigationRef.current.isNavigating = true;
      navigationRef.current.selectedCallId = navigationState.selectedCall.id;

      const loadCallData = async () => {
        try {
          setLoading(true);
          setSelectedAssistant(navigationState.assistantType);
          
          // Keep the navigation call visible while loading
          setSelectedCall(navigationState.selectedCall);
          
          // Fetch data
          let callsData;
          if (navigationState.assistantType === 'text') {
            callsData = await fetchWhatsAppConversations(100, 0);
            callsData.calls = callsData.calls.map(call => ({
              ...call,
              channel: 'whatsapp',
              platform: 'whatsapp'
            }));
          } else {
            callsData = await fetchCallAnalytics(navigationState.assistantType);
          }
          
          // Find the fresh call data
          const freshCall = callsData.calls.find(c => c.id === navigationState.selectedCall.id);
          
          // Update the states in the correct order
          setCalls(callsData.calls);
          if (freshCall) {
            setSelectedCall(freshCall);
          }
          
          setLoading(false);
        } catch (err) {
          console.error('Error loading call:', err);
          // On error, keep the navigation call visible
          setLoading(false);
        } finally {
          navigationRef.current.isNavigating = false;
        }
      };
      
      loadCallData();
    }
  }, [location.state?.selectedCall?.id]); // Only run when the selected call ID changes

  // Function to load data (for polling and load more)
  const loadData = async (isLoadingMore = false, isPolling = false, currentLimit = limit) => {
    try {
      if (isLoadingMore) {
        setLoadingMore(true);
      } else if (!isPolling) {
        setLoading(true);
      }

      let callsData;
      if (selectedAssistant === 'text') {
        callsData = await fetchWhatsAppConversations(currentLimit, 0);
        callsData.calls = callsData.calls.map(call => ({
          ...call,
          channel: 'whatsapp',
          platform: 'whatsapp'
        }));
      } else {
        callsData = await fetchCallAnalytics(selectedAssistant, currentLimit);
      }

      // If we have a selected call, make sure to preserve it
      if (selectedCall) {
        const updatedCall = callsData.calls.find(c => c.id === selectedCall.id);
        if (updatedCall) {
          setSelectedCall(updatedCall);
        }
      }

      setCalls(callsData.calls);

      if (!isPolling) {
        setLoading(false);
      }
      setLoadingMore(false);
    } catch (error) {
      console.error('Error loading data:', error);
      if (!isPolling) {
        setLoading(false);
      }
      setLoadingMore(false);
    }
  };

  const filteredCalls = React.useMemo(() => {
    // Don't filter during navigation or if we have a selected call
    if (navigationRef.current.isNavigating || selectedCall) {
      return calls;
    }

    // First filter out any calls that have been deleted (no messages)
    const callsWithMessages = calls.filter(call => call.messages && call.messages.length > 0);
    
    // Then filter out calls without a summary
    const validCalls = callsWithMessages.filter(call => call.summary && call.summary.trim() !== '');
    
    if (!Object.values(selectedFilters).some(arr => arr.length > 0)) return validCalls;
    
    return validCalls.filter(call => {
      const propertyMatch = selectedFilters.property.length === 0 || 
        selectedFilters.property.includes(call.analysis?.structuredData?.property_name?.toLowerCase());

      const statusMatch = selectedFilters.forwarded.length === 0 || 
        (selectedFilters.forwarded.includes('forwarded') && call.endedReason === "assistant-forwarded-call") ||
        (selectedFilters.forwarded.includes('callback') && call.requiresCallback);

      if (selectedAssistant === 'inbound') {
        const reasonMatch = selectedFilters.reason.length === 0 ||
          selectedFilters.reason.includes(call.analysis?.structuredData?.reason_of_call?.toLowerCase());
        return propertyMatch && reasonMatch && statusMatch;
      } else if (selectedAssistant === 'outbound') {
        const outcomeMatch = selectedFilters.outcome.length === 0 ||
          selectedFilters.outcome.includes(call.analysis?.structuredData?.call_outcome?.toLowerCase());
        return propertyMatch && outcomeMatch && statusMatch;
      } else if (selectedAssistant === 'text') {
        const reasonMatch = selectedFilters.reason.length === 0 ||
          selectedFilters.reason.includes(call.analysis?.structuredData?.reason_of_call?.toLowerCase());

        const mediaMatch = selectedFilters.media.length === 0 ||
          call.messages.some(msg => 
            selectedFilters.media.includes(msg.mediaType?.toLowerCase())
          );

        return propertyMatch && reasonMatch && statusMatch && mediaMatch;
      }
      return false;
    });
  }, [calls, selectedFilters, selectedAssistant, selectedCall]);

  // Generate filter options from actual data
  const filterOptions = React.useMemo(() => {
    // Get unique properties
    const properties = new Set(
      calls
        .filter(call => call.analysis?.structuredData?.property_name)
        .map(call => call.analysis.structuredData.property_name)
    );

    // Get unique reasons/outcomes
    const reasonsOrOutcomes = new Set(
      calls
        .filter(call => {
          if (selectedAssistant === 'inbound') {
            return call.analysis?.structuredData?.reason_of_call;
          } else if (selectedAssistant === 'text') {
            return call.analysis?.structuredData?.reason_of_call;
          }
          return call.analysis?.structuredData?.call_outcome;
        })
        .map(call => {
          if (selectedAssistant === 'inbound' || selectedAssistant === 'text') {
            return call.analysis.structuredData.reason_of_call.toLowerCase();
          }
          return call.analysis.structuredData.call_outcome.toLowerCase();
        })
    );

    const forwardedFilter = {
      type: 'forwarded',
      label: selectedAssistant === 'text' ? 'Message Status' : 'Call Status',
      options: [
        {
          id: 'callback',
          label: 'Callback Required'
        },
        ...(selectedAssistant !== 'text' ? [
          {
            id: 'forwarded',
            label: 'Forwarded'
          }
        ] : [])
      ]
    };

    const mediaFilter = {
      type: 'media',
      label: 'Media Type',
      options: [
        {
          id: 'image',
          label: 'Images'
        },
        {
          id: 'video',
          label: 'Videos'
        },
        {
          id: 'document',
          label: 'Documents'
        }
      ]
    };

    const baseFilters = {
      property: {
        type: 'property',
        label: 'Property',
        options: Array.from(properties).map(property => ({
          id: property.toLowerCase(),
          label: property
        }))
      },
      forwarded: forwardedFilter
    };

    return {
      inbound: [
        {
          type: 'reason',
          label: 'Reason for Call',
          options: Array.from(reasonsOrOutcomes).map(reason => ({
            id: reason,
            label: reason.charAt(0).toUpperCase() + reason.slice(1)
          })),
        },
        baseFilters.property,
        baseFilters.forwarded
      ],
      outbound: [
        {
          type: 'outcome',
          label: 'Call Outcome',
          options: Array.from(reasonsOrOutcomes).map(outcome => ({
            id: outcome,
            label: outcome.charAt(0).toUpperCase() + outcome.slice(1)
          })),
        },
        baseFilters.property,
        baseFilters.forwarded
      ],
      text: [
        {
          type: 'reason',
          label: 'Purpose',
          options: Array.from(reasonsOrOutcomes).map(reason => ({
            id: reason,
            label: reason.charAt(0).toUpperCase() + reason.slice(1)
          })),
        },
        baseFilters.property,
        baseFilters.forwarded,
        mediaFilter
      ]
    };
  }, [calls, selectedAssistant]);

  // Add this helper function at the top of the component
  const groupConversationsByDate = (conversations) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const thisWeek = new Date(today);
    thisWeek.setDate(thisWeek.getDate() - 7);
    
    return conversations.reduce((groups, call) => {
      const callDate = new Date(call.startedAt);
      
      if (callDate >= today) {
        if (!groups.today) groups.today = [];
        groups.today.push(call);
      } else if (callDate >= thisWeek) {
        if (!groups.thisWeek) groups.thisWeek = [];
        groups.thisWeek.push(call);
      } else {
        if (!groups.older) groups.older = [];
        groups.older.push(call);
      }
      
      return groups;
    }, {});
  };

  // Add this helper function near the top of the component
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);
    
    // Time in 12-hour format
    const timeString = date.toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit',
      hour12: true 
    });

    // If it's today
    if (date.toDateString() === now.toDateString()) {
      return `Today at ${timeString}`;
    }
    
    // If it's yesterday
    if (date.toDateString() === yesterday.toDateString()) {
      return `Yesterday at ${timeString}`;
    }
    
    // If it's this week
    const isThisWeek = date >= new Date(now.setDate(now.getDate() - 7));
    if (isThisWeek) {
      return `${date.toLocaleDateString([], { weekday: 'long' })} at ${timeString}`;
    }
    
    // If it's this year
    if (date.getFullYear() === now.getFullYear()) {
      return date.toLocaleDateString([], { 
        month: 'short',
        day: 'numeric',
      }) + ` at ${timeString}`;
    }
    
    // If it's older
    return date.toLocaleDateString([], { 
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }) + ` at ${timeString}`;
  };

  // Add this helper function near the top where other helper functions are
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    // Remove any non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, '');
    // Format as +XX XXXX XXXXXX
    return `+${cleaned.slice(0, 2)} ${cleaned.slice(2, 6)} ${cleaned.slice(6)}`;
  };

  // Handle loading more conversations
  const handleLoadMore = () => {
    const newLimit = limit + 100;
    setLimit(newLimit);
    loadData(true, false, newLimit);
  };

  // Setup polling when component mounts
  React.useEffect(() => {
    // Initial load
    if (!navigationRef.current.isNavigating) {
      loadData();
    }

    // Setup polling every 30 seconds
    const interval = setInterval(() => {
      if (!navigationRef.current.isNavigating) {
        loadData(false, true);
      }
    }, 30000);

    // Cleanup on unmount
    return () => clearInterval(interval);
  }, [selectedAssistant]);

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-red-500">Error: {error}</div>;

  if (selectedCall) {
    // Check if the call still exists in our data
    const callExists = calls.find(call => call.id === selectedCall.id);
    if (!callExists) {
      // If call doesn't exist anymore, go back to the list
      setSelectedCall(null);
      return null;
    }
    
    return (
      <div className="flex h-full">
        {/* Main chat area */}
        <div className="flex-1 overflow-hidden">
          <div className="flex flex-col h-full">
            <div className="p-4 border-b flex items-center">
              <button 
                onClick={() => setSelectedCall(null)}
                className="mr-4 text-gray-600 hover:text-gray-900"
              >
                ← Back
              </button>
              <h2 className="text-xl font-bold">Conversation Details</h2>
            </div>
            <ChatInterface call={selectedCall} />
          </div>
        </div>

        {/* Right sidebar */}
        <div className="w-96 border-l bg-white flex-shrink-0 overflow-hidden">
          <CallDetails call={selectedCall} />
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="bg-white rounded-xl shadow-sm border">
        <div className="p-4 border-b">
          <h2 className="text-xl font-bold">Conversations</h2>
        </div>
        
        {/* Assistant selector and filters section */}
        <div className="p-4 space-y-4 border-b">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Assistant
          </label>
          <Select
            value={selectedAssistant}
            onValueChange={(value) => {
              setSelectedAssistant(value);
              setSelectedFilters({
                property: [],
                reason: [],
                outcome: [],
                forwarded: [],
                callback: [],
                channel: [],
                media: []
              });
            }}
          >
            <SelectTrigger className="w-full bg-white">
              <SelectValue placeholder="Select assistant type" />
            </SelectTrigger>
            <SelectContent className="bg-white">
              <SelectItem value="inbound">Inbound</SelectItem>
              <SelectItem value="outbound">Outbound</SelectItem>
              <SelectItem value="text">WhatsApp</SelectItem>
            </SelectContent>
          </Select>

          <div className="flex items-center gap-4">
            <div className="w-72">
              <div className="relative">
                <div className="flex items-center border rounded-lg bg-white">
                  <Search className="h-4 w-4 ml-3 text-gray-500" />
                  <input
                    type="text"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      if (!e.target.value) {
                        setSelectedFilters(prev => ({ ...prev, property: [] }));
                      }
                      setShowDropdown(true);
                    }}
                    onFocus={() => setShowDropdown(true)}
                    onBlur={() => {
                      setTimeout(() => setShowDropdown(false), 200);
                    }}
                    placeholder="Search properties..."
                    className="w-full p-2 bg-transparent border-none focus:outline-none text-sm"
                  />
                  {searchValue && (
                    <button
                      onClick={() => {
                        setSearchValue('');
                        setSelectedFilters(prev => ({ ...prev, property: [] }));
                        setShowDropdown(false);
                      }}
                      className="p-2 hover:text-gray-600"
                    >
                      <X className="h-4 w-4" />
                    </button>
                  )}
                </div>
                {showDropdown && (
                  <div className="absolute w-full mt-1 bg-white rounded-lg border shadow-md max-h-[300px] overflow-y-auto z-50">
                    {filterOptions[selectedAssistant][1].options
                      .filter(option => !searchValue || 
                        option.label
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      )
                      .map(option => (
                        <div
                          key={option.id}
                          className="flex items-center px-3 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            setSelectedFilters(prev => ({
                              ...prev,
                              property: [option.id]
                            }));
                            setSearchValue(option.label);
                            setShowDropdown(false);
                          }}
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              selectedFilters.property.includes(option.id)
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                          {option.label}
                        </div>
                      ))}
                    {filterOptions[selectedAssistant][1].options
                      .filter(option => !searchValue || 
                        option.label
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      ).length === 0 && (
                      <div className="px-3 py-2 text-sm text-gray-500">
                        No property found.
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" size="sm" className="flex items-center gap-2">
                  <Filter className="h-4 w-4" />
                  Filters
                  {Object.values(selectedFilters).some(arr => arr.length > 0) && (
                    <span className="ml-2 bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded-full">
                      {Object.entries(selectedFilters)
                        .filter(([key]) => key !== 'property')
                        .reduce((total, [_, arr]) => total + arr.length, 0)}
                    </span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-72 p-4">
                <div className="space-y-6">
                  {filterOptions[selectedAssistant]
                    .filter(group => group.type !== 'property')
                    .map((filterGroup) => (
                      <div key={filterGroup.type} className="space-y-3">
                        <h4 className="font-medium">{filterGroup.label}</h4>
                        <div className="space-y-2">
                          {filterGroup.options.map((option) => (
                            <div key={option.id} className="flex items-center space-x-2">
                              <Checkbox
                                id={option.id}
                                checked={selectedFilters[filterGroup.type].includes(option.id)}
                                onCheckedChange={(checked) => {
                                  if (checked) {
                                    setSelectedFilters({
                                      ...selectedFilters,
                                      [filterGroup.type]: [...selectedFilters[filterGroup.type], option.id]
                                    });
                                  } else {
                                    setSelectedFilters({
                                      ...selectedFilters,
                                      [filterGroup.type]: selectedFilters[filterGroup.type].filter(f => f !== option.id)
                                    });
                                  }
                                }}
                              />
                              <label
                                htmlFor={option.id}
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>

        {/* Conversations list */}
        <div className="divide-y divide-gray-100">
          {Object.entries(groupConversationsByDate(filteredCalls)).map(([period, calls]) => (
            <div key={period} className="divide-y divide-gray-100">
              <div className="px-4 py-2 bg-gray-50">
                <h3 className="text-sm font-medium text-gray-600 uppercase">
                  {period === 'today' ? 'Today' : 
                   period === 'thisWeek' ? 'This Week' : 'Older'}
                </h3>
              </div>
              {calls.map((call) => (
                <div
                  key={call.id}
                  className={`p-4 cursor-pointer hover:bg-gray-50/50 transition-colors ${
                    selectedCall?.id === call.id ? 'bg-blue-50/50' : ''
                  }`}
                  onClick={() => setSelectedCall(call)}
                >
                  <div className="flex justify-between items-start mb-2">
                    <div className="flex flex-col">
                      <div className="text-sm font-medium text-gray-900">
                        {formatDateTime(call.startedAt)}
                      </div>
                      {selectedAssistant === 'text' && (
                        <div className="text-xs text-gray-600 font-medium">
                          {formatPhoneNumber(call.phoneNumber)}
                        </div>
                      )}
                    </div>
                    <div className="flex gap-2">
                      {call.analysis?.structuredData?.property_name && (
                        <div className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 ${getTagColor('property_name')}`}>
                          {getTypeIcon('property')}
                          {getCanonicalPropertyName(call.analysis.structuredData.property_name)}
                        </div>
                      )}
                      {call.requiresCallback && (
                        <div className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 ${getTagColor('callback')}`}>
                          {getStatusIcon('required')}
                          Callback required
                        </div>
                      )}
                      {call.endedReason === "assistant-forwarded-call" && (
                        <div className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 ${getTagColor('forwarded')}`}>
                          {getStatusIcon('pending')}
                          Call forwarded
                        </div>
                      )}
                      {selectedAssistant === 'text' && (
                        <>
                          {call.analysis?.structuredData?.reason_of_call && (
                            <div className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 ${getTagColor('reason_of_call', call.analysis.structuredData.reason_of_call)}`}>
                              {call.analysis.structuredData.reason_of_call
                                .split('_')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                .join(' ')
                                .replace(/\?/g, '')}
                            </div>
                          )}
                          {call.analysis?.structuredData?.call_outcome && (
                            <div className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 ${
                              call.analysis.structuredData.call_outcome === 'Viewing Booked' 
                                ? 'bg-emerald-100 text-emerald-700' 
                                : getTagColor('call_outcome', call.analysis.structuredData.call_outcome)
                            }`}>
                              {getStatusIcon(call.analysis.structuredData.call_outcome === 'Viewing Booked' ? 'confirmed' : 'pending')}
                              {call.analysis.structuredData.call_outcome}
                            </div>
                          )}
                        </>
                      )}
                      {selectedAssistant === 'inbound' && call.analysis?.structuredData?.reason_of_call && (
                        <div className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 ${getTagColor('reason_of_call', call.analysis.structuredData.reason_of_call)}`}>
                          {getTypeIcon(call.analysis.structuredData.reason_of_call)}
                          {getReasonDisplayText(call.analysis.structuredData.reason_of_call)}
                        </div>
                      )}
                      {selectedAssistant === 'outbound' && call.analysis?.structuredData?.call_outcome && (
                        <div className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 ${getTagColor('call_outcome', call.analysis.structuredData.call_outcome)}`}>
                          {getStatusIcon(call.analysis.structuredData.call_outcome === 'Confirmed' ? 'confirmed' : 
                                        call.analysis.structuredData.call_outcome === 'Cancelled' ? 'required' : 'pending')}
                          {call.analysis.structuredData.call_outcome}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-sm text-gray-600 line-clamp-2">
                    {call.summary}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* Load Earlier button */}
        <div className="p-4 flex justify-center border-t">
          <button
            onClick={handleLoadMore}
            disabled={loadingMore}
            className="px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-800 disabled:text-gray-400 disabled:cursor-not-allowed"
          >
            {loadingMore ? 'Loading...' : 'Load Earlier Conversations'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConversationsScreen; 