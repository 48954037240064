import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  LayoutDashboard, 
  MessageSquare, 
  Settings, 
  Bell,
  Users,
  LogOut,
  Link2,
  CreditCard,
  Home
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { Separator } from '../ui/separator';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { usePermissions } from '../../hooks/usePermissions';
import dooraLogo from '../../assets/doora-logo.png';

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const { can } = usePermissions();

  // Add debug logging
  console.log('Sidebar user data:', {
    user,
    photoURL: user?.photoURL,
    email: user?.email
  });

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Failed to sign out:', error);
    }
  };

  // Get initials from name
  const getInitials = (name) => {
    return name
      ?.split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase() || '?';
  };

  const menuItems = [
    {
      path: '/analytics',
      label: 'Analytics',
      icon: LayoutDashboard,
      show: true
    },
    {
      path: '/conversations',
      label: 'Conversations',
      icon: MessageSquare,
      show: true
    },
    {
      path: '/notifications',
      label: 'Notifications',
      icon: Bell,
      show: true
    },
    {
      path: '/properties',
      label: 'Properties',
      icon: Home,
      show: true
    },
    {
      path: '/billing',
      label: 'Billing & Usage',
      icon: CreditCard,
      show: true
    },
    {
      path: '/integrations',
      label: 'Integrations',
      icon: Link2,
      show: true
    },
    {
      path: '/settings',
      label: 'Settings',
      icon: Settings,
      show: true
    }
  ];

  return (
    <div className="w-64 bg-white border-r h-screen flex flex-col flex-shrink-0">
      {/* Logo */}
      <div className="px-6 pt-6 pb-8">
        <img 
          src={dooraLogo}
          alt="Doora" 
          className="h-7 w-auto"
        />
      </div>

      {/* Main Navigation */}
      <nav className="flex-1 px-4">
        {menuItems
          .filter(item => item.show)
          .map(item => (
            <Link
              key={item.path}
              to={item.path}
              className={`flex items-center gap-3 px-3 py-2 rounded-lg mb-1 transition-colors ${
                location.pathname.startsWith(item.path)
                  ? 'bg-blue-50 text-[#1C84D5]'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              <item.icon className="h-5 w-5" />
              <span>{item.label}</span>
            </Link>
          ))}
      </nav>

      {/* Bottom Section */}
      <div className="px-4 pb-6">
        <Separator className="my-4" />
        
        {/* Organization Link */}
        <Link
          to="/organization"
          className={`flex items-center gap-3 px-3 py-2.5 rounded-lg mb-1 transition-colors ${
            location.pathname === '/organization'
              ? 'bg-blue-50 text-[#1C84D5]'
              : 'text-gray-600 hover:bg-gray-100'
          }`}
        >
          <div className="h-8 w-8 bg-gray-100 rounded-full flex items-center justify-center">
            <Users className="h-5 w-5" />
          </div>
          <span>Organization</span>
        </Link>

        {/* User Account Dropdown */}
        <DropdownMenu>
          <DropdownMenuTrigger className="w-full focus:outline-none">
            <div className="flex items-center gap-3 px-3 py-2.5 rounded-lg text-gray-600 hover:bg-gray-100">
              {user?.photoURL ? (
                <>
                  <img 
                    src={user.photoURL || ''} 
                    alt="Profile" 
                    className="h-8 w-8 rounded-full flex-shrink-0 object-cover"
                    crossOrigin="anonymous"
                    referrerPolicy="no-referrer"
                    onError={(e) => {
                      console.error('Failed to load profile image:', {
                        src: e.target.src,
                        error: e,
                        userPhotoURL: user.photoURL
                      });
                      // Fall back to initials
                      e.target.style.display = 'none';
                      const fallbackInitials = e.target.nextElementSibling;
                      if (fallbackInitials) {
                        fallbackInitials.style.display = 'flex';
                      }
                    }}
                  />
                  <div className="h-8 w-8 bg-blue-100 text-[#1C84D5] rounded-full flex-shrink-0 hidden items-center justify-center text-sm font-medium">
                    {getInitials(user?.email)}
                  </div>
                </>
              ) : (
                <div className="h-8 w-8 bg-blue-100 text-[#1C84D5] rounded-full flex-shrink-0 flex items-center justify-center text-sm font-medium">
                  {getInitials(user?.email)}
                </div>
              )}
              <span className="flex-1 text-left truncate">{user?.email || 'User'}</span>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-56">
            <div className="px-2 py-1.5 text-sm text-gray-600 border-b">
              {user?.email || 'User'}
            </div>
            <DropdownMenuItem onClick={handleSignOut} className="text-red-600">
              <LogOut className="h-4 w-4 mr-2" />
              Sign out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default Sidebar; 