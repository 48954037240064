"use client"

import { Card, CardContent } from "../../ui/card.jsx"
import { Textarea } from "../../ui/textarea.jsx"
import { Label } from "../../ui/label.jsx"
import { FileText } from "lucide-react"

export function PropertyNotesTab({ property, isEditing, onChange }) {
  if (!isEditing) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="flex items-start gap-3 mb-4">
            <FileText className="h-5 w-5 text-muted-foreground mt-0.5" />
            <h3 className="text-base font-medium">Additional Notes</h3>
          </div>
          <div className="p-4 border rounded-md min-h-[200px] whitespace-pre-wrap">
            {property.additionalNotes || "No additional notes."}
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card>
      <CardContent className="pt-6 space-y-4">
        <div className="space-y-2">
          <Label htmlFor="notes">Additional Notes</Label>
          <Textarea
            id="notes"
            value={property.additionalNotes}
            onChange={(e) => onChange({ additionalNotes: e.target.value })}
            placeholder="Add any additional notes about the property here..."
            className="min-h-[200px]"
          />
        </div>
      </CardContent>
    </Card>
  )
} 