import { useAuth } from '../../contexts/AuthContext';
import { jwtDecode } from 'jwt-decode';

const AuthDebug = () => {
  const { user } = useAuth();
  const token = localStorage.getItem('token');
  const decodedToken = token ? jwtDecode(token) : null;

  if (process.env.REACT_APP_DEBUG !== 'true') return null;

  return (
    <div className="fixed bottom-4 right-4 p-4 bg-black/80 text-white rounded-lg text-sm">
      <pre>
        User: {JSON.stringify(user, null, 2)}
        {'\n\n'}
        Token Payload: {JSON.stringify(decodedToken, null, 2)}
      </pre>
    </div>
  );
};

export default AuthDebug; 