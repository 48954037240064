import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import AuthDebug from '../Debug/AuthDebug';

const Layout = () => {
  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      <Sidebar />
      <main className="flex-1 overflow-auto">
        <Outlet />
      </main>
      {process.env.NODE_ENV === 'development' && <AuthDebug />}
    </div>
  );
};

export default Layout; 