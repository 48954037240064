const VAPI_API_URL = 'https://api.vapi.ai';
const VAPI_CALL_URL = `${VAPI_API_URL}/call`;
const { fetchPropertyMappings, getCanonicalPropertyName, fetchClientRegistry, fetchCallbackList } = require('./propertyService');

const TOTAL_CREDITS = process.env.REACT_APP_TOTAL_CREDITS || 100;
const VAPI_API_KEY = process.env.REACT_APP_VAPI_API_KEY;

const getOrgConfig = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user?.orgConfig || {};
};

const getAssistantId = (type) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const assistantIds = user?.orgConfig?.AssistantIDs || {};
  
  if (type === 'inbound') {
    return assistantIds.inbound || [];
  }
  
  return assistantIds.outbound;
};

const getAssistantIds = (type) => {
  const { AssistantIDs = {} } = getOrgConfig();
  console.log('Getting assistant IDs:', {
    type,
    allIds: AssistantIDs,
    inboundIds: AssistantIDs.inbound,
    outboundId: AssistantIDs[type]
  });
  if (type === 'inbound') {
    return AssistantIDs.inbound || [];
  }
  return [AssistantIDs[type]].filter(Boolean);
};

const fetchCallAnalytics = async (assistantType = 'all', limit = 100, offset = 0) => {
  try {
    let calls = [];
    const [propertyMappings, clientRegistry, unhandledCallbacks] = await Promise.all([
      fetchPropertyMappings(),
      fetchClientRegistry(),
      fetchCallbackList()
    ]);

    // Helper function to validate property
    const validateProperty = (call, propertyName) => {
      const canonicalName = getCanonicalPropertyName(propertyName);
      // For valuations, check client registry
      if (call.analysis?.structuredData?.reason_of_call === 'Valuation') {
        // Normalize addresses for comparison by removing postal codes and extra spaces
        const normalizedInput = propertyName.toLowerCase()
          .replace(/\s+/g, ' ')  // Replace multiple spaces with single space
          .trim();
        
        // Check each address in registry
        for (const [registryAddress, originalAddress] of clientRegistry.entries()) {
          const normalizedRegistry = registryAddress
            .toLowerCase()
            .replace(/,.*$/, '')  // Remove everything after comma (usually postal code)
            .replace(/\s+/g, ' ')
            .trim();
          
          if (normalizedInput === normalizedRegistry) {
            return originalAddress;
          }
        }
        return undefined;
      }
      // For other cases, check property listings
      return (propertyMappings && Array.from(propertyMappings.values()).includes(canonicalName)) 
        ? canonicalName 
        : undefined;
    };

    // Helper function to check if callback is required
    const needsCallback = (call) => {
      // Check if the call ID is in the unhandled callbacks list
      const isInCallbackList = unhandledCallbacks.has(call.id);
      
      // Check if the call was forwarded (and not a wrong number)
      const isForwardedCall = call.endedReason === "assistant-forwarded-call" && 
        call.analysis?.structuredData?.reason_of_call !== "Wrong Number";
      
      return isInCallbackList || isForwardedCall;
    };

    // Get our organization's assistant IDs
    const ourInboundAssistantIds = getAssistantIds('inbound');
    const ourOutboundAssistantId = getAssistantId('outbound');
    
    // Fetch all calls with phoneNumberId and pagination
    const phoneNumberId = '4e5fec14-4a14-4c38-85b1-d0728b989506'; // Will be made configurable later
    const queryParams = new URLSearchParams({
      phoneNumberId,
      limit: limit.toString()
    });
    
    const allCallsResponse = await fetch(
      `${VAPI_CALL_URL}?${queryParams.toString()}`,
      {
        headers: {
          'Authorization': `Bearer ${VAPI_API_KEY}`,
          'Content-Type': 'application/json'
        }
      }
    );
    
    if (allCallsResponse.ok) {
      const allCalls = await allCallsResponse.json();
      
      // Filter calls that belong to our organization
      const ourCalls = allCalls.filter(call => {
        // Skip calls without messages
        if (!call.messages || call.messages.length === 0) {
          return false;
        }
        
        // Check for squad in the call object
        if (call.squad?.members && call.squad.members.length > 0) {
          // Mark as squad call immediately
          call.isSquadCall = true;
          
          // Check if any squad member's assistantId matches our inbound IDs
          const isOurSquadCall = call.squad.members.some(member => 
            ourInboundAssistantIds.includes(member.assistantId)
          );
          
          if (isOurSquadCall) {
            // Find the first matching assistant ID to use for grouping
            const matchingMember = call.squad.members.find(member => 
              ourInboundAssistantIds.includes(member.assistantId)
            );
            if (matchingMember) {
              call.matchedAssistantId = matchingMember.assistantId;
            }
            return assistantType === 'all' || assistantType === 'inbound';
          }
          
          // If the call has a squad but no assistantId, check if any squad member matches our assistants
          if (!call.assistantId) {
            // First check for specific call IDs we know should be included
            const knownSquadCallIds = [
              'be84c907-2f4a-4103-beb7-95a42b8d9ab4',
              '9f502b1c-6f6e-42fe-8bf2-8c9c34a9e3b1',
              '44f61044-a346-4780-82c6-6df9a63434f9',
              'b0d467dc-db95-4440-a78f-e63c1603664f',
              'ab0212f7-86ed-44a3-9b9e-37bc07e01c86',
              'b426a2d8-5adf-4e2e-9da9-86ea9d2046ed',
              '8c94dd95-9171-4c0c-88f5-edf4378d7811'
            ];
            
            if (knownSquadCallIds.includes(call.id)) {
              // Use the first squad member's assistantId for grouping
              if (call.squad.members[0]) {
                call.matchedAssistantId = call.squad.members[0].assistantId;
              }
              return assistantType === 'all' || assistantType === 'inbound';
            }
            
            // For any squad call with undefined assistantId, check if it has our assistants in the squad
            // This is a more general approach to include all squad calls
            for (const member of call.squad.members) {
              // If any member has an assistantId that matches one of our inbound assistants
              if (member.assistantId && ourInboundAssistantIds.includes(member.assistantId)) {
                call.matchedAssistantId = member.assistantId;
                return assistantType === 'all' || assistantType === 'inbound';
              }
              
              // If any member has an assistantDestination that mentions our assistants
              if (member.assistantDestinations) {
                for (const destination of member.assistantDestinations) {
                  // Check if the destination assistant name contains any of our known assistant names
                  // This is a fallback if assistantId matching doesn't work
                  const knownAssistantNames = ['Ben', 'Bookings', 'Register', 'Squads'];
                  if (destination.assistantName && 
                      knownAssistantNames.some(name => destination.assistantName.includes(name))) {
                    call.matchedAssistantId = ourInboundAssistantIds[0]; // Use first inbound ID for grouping
                    return assistantType === 'all' || assistantType === 'inbound';
                  }
                }
              }
            }
          }
        }
        
        // Fall back to old method for inbound calls
        if (ourInboundAssistantIds.includes(call.assistantId)) {
          return assistantType === 'all' || assistantType === 'inbound';
        }
        
        // Check for outbound calls
        if (call.assistantId === ourOutboundAssistantId) {
          return assistantType === 'all' || assistantType === 'outbound';
        }
        
        return false;
      });
      
      // Process our calls
      const processedCalls = ourCalls.map(call => {
        const requiresCallback = needsCallback(call);
        
        // Process property name if it exists
        if (call.analysis?.structuredData?.property_name) {
          const validatedProperty = validateProperty(call, call.analysis.structuredData.property_name);
          if (validatedProperty) {
            return {
              ...call,
              requiresCallback,
              analysis: {
                ...call.analysis,
                structuredData: {
                  ...call.analysis.structuredData,
                  property_name: validatedProperty
                }
              }
            };
          }
          // If property is invalid, remove it from structuredData
          return {
            ...call,
            requiresCallback,
            analysis: {
              ...call.analysis,
              structuredData: {
                ...call.analysis.structuredData,
                property_name: undefined
              }
            }
          };
        }
        return { ...call, requiresCallback };
      });
      
      calls = [...calls, ...processedCalls];
    }

    // If we still need to fetch outbound calls separately (fallback)
    if ((assistantType === 'all' || assistantType === 'outbound') && 
        !calls.some(call => call.assistantId === ourOutboundAssistantId)) {
      
      // Fetch outbound calls directly
      const outboundResponse = await fetch(
        `${VAPI_CALL_URL}?assistantId=${ourOutboundAssistantId}`,
        {
          headers: {
            'Authorization': `Bearer ${VAPI_API_KEY}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (outboundResponse.ok) {
        const outboundData = await outboundResponse.json();
        
        // Filter and process outbound calls
        const validOutboundCalls = outboundData
          .filter(call => call.messages && call.messages.length > 0)
          .map(call => {
            const requiresCallback = needsCallback(call);
            if (call.analysis?.structuredData?.property_name) {
              const validatedProperty = validateProperty(call, call.analysis.structuredData.property_name);
              if (validatedProperty) {
                return {
                  ...call,
                  requiresCallback,
                  analysis: {
                    ...call.analysis,
                    structuredData: {
                      ...call.analysis.structuredData,
                      property_name: validatedProperty
                    }
                  }
                };
              }
              // If property is invalid, remove it from structuredData
              return {
                ...call,
                requiresCallback,
                analysis: {
                  ...call.analysis,
                  structuredData: {
                    ...call.analysis.structuredData,
                    property_name: undefined
                  }
                }
              };
            }
            return { ...call, requiresCallback };
          });
        
        calls = [...calls, ...validOutboundCalls];
      }
    }

    // Sort all calls by date, most recent first
    calls.sort((a, b) => {
      const dateA = new Date(a.startedAt);
      const dateB = new Date(b.startedAt);
      return dateB - dateA;
    });

    return {
      calls,
      processed: processAnalyticsData(calls)
    };
  } catch (error) {
    console.error('Error in fetchCallAnalytics:', error);
    throw error;
  }
};

const processAnalyticsData = (calls) => {
  // Group calls by assistant type
  const groupedCalls = calls.reduce((acc, call) => {
    // For squad calls, use the matchedAssistantId
    const assistantId = call.matchedAssistantId || call.assistantId;
    const type = getAssistantIds('inbound').includes(assistantId) || call.isSquadCall ? 'inbound' : 'outbound';
    
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(call);
    return acc;
  }, {});

  // Process tool calls
  const toolCalls = {};
  const reasonsForCall = {};
  const callOutcomes = {};
  let inboundMinutes = 0;
  let outboundMinutes = 0;
  let totalCreditsUsed = 0;
  const totalMinutes = calculateTotalMinutes(calls);

  // Process each group
  const processed = {
    totalCalls: calls.length,
    totalMinutes: totalMinutes,
    totalCost: calculateTotalCost(calls),
    averageCostPerCall: calculateAverageCost(calls),
    callsOverTime: processCallsOverTime(calls),
    minutesOverTime: processMinutesOverTime(calls),
    costsOverTime: processCostsOverTime(calls),
    callDetails: processCallDetails(calls),
    toolCalls: {},
    inboundMinutes: 0,
    outboundMinutes: 0,
    totalCreditsUsed: 0,
    remainingCredits: TOTAL_CREDITS,
    reasonsForCall: {},
    callOutcomes: {},
    creditEfficiency: null,
    inboundCalls: 0,
    outboundCalls: 0
  };

  for (const [type, typeCalls] of Object.entries(groupedCalls)) {
    // Track call counts
    if (type === 'inbound') {
      processed.inboundCalls = typeCalls.length;
    } else if (type === 'outbound') {
      processed.outboundCalls = typeCalls.length;
    }

    const byDate = groupByDate(typeCalls);
    const minutes = typeCalls.reduce((acc, call) => {
      if (call.startedAt && call.endedAt) {
        const duration = (new Date(call.endedAt) - new Date(call.startedAt)) / 1000 / 60;
        if (type === 'inbound') inboundMinutes += duration;
        else outboundMinutes += duration;
        
        // Calculate credits
        let callCredits = duration;
        if (call.messages) {
          call.messages.forEach(entry => {
            if (entry.role === 'tool_calls' && entry.toolCalls) {
              callCredits += entry.toolCalls.length * 0.5;
              entry.toolCalls.forEach(toolCall => {
                if (toolCall.function && toolCall.function.name) {
                  toolCalls[toolCall.function.name] = (toolCalls[toolCall.function.name] || 0) + 1;
                }
              });
            }
          });
        }
        totalCreditsUsed += callCredits;
        
        // Process reasons/outcomes
        if (call.analysis?.structuredData) {
          if (type === 'inbound' && call.analysis.structuredData.reason_of_call) {
            let reason = call.analysis.structuredData.reason_of_call;
            
            // Skip unknown calls
            if (reason.toLowerCase() === 'unknown') {
              return acc + duration;
            }
            
            // Normalize case for all reasons - capitalize first letter of each word
            reason = reason
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ');
            
            reasonsForCall[reason] = (reasonsForCall[reason] || 0) + 1;
          }
          if (type === 'outbound' && call.analysis.structuredData.call_outcome) {
            const outcome = call.analysis.structuredData.call_outcome;
            callOutcomes[outcome] = (callOutcomes[outcome] || 0) + 1;
          }
        }
        return acc + duration;
      }
      return acc;
    }, 0);
  }

  processed.toolCalls = toolCalls;
  processed.inboundMinutes = parseFloat(inboundMinutes.toFixed(2));
  processed.outboundMinutes = parseFloat(outboundMinutes.toFixed(2));
  processed.totalCreditsUsed = parseFloat(totalCreditsUsed.toFixed(2));
  processed.remainingCredits = parseFloat((TOTAL_CREDITS - totalCreditsUsed).toFixed(2));
  processed.reasonsForCall = reasonsForCall;
  processed.callOutcomes = callOutcomes;
  processed.creditEfficiency = calls.length > 0 ? {
    perCall: parseFloat((totalCreditsUsed / calls.length).toFixed(2)),
    perMinute: parseFloat((totalCreditsUsed / totalMinutes).toFixed(2))
  } : null;

  return processed;
};

const calculateTotalMinutes = (calls) => {
  return calls.reduce((total, call) => {
    if (!call.endedAt || !call.startedAt) return total;
    const duration = new Date(call.endedAt) - new Date(call.startedAt);
    return total + (duration / 1000 / 60); // Convert to minutes
  }, 0).toFixed(2);
};

const calculateTotalCost = (calls) => {
  return calls.reduce((total, call) => {
    const costBreakdown = call.costBreakdown || {};
    return total + (costBreakdown.total || 0);
  }, 0).toFixed(2);
};

const calculateAverageCost = (calls) => {
  if (calls.length === 0) return "0.00";
  return (calculateTotalCost(calls) / calls.length).toFixed(2);
};

const processCallDetails = (calls) => {
  return calls.map(call => ({
    id: call.id,
    startTime: call.startedAt,
    endTime: call.endedAt,
    duration: new Date(call.endedAt) - new Date(call.startedAt),
    cost: call.costBreakdown?.total || 0,
    status: call.status,
    endReason: call.endedReason,
    transcript: call.transcript,
    summary: call.summary,
    costBreakdown: {
      transcription: call.costBreakdown?.stt || 0,
      llm: call.costBreakdown?.llm || 0,
      tts: call.costBreakdown?.tts || 0,
      vapi: call.costBreakdown?.vapi || 0,
    }
  }));
};

const processCallsOverTime = (calls) => {
  const groupedCalls = groupByDate(calls);
  return {
    labels: Object.keys(groupedCalls),
    data: Object.values(groupedCalls).map(day => day.count),
  };
};

const processMinutesOverTime = (calls) => {
  const groupedCalls = groupByDate(calls);
  return {
    labels: Object.keys(groupedCalls),
    data: Object.values(groupedCalls).map(day => day.minutes),
  };
};

const processCostsOverTime = (calls) => {
  const groupedCalls = groupByDate(calls);
  return {
    labels: Object.keys(groupedCalls),
    data: Object.values(groupedCalls).map(day => day.credits),
  };
};

const groupByDate = (calls) => {
  return calls.reduce((acc, call) => {
    // Skip if no startedAt date
    if (!call.startedAt) return acc;

    try {
      const date = new Date(call.startedAt);
      
      // Skip invalid dates
      if (isNaN(date.getTime())) {
        console.warn('Invalid date found:', call.startedAt);
        return acc;
      }

      // Format date as YYYY-MM-DD to ensure consistent grouping
      const formattedDate = date.toISOString().split('T')[0];
      
      if (!acc[formattedDate]) {
        acc[formattedDate] = { count: 0, minutes: 0, credits: 0 };
      }
      
      acc[formattedDate].count += 1;
      
    if (call.startedAt && call.endedAt) {
        const endDate = new Date(call.endedAt);
        if (!isNaN(endDate.getTime())) {
          const duration = (endDate - date) / 1000 / 60;
          acc[formattedDate].minutes += duration;
          acc[formattedDate].credits += duration;
          
      if (call.messages) {
        call.messages.forEach(entry => {
          if (entry.role === 'tool_calls' && entry.toolCalls) {
                acc[formattedDate].credits += entry.toolCalls.length * 0.5;
          }
        });
      }
    }
      }
      
      return acc;
    } catch (error) {
      console.warn('Error processing date:', error);
    return acc;
    }
  }, {});
};

const updateAssistant = async (updates, assistantId) => {
  try {
    const response = await fetch(
      `${VAPI_API_URL}/assistant/${assistantId}`,
      {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${VAPI_API_KEY}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updates)
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Update error response:', errorText);
      throw new Error('Failed to update assistant');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating assistant:', error);
    throw error;
  }
};

const getTotalCredits = () => TOTAL_CREDITS;

const fetchAssistantConfig = async (assistantId) => {
  try {
    // If assistantId is an array, fetch first one for now
    const id = Array.isArray(assistantId) ? assistantId[0] : assistantId;
    const response = await fetch(
      `${VAPI_API_URL}/assistant/${id}`,
      {
        headers: {
          'Authorization': `Bearer ${VAPI_API_KEY}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch assistant configuration');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching assistant config:', error);
    throw error;
  }
};

export {
  fetchCallAnalytics,
  getTotalCredits,
  processAnalyticsData,
  updateAssistant,
  getAssistantId,
  fetchAssistantConfig,
  getAssistantIds
}; 