import React from 'react';
import ReactMarkdown from 'react-markdown';

const ChatInterface = ({ call }) => {
  if (!call || !call.messages) {
    return (
      <div className="flex items-center justify-center h-full text-gray-500">
        This conversation is no longer available
      </div>
    );
  }

  // Filter out system messages, tool-related messages, and empty messages
  const conversationMessages = call.messages.filter(
    message => message.role !== 'system' && 
               message.role !== 'tool_calls' && 
               message.role !== 'tool_call_result' &&
               (message.message || message.content || message.mediaType) // Only keep messages with content or media
  );

  // Helper function to format WhatsApp media content
  const renderMediaContent = (message) => {
    if (message.mediaType === 'image') {
      return (
        <img 
          src={message.mediaUrl} 
          alt="Shared image" 
          className="max-w-sm rounded-lg"
        />
      );
    } else if (message.mediaType === 'video') {
      return (
        <video 
          controls 
          className="max-w-sm rounded-lg"
        >
          <source src={message.mediaUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else if (message.mediaType === 'document') {
      return (
        <a 
          href={message.mediaUrl}
          target="_blank"
          rel="noopener noreferrer" 
          className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
        >
          📎 {message.mediaFileName || 'Document'}
        </a>
      );
    }
    return null;
  };

  return (
    <div className="flex-1 overflow-y-auto p-4 space-y-4">
      {conversationMessages.map((message, index) => (
        <div
          key={index}
          className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
        >
          <div
            className={`max-w-[70%] rounded-lg p-3 ${
              message.role === 'user'
                ? 'bg-[#2B9BF7] text-white'
                : 'bg-gray-200 text-gray-900'
            }`}
          >
            {/* Render media content if present */}
            {message.mediaType && renderMediaContent(message)}
            
            {/* Render message text with markdown */}
            {(message.message || message.content) && (
              <div className={`prose ${message.role === 'user' ? 'prose-invert' : ''} max-w-none ${message.mediaType ? 'mt-2' : ''}`}>
                <ReactMarkdown>
                  {message.message || message.content}
                </ReactMarkdown>
              </div>
            )}
            
            {/* Show message metadata */}
            <div className={`flex items-center gap-2 mt-1 text-xs ${
              message.role === 'user' ? 'text-white/80' : 'text-gray-600'
            }`}>
              {message.duration && (
                <span>{(message.duration / 1000).toFixed(1)}s</span>
              )}
              {message.timestamp && (
                <span>{new Date(message.timestamp).toLocaleTimeString([], { 
                  hour: '2-digit', 
                  minute: '2-digit'
                })}</span>
              )}
              {message.status && (
                <span className="flex items-center gap-1">
                  {message.status === 'read' ? '✓✓' : '✓'}
                </span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatInterface; 