import React from 'react';

const CallerDistributionCard = ({ totalCalls, newCallers }) => {
  const existingCallers = totalCalls - newCallers;
  const newPercentage = (newCallers / totalCalls * 100).toFixed(0);
  
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">Caller Distribution</h3>
      
      <div className="flex justify-between items-center mb-2">
        <span className="text-xl font-semibold text-gray-900">Total Callers: {totalCalls}</span>
        <span className="text-sm font-medium text-teal-600">{newPercentage}% New</span>
      </div>

      <div className="relative h-2.5 bg-gray-100 rounded-full overflow-hidden">
        <div 
          className="absolute h-full rounded-full transition-all duration-500 bg-teal-500"
          style={{ width: `${newPercentage}%` }}
        />
      </div>

      <div className="flex justify-between mt-2">
        <span className="text-sm font-medium text-teal-600">{newCallers} new</span>
        <span className="text-sm font-medium text-gray-500">{existingCallers} existing</span>
      </div>
    </div>
  );
};

export default CallerDistributionCard; 