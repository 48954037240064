import React from 'react';
import StatsCard from './StatsCard';
import ChartCard from './ChartCard';
import PieChartCard from './PieChartCard';
import CallerDistributionCard from './CallerDistributionCard';
import { fetchCallAnalytics, processAnalyticsData, getTotalCredits, fetchAssistantConfig } from '../../services/vapiService';
import { fetchWhatsAppConversations } from '../../services/whatsappService';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../ui/select';
import { fetchPropertyMappings, getCanonicalPropertyName, getCallerHistory } from '../../services/propertyService';
import { LoadingSpinner } from '../ui/loading';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../ui/popover";
import { Check, ChevronsUpDown, User, UserCircle } from "lucide-react";
import { cn } from "../../lib/utils";
import { Button } from "../ui/button";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const TOOL_NAME_MAPPING = {
  'property-address-finder-real-1': 'Property Search',
  'check-for-specified-existing-booking': 'Check Existing Booking',
  'book_viewing_real': 'Book Viewing',
  'check-calendar': 'Check Calendar',
  'cancel-booking': 'Cancel Booking',
  'confirm_attendance': 'Confirm Attendance',
  'search-registry-property': 'Search Registry Property',
  'dynamicDestinationTransferCall': 'Call Forwarding',
  'register-customer-details': 'Register Customer Details',
  'note-callback': 'Note Callback'
};

const AnalyticsDashboard = () => {
  const [analyticsData, setAnalyticsData] = React.useState(null);
  const [rawData, setRawData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [timeFilter, setTimeFilter] = React.useState('7');
  const [selectedAssistant, setSelectedAssistant] = React.useState('all');
  const [selectedProperty, setSelectedProperty] = React.useState('all');
  const [propertyMappings, setPropertyMappings] = React.useState(null);
  const [properties, setProperties] = React.useState(new Set());
  const [callerHistory, setCallerHistory] = React.useState(new Set());
  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [whatsappData, setWhatsappData] = React.useState(null);

  // Update property interest data calculation to include WhatsApp conversations
  const propertyInterestData = React.useMemo(() => {
    if (!rawData && !whatsappData) return { labels: [], data: [] };
    
    const propertyCounts = {};
    
    // Apply date filtering
    const cutoffDate = new Date();
    if (timeFilter === '1') {
      cutoffDate.setHours(cutoffDate.getHours() - 24);
    } else {
      cutoffDate.setDate(cutoffDate.getDate() - parseInt(timeFilter));
    }
    
    // Process voice calls
    if (rawData) {
      const filteredCalls = rawData.filter(call => {
        const callDate = new Date(call.startedAt);
        return callDate >= cutoffDate;
      });
      
      filteredCalls.forEach(call => {
        const property = call.analysis?.structuredData?.property_name;
        if (property && propertyMappings) {
          const canonicalName = getCanonicalPropertyName(property);
          if (Array.from(propertyMappings.values()).includes(canonicalName)) {
            propertyCounts[canonicalName] = (propertyCounts[canonicalName] || 0) + 1;
          }
        }
      });
    }

    // Process WhatsApp conversations
    if (whatsappData?.calls) {
      const filteredWhatsApp = whatsappData.calls.filter(conv => {
        const convDate = new Date(conv.startedAt);
        return convDate >= cutoffDate;
      });

      filteredWhatsApp.forEach(conv => {
        const property = conv.analysis?.structuredData?.property_name;
        if (property && propertyMappings) {
          const canonicalName = getCanonicalPropertyName(property);
          if (Array.from(propertyMappings.values()).includes(canonicalName)) {
            propertyCounts[canonicalName] = (propertyCounts[canonicalName] || 0) + 1;
          }
        }
      });
    }
    
    return {
      labels: Object.keys(propertyCounts),
      data: Object.values(propertyCounts),
    };
  }, [rawData, whatsappData, propertyMappings, timeFilter]);

  // Function to get unique users count from conversations
  const getUniqueUsersCount = (conversations) => {
    const uniqueUsers = new Set(
      conversations.map(conv => conv.phoneNumber)
    );
    return uniqueUsers.size;
  };

  // Add back the filterData function
  const filterData = (data, days) => {
    const cutoffDate = new Date();
    if (days === '1') {
      cutoffDate.setHours(cutoffDate.getHours() - 24);
    } else {
      cutoffDate.setDate(cutoffDate.getDate() - parseInt(days));
    }
    
    // Get all numbers from before the cutoff date
    const historicalNumbers = new Set();
    const newPeriodNumbers = new Set();
    
    // First pass: collect all numbers from before cutoff date
    data.forEach(call => {
      const callDate = new Date(call.startedAt);
      if (callDate < cutoffDate && call.customer?.number) {
        let phoneNumber = call.customer.number.trim().replace(/\s+/g, '');
        phoneNumber = phoneNumber.replace(/^\+44/, '');
        phoneNumber = phoneNumber.replace(/\D/g, '');
        phoneNumber = phoneNumber.replace(/^0/, '');
        if (phoneNumber.length >= 10) {
          historicalNumbers.add(phoneNumber);
        }
      }
    });
    
    const filteredCalls = data.filter(call => {
      const callDate = new Date(call.startedAt);
      const dateMatch = callDate >= cutoffDate;
      const propertyMatch = selectedProperty === 'all' || 
        getCanonicalPropertyName(call.analysis?.structuredData?.property_name) === selectedProperty;
      return dateMatch && propertyMatch;
    });
    
    // First collect all distinct numbers in the filter period
    filteredCalls.forEach(call => {
      if (!call.customer?.number) return false;
      
      // Normalize the phone number from the call
      let phoneNumber = call.customer.number.trim().replace(/\s+/g, '');
      phoneNumber = phoneNumber.replace(/^\+44/, '');
      phoneNumber = phoneNumber.replace(/\D/g, '');
      phoneNumber = phoneNumber.replace(/^0/, '');
      
      // Skip invalid numbers
      if (phoneNumber.length < 10) return false;
      
      newPeriodNumbers.add(phoneNumber);
    });
    
    // Then count how many of these numbers are truly new
    let newCallers = 0;
    newPeriodNumbers.forEach(number => {
      if (!historicalNumbers.has(number)) {
        newCallers++;
      }
    });
    
    const processedData = processAnalyticsData(filteredCalls);
    
    // Sort the callsOverTime data chronologically
    if (processedData.callsOverTime) {
      const sortedData = {
        labels: [...processedData.callsOverTime.labels],
        data: [...processedData.callsOverTime.data]
      };
      
      // Create pairs of labels and data for sorting
      const pairs = sortedData.labels.map((label, index) => ({
        label,
        value: sortedData.data[index]
      }));
      
      // Sort by date
      pairs.sort((a, b) => new Date(a.label) - new Date(b.label));
      
      // Reconstruct the sorted arrays
      processedData.callsOverTime = {
        labels: pairs.map(p => p.label),
        data: pairs.map(p => p.value)
      };
    }
    
    return {
      ...processedData,
      newCallers
    };
  };

  React.useEffect(() => {
    const loadAnalytics = async () => {
      try {
        setLoading(true);
        let data;
        let whatsapp;

        if (selectedAssistant === 'text') {
          whatsapp = await fetchWhatsAppConversations(100, 0);
          setWhatsappData(whatsapp);
          data = { calls: [] }; // Empty voice calls data
        } else {
          [data, whatsapp] = await Promise.all([
            fetchCallAnalytics(selectedAssistant),
            selectedAssistant === 'all' ? fetchWhatsAppConversations(100, 0) : null
          ]);
          if (whatsapp) {
            setWhatsappData(whatsapp);
          }
        }

        const [mappings, callers] = await Promise.all([
          fetchPropertyMappings(),
          getCallerHistory()
        ]);
        
        // Get unique properties from calls
        const propertySet = new Set(
          data.calls
            .filter(call => call.analysis?.structuredData?.property_name)
            .filter(call => {
              const canonicalName = getCanonicalPropertyName(call.analysis.structuredData.property_name);
              return mappings && Array.from(mappings.values()).includes(canonicalName);
            })
            .map(call => getCanonicalPropertyName(call.analysis.structuredData.property_name))
        );
        
        setRawData(data.calls);
        setPropertyMappings(mappings);
        setProperties(propertySet);
        setCallerHistory(callers);
        setAnalyticsData(filterData(data.calls, timeFilter));
        setLoading(false);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadAnalytics();
  }, [selectedAssistant]);

  React.useEffect(() => {
    if (rawData) {
      setAnalyticsData(filterData(rawData, timeFilter));
    }
  }, [timeFilter, rawData, selectedProperty]);

  const handleTimeFilterChange = (event) => {
    setTimeFilter(event.target.value);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-red-500">Error: {error}</div>;
  if (!analyticsData) return null;

  const getStats = () => {
    const baseStats = [{
      title: selectedAssistant === 'all' ? 'Total Conversations' : 'Number of Calls',
      value: selectedAssistant === 'all' ? 
        analyticsData.totalCalls + (whatsappData?.calls?.length || 0) :
        analyticsData.totalCalls,
      unit: selectedAssistant === 'all' ? 'conversations' : 'calls',
      additionalInfo: selectedAssistant === 'all' ? {} : {
        text: `${analyticsData.newCallers} new ${analyticsData.newCallers === 1 ? 'caller' : 'callers'}`,
        isPositive: true
      }
    }];

    // Add Bookings Made stat for 'all' view
    if (selectedAssistant === 'all') {
      const voiceBookings = Object.entries(analyticsData.reasonsForCall || {})
        .find(([reason]) => reason.toLowerCase().includes('new booking'));
      
      const whatsappBookings = whatsappData?.processed?.outcomes?.['Viewing Booked'] || 0;
      
      baseStats.push({
        title: 'Bookings Made',
        value: (voiceBookings ? voiceBookings[1] : 0) + whatsappBookings,
        unit: 'bookings',
        additionalInfo: {}
      });
    }

    // Add call minutes for voice calls
    if (selectedAssistant !== 'text') {
      baseStats.push({
        title: 'Total Call Minutes',
        value: analyticsData.totalMinutes,
        unit: 'min',
        additionalInfo: {}
      });
    }

    return baseStats;
  };

  // Add helper function to combine reasons and purposes
  const getCombinedReasons = () => {
    const combinedReasons = { ...(analyticsData.reasonsForCall || {}) };
    
    // Add WhatsApp purposes if available
    if (whatsappData?.processed?.purposes) {
      Object.entries(whatsappData.processed.purposes).forEach(([purpose, count]) => {
        // Convert purpose to title case and remove underscores
        const formattedPurpose = purpose
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
        
        if (combinedReasons[formattedPurpose]) {
          combinedReasons[formattedPurpose] += count;
        } else {
          combinedReasons[formattedPurpose] = count;
        }
      });
    }

    return combinedReasons;
  };

  if (selectedAssistant === 'text' && whatsappData) {
    return (
      <div className="space-y-6 p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Analytics</h2>
          <div className="flex items-center gap-4">
            <div className="w-72">
              <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-full justify-between bg-white"
                  >
                    {selectedProperty === "all"
                      ? "All Properties"
                      : getCanonicalPropertyName(selectedProperty)}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-72 p-0">
                  <Command>
                    <CommandInput 
                      placeholder="Search properties..." 
                      value={searchValue}
                      onValueChange={setSearchValue}
                    />
                    <CommandEmpty>No property found.</CommandEmpty>
                    <CommandGroup>
                      <CommandItem
                        value="all"
                        onSelect={(currentValue) => {
                          setSelectedProperty("all");
                          setOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            selectedProperty === "all" ? "opacity-100" : "opacity-0"
                          )}
                        />
                        All Properties
                      </CommandItem>
                      {Array.from(properties)
                        .filter(property => 
                          getCanonicalPropertyName(property)
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        )
                        .map(property => (
                          <CommandItem
                            key={property}
                            value={property}
                            onSelect={(currentValue) => {
                              setSelectedProperty(property);
                              setOpen(false);
                            }}
                          >
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                selectedProperty === property 
                                  ? "opacity-100" 
                                  : "opacity-0"
                              )}
                            />
                            {getCanonicalPropertyName(property)}
                          </CommandItem>
                        ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
            <div className="w-[200px]">
              <Select 
                value={timeFilter}
                onValueChange={setTimeFilter}
              >
                <SelectTrigger className="bg-white">
                  <SelectValue placeholder="Select time range" />
                </SelectTrigger>
                <SelectContent className="bg-white">
                  <SelectItem value="1">Last 24 hours</SelectItem>
                  <SelectItem value="7">Last 7 days</SelectItem>
                  <SelectItem value="30">Last 30 days</SelectItem>
                  <SelectItem value="90">Last 90 days</SelectItem>
                  <SelectItem value="365">Last year</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            View Analytics For
          </label>
          <Select
            value={selectedAssistant}
            onValueChange={setSelectedAssistant}
          >
            <SelectTrigger className="w-full bg-white">
              <SelectValue placeholder="Select assistant type" />
            </SelectTrigger>
            <SelectContent className="bg-white">
              <SelectItem value="all">All Assistants</SelectItem>
              <SelectItem value="inbound">Inbound</SelectItem>
              <SelectItem value="outbound">Outbound</SelectItem>
              <SelectItem value="text">WhatsApp</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <StatsCard
            title="Total Conversations"
            value={whatsappData.calls.length}
            unit="chats"
          />
          <StatsCard
            title="Active Users"
            value={getUniqueUsersCount(whatsappData.calls)}
            unit="users"
            icon={<UserCircle className="h-5 w-6 text-emerald-600" />}
            borderColor="border-l-emerald-500"
          />
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <PieChartCard
            title="Conversation Purposes"
            data={Object.values(whatsappData.processed.purposes || {})}
            labels={Object.keys(whatsappData.processed.purposes || {}).map(purpose => 
              purpose.split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ')
            )}
            backgroundColor={[
              '#FF6384',
              '#36A2EB',
              '#FFCE56',
              '#4BC0C0',
              '#9966FF'
            ]}
          />
          {selectedProperty === 'all' && (
            <PieChartCard
              title="Interest by Property"
              data={propertyInterestData.data}
              labels={propertyInterestData.labels}
              backgroundColor={[
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#4BC0C0',
                '#9966FF',
                '#FF9F40'
              ]}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6 p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Analytics</h2>
        <div className="flex items-center gap-4">
          <div className="w-72">
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="w-full justify-between bg-white"
                >
                  {selectedProperty === "all"
                    ? "All Properties"
                    : getCanonicalPropertyName(selectedProperty)}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-72 p-0">
                <Command>
                  <CommandInput 
                    placeholder="Search properties..." 
                    value={searchValue}
                    onValueChange={setSearchValue}
                  />
                  <CommandEmpty>No property found.</CommandEmpty>
                  <CommandGroup>
                    <CommandItem
                      value="all"
                      onSelect={(currentValue) => {
                        console.log('Selected:', currentValue);
                        setSelectedProperty("all");
                        setOpen(false);
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          selectedProperty === "all" ? "opacity-100" : "opacity-0"
                        )}
                      />
                      All Properties
                    </CommandItem>
                    {Array.from(properties)
                      .filter(property => 
                        getCanonicalPropertyName(property)
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      )
                      .map(property => (
                        <CommandItem
                          key={property}
                          value={property}
                          onSelect={(currentValue) => {
                            console.log('Selected:', currentValue);
                            setSelectedProperty(property);
                            setOpen(false);
                          }}
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              selectedProperty === property 
                                ? "opacity-100" 
                                : "opacity-0"
                            )}
                          />
                          {getCanonicalPropertyName(property)}
                        </CommandItem>
                      ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>
          </div>
          <div className="w-[200px]">
            <Select 
              value={timeFilter}
              onValueChange={setTimeFilter}
            >
              <SelectTrigger className="bg-white">
                <SelectValue placeholder="Select time range" />
              </SelectTrigger>
              <SelectContent className="bg-white">
                <SelectItem value="1">Last 24 hours</SelectItem>
                <SelectItem value="7">Last 7 days</SelectItem>
                <SelectItem value="30">Last 30 days</SelectItem>
                <SelectItem value="90">Last 90 days</SelectItem>
                <SelectItem value="365">Last year</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          View Analytics For
        </label>
        <Select
          value={selectedAssistant}
          onValueChange={setSelectedAssistant}
        >
          <SelectTrigger className="w-full bg-white">
            <SelectValue placeholder="Select assistant type" />
          </SelectTrigger>
          <SelectContent className="bg-white">
            <SelectItem value="all">All Assistants</SelectItem>
            <SelectItem value="inbound">Inbound</SelectItem>
            <SelectItem value="outbound">Outbound</SelectItem>
            <SelectItem value="text">WhatsApp</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {selectedAssistant === 'text' ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <StatsCard
              title="Total Conversations"
              value={whatsappData.calls.length}
              unit="chats"
            />
            <StatsCard
              title="Active Users"
              value={getUniqueUsersCount(whatsappData.calls)}
              unit="users"
              icon={<UserCircle className="h-5 w-5 text-purple-500" />}
              borderColor="border-l-purple-500"
            />
            <StatsCard
              title="Average Response Time"
              value={whatsappData.processed.averageDuration}
              unit=""
            />
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <PieChartCard
              title="Conversation Purposes"
              data={Object.values(whatsappData.processed.purposes || {})}
              labels={Object.keys(whatsappData.processed.purposes || {}).map(purpose => 
                purpose.split('_')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                  .join(' ')
              )}
              backgroundColor={[
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#4BC0C0',
                '#9966FF'
              ]}
            />
            <PieChartCard
              title="Tool Usage Distribution"
              data={Object.values(whatsappData.processed.toolUsage || {})}
              labels={Object.keys(whatsappData.processed.toolUsage || {}).map(tool => 
                TOOL_NAME_MAPPING[tool] || tool
              )}
              backgroundColor={[
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#4BC0C0',
                '#9966FF'
              ]}
            />
            <ChartCard
              title="Messages Over Time"
              subtitle="Number of messages exchanged per day"
              data={whatsappData.processed.messagesOverTime}
            />
          </div>
        </>
      ) : (
        <>
          <div className="grid gap-4 md:grid-cols-3">
            {getStats().map((stat, index) => (
              <StatsCard
                key={index}
                title={stat.title}
                value={stat.value}
                unit={stat.unit}
                additionalInfo={stat.additionalInfo}
              />
            ))}
          </div>

          <CallerDistributionCard 
            totalCalls={analyticsData.totalCalls}
            newCallers={analyticsData.newCallers}
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {selectedAssistant === 'all' && (
              <>
                <PieChartCard
                  title="Reasons for Contact"
                  data={Object.values(getCombinedReasons())}
                  labels={Object.keys(getCombinedReasons())}
                  backgroundColor={[
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                    '#FF9F40'
                  ]}
                />
                {selectedProperty === 'all' && (
                  <PieChartCard
                    title="Interest by Property"
                    data={propertyInterestData.data}
                    labels={propertyInterestData.labels}
                    backgroundColor={[
                      '#FF6384',
                      '#36A2EB',
                      '#FFCE56',
                      '#4BC0C0',
                      '#9966FF',
                      '#FF9F40'
                    ]}
                  />
                )}
                <PieChartCard
                  title="Conversations by Channel"
                  data={[
                    analyticsData.inboundCalls || 0,
                    analyticsData.outboundCalls || 0,
                    whatsappData?.calls?.length || 0
                  ]}
                  labels={['Inbound', 'Outbound', 'WhatsApp']}
                  backgroundColor={['#FF6384', '#36A2EB', '#4BC0C0']}
                />
                <ChartCard
                  title="Number of Calls"
                  subtitle="The total number of calls made each day"
                  data={analyticsData.callsOverTime}
                  type="bar"
                />
                <PieChartCard
                  title="Call Minutes Distribution"
                  data={[analyticsData.inboundMinutes, analyticsData.outboundMinutes]}
                  labels={['Inbound', 'Outbound']}
                  backgroundColor={['#FF6384', '#36A2EB']}
                />
              </>
            )}
            {selectedAssistant !== 'all' ? (
              <>
                {selectedAssistant === 'inbound' && (
                  <PieChartCard
                    title="Reasons for Call"
                    data={Object.values(analyticsData.reasonsForCall || {})}
                    labels={Object.keys(analyticsData.reasonsForCall || {})}
                    backgroundColor={[
                      '#FF6384',
                      '#36A2EB',
                      '#FFCE56',
                      '#4BC0C0',
                      '#9966FF',
                      '#FF9F40'
                    ]}
                  />
                )}
                {selectedAssistant === 'outbound' && (
                  <PieChartCard
                    title="Call Outcomes"
                    data={Object.values(analyticsData.callOutcomes || {})}
                    labels={Object.keys(analyticsData.callOutcomes || {})}
                    backgroundColor={[
                      '#FF6384',
                      '#36A2EB',
                      '#FFCE56',
                      '#4BC0C0',
                      '#9966FF',
                      '#FF9F40'
                    ]}
                  />
                )}
                {selectedProperty === 'all' && (
                  <PieChartCard
                    title="Interest by Property"
                    data={propertyInterestData.data}
                    labels={propertyInterestData.labels}
                    backgroundColor={[
                      '#FF6384',
                      '#36A2EB',
                      '#FFCE56',
                      '#4BC0C0',
                      '#9966FF',
                      '#FF9F40'
                    ]}
                  />
                )}
              </>
            ) : null}
          </div>

          <div className="mt-8">
            <h3 className="text-xl font-semibold mb-4">Recent Calls</h3>
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Duration</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Credits</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {analyticsData.callDetails.slice(0, 5).map((call) => (
                    <tr key={call.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(call.startTime).toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {(call.duration / 1000 / 60).toFixed(2)} min
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {((call.duration / 1000 / 60) + (call.toolCalls || 0) * 0.5).toFixed(2)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {call.status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AnalyticsDashboard; 