import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Button } from "../ui/button";
import { Home, Calendar, User, FolderOpen, Loader2, X, Check, PlusCircle, FileSpreadsheet, RefreshCcw } from "lucide-react";
import { Card, CardContent } from "../ui/card";
import { Badge } from "../ui/badge";
import { Checkbox } from "../ui/checkbox";
import { googleService } from '../../services/googleService';
import { microsoftService } from '../../services/microsoftService';
import { Link, useNavigate } from "react-router-dom";

const MAPPING_CATEGORIES = {
  property_listings: {
    icon: Home,
    title: "Property Listings",
    colors: {
      bg: "bg-blue-100",
      text: "text-blue-600"
    },
    fields: [
      { id: 'property_address', label: 'Property Address', preview: '123 Main St, City' },
      { id: 'list_price', label: 'List Price', preview: '$450,000' },
      { id: 'bedrooms', label: 'Bedrooms', preview: '3' },
      { id: 'bathrooms', label: 'Bathrooms', preview: '2.5' },
      { id: 'description', label: 'Description', preview: 'Beautiful home with...' },
    ]
  },
  bookings: {
    icon: Calendar,
    title: "Bookings",
    colors: {
      bg: "bg-purple-100",
      text: "text-purple-600"
    },
    fields: [
      { id: 'booking_date', label: 'Booking Date', preview: '2024-03-15' },
      { id: 'booking_time', label: 'Booking Time', preview: '14:30' },
      { id: 'property_id', label: 'Property ID', preview: 'PROP123' },
      { id: 'client_name', label: 'Client Name', preview: 'John Smith' },
      { id: 'booking_type', label: 'Booking Type', preview: 'Viewing' },
    ]
  },
  client_details: {
    icon: User,
    title: "Client Details",
    colors: {
      bg: "bg-green-100",
      text: "text-green-600"
    },
    fields: [
      { id: 'full_name', label: 'Full Name', preview: 'John Smith' },
      { id: 'email', label: 'Email', preview: 'john@example.com' },
      { id: 'phone', label: 'Phone', preview: '+1 234 567 8900' },
      { id: 'preferred_contact', label: 'Preferred Contact', preview: 'Email' },
      { id: 'notes', label: 'Notes', preview: 'Interested in 3-bed properties' },
    ]
  }
};

const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID;

// Load Google Drive Picker API
const loadGoogleDrivePickerApi = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = () => {
      window.gapi.load('picker', () => {
        resolve();
      });
    };
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

// Load Microsoft OneDrive Picker API
const loadMicrosoftFilePickerApi = () => {
  return new Promise((resolve, reject) => {
    // Check if the OneDrive SDK is already loaded
    if (window.OneDrive) {
      resolve();
      return;
    }
    
    const script = document.createElement('script');
    script.src = 'https://js.live.net/v7.2/OneDrive.js';
    script.async = true;
    script.defer = true;
    
    script.onload = () => {
      // Wait a bit to ensure the OneDrive object is fully initialized
      setTimeout(() => {
        if (window.OneDrive) {
          resolve();
        } else {
          reject(new Error('OneDrive SDK loaded but OneDrive object not available'));
        }
      }, 500);
    };
    
    script.onerror = () => {
      reject(new Error('Failed to load OneDrive SDK script'));
    };
    
    document.head.appendChild(script);
  });
};

const ManageIntegrations = () => {
  const [openItems, setOpenItems] = useState([]);
  const [selectedMappings, setSelectedMappings] = useState({});
  const [selectedFiles, setSelectedFiles] = useState({});
  const [selectedSheets, setSelectedSheets] = useState({}); // Now stores arrays of sheets
  const [availableSheets, setAvailableSheets] = useState({}); // All sheets for each file
  const [loading, setLoading] = useState({ microsoftRefresh: false });
  const [isGooglePickerLoaded, setIsGooglePickerLoaded] = useState(false);
  const [isMicrosoftPickerLoaded, setIsMicrosoftPickerLoaded] = useState(false);
  const [connectedServices, setConnectedServices] = useState({
    google: false,
    microsoft: false
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Check connection status and load picker APIs if services are connected
  useEffect(() => {
    const checkConnections = async () => {
      const googleConnected = googleService.isConnected();
      const microsoftConnected = microsoftService.isConnected();
      
      console.log('Connection status:', { google: googleConnected, microsoft: microsoftConnected });
      
      setConnectedServices({
        google: googleConnected, 
        microsoft: microsoftConnected
      });
      
      // Load picker APIs based on connected services
      if (googleConnected && !isGooglePickerLoaded) {
        try {
          await loadGoogleDrivePickerApi();
          setIsGooglePickerLoaded(true);
          console.log('Google Drive Picker API loaded');
        } catch (error) {
          console.error('Failed to load Google Drive Picker API:', error);
        }
      }
      
      if (microsoftConnected && !isMicrosoftPickerLoaded) {
        try {
          await loadMicrosoftFilePickerApi();
          setIsMicrosoftPickerLoaded(true);
          console.log('Microsoft File Picker API loaded');
        } catch (error) {
          console.error('Failed to load Microsoft File Picker API:', error);
        }
      }
    };
    
    checkConnections();
  }, [isGooglePickerLoaded, isMicrosoftPickerLoaded]);

  const handleToggleItem = (categoryId) => {
    setOpenItems(prev => {
      if (prev.includes(categoryId)) {
        return prev.filter(item => item !== categoryId);
      } else {
        return [...prev, categoryId];
      }
    });
  };

  // Handler for showing the Google Drive Picker
  const openGoogleDrivePicker = async (categoryId) => {
    if (!isGooglePickerLoaded) {
      console.error('Google Drive Picker API not loaded');
      return;
    }
    
    try {
      // Get OAuth token from your Google service
      const authToken = await googleService.getOAuthAccessToken();
      
      if (!authToken) {
        console.error('Failed to get OAuth token');
        return;
      }
      
      // Create and render the picker
      const picker = new window.google.picker.PickerBuilder()
        .addView(window.google.picker.ViewId.SPREADSHEETS) // Only show spreadsheets
        .setOAuthToken(authToken)
        .setCallback((data) => {
          if (data.action === window.google.picker.Action.PICKED) {
            const file = data.docs[0];
            console.log('File selected from Drive:', file);
            
            // Process the file selection
            handleGoogleDriveFileSelected(categoryId, file);
          }
        })
        .build();
        
      picker.setVisible(true);
      
    } catch (error) {
      console.error('Error opening Google Drive Picker:', error);
    }
  };
  
  // Handler for showing the Microsoft OneDrive Picker
  const handleSelectMicrosoftFile = async (categoryId) => {
    setLoading(prev => ({ ...prev, [categoryId]: true }));
    
    try {
      // Check if client ID is available
      if (!MICROSOFT_CLIENT_ID) {
        throw new Error('Microsoft client ID is not configured');
      }

      // Get a fresh access token
      const accessToken = await microsoftService.getOAuthAccessToken();
      
      if (!accessToken) {
        throw new Error('No valid access token available');
      }

      // Initialize OneDrive options with the access token
      const options = {
        clientId: MICROSOFT_CLIENT_ID,
        action: 'query',
        multiSelect: false,
        advanced: {
          filter: '.xlsx,.xls',
          redirectUri: window.location.origin,
          endpointHint: 'api.onedrive.com',
          queryParameters: `access_token=${accessToken}`,
          navigation: 'auto'
        },
        success: async (response) => {
          console.log('Files selected from OneDrive:', response);
          
          // Handle the OneDrive response format
          if (response && response.value && response.value.length > 0) {
            const selectedFile = response.value[0];
            
            // Fetch complete file metadata using Microsoft Graph API
            try {
              const fileMetadata = await fetch(
                `https://graph.microsoft.com/v1.0/me/drive/items/${selectedFile.id}`,
                {
                  headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                  }
                }
              );

              if (!fileMetadata.ok) {
                throw new Error(`Failed to fetch file metadata: ${fileMetadata.statusText}`);
              }

              const fileData = await fileMetadata.json();
              console.log('Complete file metadata:', fileData);

              const file = {
                id: fileData.id,
                name: fileData.name,
                webUrl: fileData.webUrl,
                size: fileData.size,
                createdDateTime: fileData.createdDateTime,
                lastModifiedDateTime: fileData.lastModifiedDateTime,
                downloadUrl: fileData['@microsoft.graph.downloadUrl']
              };
              
              await handleMicrosoftFileSelected(categoryId, file);
            } catch (error) {
              console.error('Error fetching file metadata:', error);
              setError('Failed to fetch file details. Please try again.');
            }
          } else {
            console.log('No files selected from OneDrive');
            setError('No file was selected');
          }
          
          setLoading(prev => ({ ...prev, [categoryId]: false }));
        },
        cancel: () => {
          console.log('File selection cancelled');
          setLoading(prev => ({ ...prev, [categoryId]: false }));
        },
        error: (error) => {
          console.error('OneDrive file selection error:', error);
          setError('Failed to select file. Please try again.');
          setLoading(prev => ({ ...prev, [categoryId]: false }));
        }
      };

      // Launch OneDrive picker
      window.OneDrive.open(options);
    } catch (error) {
      console.error('Error launching OneDrive picker:', error);
      setError(error.message || 'Failed to launch file picker. Please try again.');
      setLoading(prev => ({ ...prev, [categoryId]: false }));
    }
  };
  
  // Handle a file selected from the Google Drive Picker
  const handleGoogleDriveFileSelected = async (categoryId, driveFile) => {
    if (!driveFile || !driveFile.id) {
      console.error('Invalid file selected');
      return;
    }
    
    console.log('Google Drive file selected:', driveFile);
    
    // Convert the Drive file to the format your app expects
    const file = {
      id: driveFile.id,
      name: driveFile.name,
      type: 'google_sheets',
      url: driveFile.url,
      service: 'google'
      // Add any other properties your application needs
    };
    
    console.log('Creating file object for selected file:', file);
    
    // Add the file to our selected files (instead of replacing)
    setSelectedFiles(prev => {
      const currentFiles = prev[categoryId] || [];
      
      // Check if this file is already selected
      if (currentFiles.some(f => f.id === file.id)) {
        // File already selected, don't add it again
        return prev;
      }
      
      // Add new file to the array
      return {
        ...prev,
        [categoryId]: [...currentFiles, file]
      };
    });
    
    // Load the sheets for this file
    try {
      await loadSheetsForFile(categoryId, driveFile.id, file, 'google');
    } catch (error) {
      console.error('Error loading sheets after file selection:', error);
    }
  };
  
  // Handle a file selected from Microsoft OneDrive
  const handleMicrosoftFileSelected = async (categoryId, oneDriveFile) => {
    try {
      if (!oneDriveFile || !oneDriveFile.id) {
        throw new Error('Invalid file selected from OneDrive');
      }
      
      console.log('Microsoft OneDrive file selected:', oneDriveFile);
      
      // Get a fresh access token for API calls
      const accessToken = await microsoftService.getOAuthAccessToken();
      
      // Convert the OneDrive file to the format your app expects
      const file = {
        id: oneDriveFile.id,
        name: oneDriveFile.name || 'Untitled',
        type: 'microsoft_excel',
        url: oneDriveFile.webUrl,
        service: 'microsoft',
        accessToken, // Store the access token for future API calls
        downloadUrl: oneDriveFile.downloadUrl || oneDriveFile.id
      };

      // Update the selected file in your state
      setSelectedFiles(prev => {
        const currentFiles = prev[categoryId] || [];
        
        // Check if this file is already selected
        if (currentFiles.some(f => f.id === file.id)) {
          return prev; // File already selected, don't add it again
        }
        
        return {
          ...prev,
          [categoryId]: [...currentFiles, file]
        };
      });

      // Try to load sheets for the selected file
      try {
        await loadSheetsForFile(categoryId, file.id, file, 'microsoft');
      } catch (error) {
        console.error('Error loading sheets for file:', error);
        setError('Selected file was added but failed to load sheets. Please try again.');
      }

      // Clear any previous errors
      setError(null);
    } catch (error) {
      console.error('Error handling selected file:', error);
      setError('Failed to process selected file. Please try again.');
      setSelectedFiles(prev => ({
        ...prev,
        [categoryId]: null
      }));
    }
  };

  const removeSelectedFile = (categoryId, fileId) => {
    setSelectedFiles(prev => {
      const currentFiles = prev[categoryId] || [];
      
      // Remove the file with matching ID
      const updatedFiles = currentFiles.filter(f => f.id !== fileId);
      
      if (updatedFiles.length === 0) {
        // If no files left, remove the category entry
        const newFiles = { ...prev };
        delete newFiles[categoryId];
        return newFiles;
      }
      
      // Otherwise update with remaining files
      return {
        ...prev,
        [categoryId]: updatedFiles
      };
    });
    
    // Also remove any sheets associated with this file
    setSelectedSheets(prev => {
      const currentSheets = prev[categoryId] || [];
      
      // Filter out sheets from the removed file
      const updatedSheets = currentSheets.filter(sheet => sheet.fileId !== fileId);
      
      if (updatedSheets.length === 0) {
        // If no sheets left, remove the category entry
        const newSheets = { ...prev };
        delete newSheets[categoryId];
        return newSheets;
      }
      
      // Otherwise update with remaining sheets
      return {
        ...prev,
        [categoryId]: updatedSheets
      };
    });
    
    // Update available sheets as well
    setAvailableSheets(prev => {
      const allSheets = prev[categoryId] || [];
      
      // Filter out sheets from the removed file
      const remainingSheets = allSheets.filter(sheet => sheet.fileId !== fileId);
      
      if (remainingSheets.length === 0) {
        // If no sheets left, remove the category entry
        const newSheets = { ...prev };
        delete newSheets[categoryId];
        return newSheets;
      }
      
      // Otherwise update with remaining sheets
      return {
        ...prev,
        [categoryId]: remainingSheets
      };
    });
  };

  const loadSheetsForFile = async (categoryId, fileId, fileObj, service = 'google') => {
    // Show loading state
    setLoading(prev => ({ ...prev, [categoryId]: true }));
    
    try {
      console.log(`Loading sheets for ${service} file ID: ${fileId}`);
      
      let sheetData;
      
      if (service === 'google') {
        console.log('Fetching sheets from Google API for file ID:', fileId);
        sheetData = await googleService.getSheetData(fileId);
        console.log('Sheet data received from Google:', sheetData);
      } else if (service === 'microsoft') {
        console.log('Fetching sheets from Microsoft Excel file ID:', fileId);
        try {
          // First try the regular method
          sheetData = await microsoftService.getExcelSheets(fileId);
          console.log('Sheet data received from Microsoft:', sheetData);
        } catch (excelError) {
          console.warn('Regular Excel method failed, trying alternative method:', excelError);
          // If regular method fails, try alternative
          sheetData = await microsoftService.getExcelSheetsAlternative(fileId);
          console.log('Sheet data received from Microsoft (alternative method):', sheetData);
        }
      }
      
      if (sheetData && sheetData.sheets && sheetData.sheets.length > 0) {
        // If any sheet doesn't have headers, try to initialize with empty array
        const sheetsWithHeaders = sheetData.sheets.map(sheet => ({
          ...sheet,
          headers: sheet.headers || [],
          fileId: fileId,
          fileName: fileObj?.name || 'Unknown',
          service: service  // Add service type to track the source
        }));
        
        // Add the new sheets to existing available sheets
        setAvailableSheets(prev => {
          const currentSheets = prev[categoryId] || [];
          
          // Filter out any sheets from the same file (in case of re-selecting)
          const sheetsFromOtherFiles = currentSheets.filter(sheet => sheet.fileId !== fileId);
          
          return {
            ...prev,
            [categoryId]: [...sheetsFromOtherFiles, ...sheetsWithHeaders]
          };
        });
        
        // Automatically select the first sheet from the new file
        if (sheetsWithHeaders.length > 0) {
          setSelectedSheets(prev => {
            const currentSelection = prev[categoryId] || [];
            return {
              ...prev,
              [categoryId]: [...currentSelection, sheetsWithHeaders[0]]
            };
          });
        }
      } else {
        console.error(`No sheets found or invalid sheet data from ${service}:`, sheetData);
        // Even if no sheets were found, we should still set empty array to indicate we tried
        setAvailableSheets(prev => {
          const currentSheets = prev[categoryId] || [];
          const sheetsFromOtherFiles = currentSheets.filter(sheet => sheet.fileId !== fileId);
          return {
            ...prev,
            [categoryId]: sheetsFromOtherFiles
          };
        });
        
        if (service === 'microsoft') {
          setError(`No sheets found in the Excel file. Please verify that the file contains worksheets and try again.`);
        } else {
          setError(`No sheets found in the selected file.`);
        }
      }
    } catch (error) {
      console.error(`Error fetching sheets from ${service}:`, error);
      setError(`Failed to load sheets: ${error.message}`);
      // Don't re-throw the error, just report it to the user
    } finally {
      // Hide loading state
      setLoading(prev => ({ ...prev, [categoryId]: false }));
    }
  };

  const handleSheetToggle = (categoryId, sheet) => {
    setSelectedSheets(prev => {
      const currentSelected = prev[categoryId] || [];
      
      // Check if this sheet is already selected
      const isSelected = currentSelected.some(s => s.id === sheet.id);
      
      if (isSelected) {
        // Remove sheet if already selected, but keep all other sheets
        const updatedSheets = currentSelected.filter(s => s.id !== sheet.id);
        
        return {
          ...prev,
          [categoryId]: updatedSheets
        };
      } else {
        // Add sheet if not selected
        return {
          ...prev,
          [categoryId]: [...currentSelected, sheet]
        };
      }
    });
  };

  const isSheetSelected = (categoryId, sheetId, fileId) => {
    return (selectedSheets[categoryId] || []).some(
      sheet => sheet.id === sheetId && sheet.fileId === fileId
    );
  };

  const getAvailableColumnsForMapping = (categoryId) => {
    // Get all selected sheets
    const selectedSheetsArray = selectedSheets[categoryId] || [];
    
    if (selectedSheetsArray.length === 0) {
      return [];
    }
    
    // First group sheets by file
    const sheetsByFile = {};
    selectedSheetsArray.forEach(sheet => {
      if (!sheetsByFile[sheet.fileId]) {
        sheetsByFile[sheet.fileId] = {
          fileName: sheet.fileName,
          sheets: []
        };
      }
      sheetsByFile[sheet.fileId].sheets.push(sheet);
    });
    
    // Now collect columns from each file, organized by file
    const result = [];
    
    // First add columns that appear in multiple files (if any)
    const allHeadersMap = {};
    
    // Collect all headers and count their occurrences
    selectedSheetsArray.forEach(sheet => {
      if (sheet.headers && Array.isArray(sheet.headers)) {
        sheet.headers.forEach(header => {
          if (!allHeadersMap[header]) {
            allHeadersMap[header] = {
              count: 0,
              files: new Set(),
              sources: []
            };
          }
          allHeadersMap[header].count++;
          allHeadersMap[header].files.add(sheet.fileId);
          allHeadersMap[header].sources.push({
            sheetTitle: sheet.title,
            fileName: sheet.fileName,
            fileId: sheet.fileId
          });
        });
      }
    });
    
    // First add headers that appear in multiple files
    Object.entries(allHeadersMap).forEach(([header, info]) => {
      if (info.files.size > 1) { // Column appears in multiple files
        result.push({
          value: header,
          label: header,
          sources: info.sources,
          multiFile: true
        });
      }
    });
    
    // Add a separator if we have multi-file columns
    if (result.length > 0) {
      result.push({
        value: 'separator-multi',
        label: '─────────────────────',
        disabled: true
      });
    }
    
    // Then add headers organized by file
    Object.entries(sheetsByFile).forEach(([fileId, fileInfo], fileIndex) => {
      // Add file header
      if (fileIndex > 0) {
        result.push({
          value: `separator-file-${fileId}`,
          label: '─────────────────────',
          disabled: true
        });
      }
      
      result.push({
        value: `header-${fileId}`,
        label: fileInfo.fileName,
        isFileHeader: true,
        disabled: true
      });
      
      // Collect all unique headers from this file's sheets
      const fileHeadersMap = {};
      fileInfo.sheets.forEach(sheet => {
        if (sheet.headers && Array.isArray(sheet.headers)) {
          sheet.headers.forEach(header => {
            if (!fileHeadersMap[header]) {
              fileHeadersMap[header] = [];
            }
            fileHeadersMap[header].push({
              sheetTitle: sheet.title,
              fileName: sheet.fileName
            });
          });
        }
      });
      
      // Add all headers from this file
      Object.entries(fileHeadersMap).forEach(([header, sources]) => {
        // Only add if it hasn't been added as a multi-file header
        if (!allHeadersMap[header] || allHeadersMap[header].files.size <= 1) {
          result.push({
            value: header,
            label: header,
            sources: sources,
            fileId: fileId
          });
        }
      });
    });
    
    return result;
  };

  const handleMappingChange = (categoryId, fieldId, column) => {
    setSelectedMappings(prev => ({
      ...prev,
      [categoryId]: { ...(prev[categoryId] || {}), [fieldId]: column }
    }));
  };

  const handleSaveMapping = (categoryId) => {
    console.log('Saving mappings:', {
      category: categoryId,
      file: selectedFiles[categoryId],
      sheets: selectedSheets[categoryId],
      mappings: selectedMappings[categoryId]
    });
    // Here you would implement the actual save logic to your backend
    alert('Mapping saved successfully!');
  };

  const getConnectedServiceName = () => {
    if (connectedServices.google) {
      return "Google Sheets";
    } else if (connectedServices.microsoft) {
      return "Microsoft Excel";
    }
    return null;
  };

  const renderCategoryContent = (categoryId, category) => {
    const connectedServiceName = getConnectedServiceName();
    
    if (!connectedServiceName) {
      return (
        <div className="py-4 text-center">
          <p className="text-gray-500 mb-4">You need to connect to Google Sheets or Microsoft Excel first.</p>
          <p className="text-gray-500">Please navigate to App Integrations and connect to Google Workspace or Microsoft Office 365.</p>
        </div>
      );
    }
    
    return (
      <div className="grid grid-cols-[1fr,2fr] gap-8">
        {/* Left column: Data source & selected sheets */}
        <div className="space-y-6">
          <div className="space-y-4">
            <h3 className="text-sm font-medium text-gray-700">Data Source</h3>
            <Select 
              value={
                connectedServices.google && connectedServices.microsoft 
                  ? (selectedFiles[categoryId]?.[0]?.service === 'microsoft' ? 'microsoft_excel' : 'google_sheets')
                  : connectedServices.google ? 'google_sheets' : 'microsoft_excel'
              }
              onValueChange={(value) => {
                // If changing service type, reset selected files and sheets
                if ((value === 'google_sheets' && selectedFiles[categoryId]?.[0]?.service === 'microsoft') ||
                    (value === 'microsoft_excel' && selectedFiles[categoryId]?.[0]?.service === 'google')) {
                  setSelectedFiles(prev => {
                    const newFiles = { ...prev };
                    delete newFiles[categoryId];
                    return newFiles;
                  });
                  setSelectedSheets(prev => {
                    const newSheets = { ...prev };
                    delete newSheets[categoryId];
                    return newSheets;
                  });
                  setAvailableSheets(prev => {
                    const newSheets = { ...prev };
                    delete newSheets[categoryId];
                    return newSheets;
                  });
                }
              }}
              disabled={!connectedServices.google || !connectedServices.microsoft}
            >
              <SelectTrigger className="bg-white border-muted">
                <SelectValue>
                  {connectedServices.google && connectedServices.microsoft 
                    ? (selectedFiles[categoryId]?.[0]?.service === 'microsoft' ? 'Microsoft Excel' : 'Google Sheets')
                    : connectedServices.google ? 'Google Sheets' : 'Microsoft Excel'}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {connectedServices.google && (
                  <SelectItem value="google_sheets">Google Sheets</SelectItem>
                )}
                {connectedServices.microsoft && (
                  <SelectItem value="microsoft_excel">Microsoft Excel</SelectItem>
                )}
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium text-gray-700">Selected Files</h3>
              {selectedFiles[categoryId]?.length > 0 && (
                <Badge variant="outline" className="py-0 px-2 text-xs bg-gray-50">
                  {selectedFiles[categoryId].length} file{selectedFiles[categoryId].length !== 1 ? 's' : ''}
                </Badge>
              )}
            </div>
            
            <div className="space-y-3">
              {selectedFiles[categoryId]?.length > 0 ? (
                <div className="space-y-3">
                  {selectedFiles[categoryId].map((file) => (
                    <div key={file.id} className="flex items-center">
                      <div className="flex-grow border rounded-md py-2 px-3 bg-white flex items-center">
                        <FileSpreadsheet className="h-4 w-4 mr-2 text-blue-600" />
                        <span className="text-sm truncate text-gray-800">{file.name}</span>
                      </div>
                      <Button 
                        variant="ghost" 
                        size="sm" 
                        onClick={() => removeSelectedFile(categoryId, file.id)}
                        className="ml-2 h-9 w-9 p-0"
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                  
                  <Button 
                    variant="outline"
                    onClick={() => {
                      const preferredService = selectedFiles[categoryId]?.[0]?.service ||
                        (connectedServices.google && !connectedServices.microsoft ? 'google' : 
                        !connectedServices.google && connectedServices.microsoft ? 'microsoft' : 'google');
                        
                      if (preferredService === 'google' && connectedServices.google) {
                        openGoogleDrivePicker(categoryId);
                      } else if (preferredService === 'microsoft' && connectedServices.microsoft) {
                        handleSelectMicrosoftFile(categoryId);
                      }
                    }}
                    className="flex items-center w-full justify-between"
                    disabled={(connectedServices.google && !isGooglePickerLoaded) || 
                            (connectedServices.microsoft && !isMicrosoftPickerLoaded) || 
                            loading[categoryId]}
                  >
                    <div className="flex items-center">
                      <PlusCircle className="h-4 w-4 mr-2" />
                      <span>Add More Files</span>
                    </div>
                    <FolderOpen className="h-4 w-4" />
                  </Button>
                </div>
              ) : (
                <Button 
                  variant="outline"
                  onClick={() => {
                    const preferredService =
                      (connectedServices.google && !connectedServices.microsoft ? 'google' : 
                       !connectedServices.google && connectedServices.microsoft ? 'microsoft' : 'google');
                       
                    if (preferredService === 'google' && connectedServices.google) {
                      openGoogleDrivePicker(categoryId);
                    } else if (preferredService === 'microsoft' && connectedServices.microsoft) {
                      handleSelectMicrosoftFile(categoryId);
                    }
                  }}
                  className="w-full flex items-center justify-center"
                  disabled={(connectedServices.google && !isGooglePickerLoaded) || 
                           (connectedServices.microsoft && !isMicrosoftPickerLoaded) || 
                           loading[categoryId]}
                >
                  {loading[categoryId] ? (
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  ) : (
                    <FolderOpen className="h-4 w-4 mr-2" />
                  )}
                  Select File
                </Button>
              )}
              
              {loading[categoryId] && (
                <div className="flex items-center py-2">
                  <Loader2 className="h-4 w-4 mr-2 animate-spin text-gray-400" />
                  <span className="text-sm text-gray-500">Loading sheets...</span>
                </div>
              )}
              
              {selectedFiles[categoryId]?.length > 0 && availableSheets[categoryId]?.length > 0 && (
                <div className="bg-white border rounded-md mt-4">
                  <div className="border-b px-4 py-3 flex items-center justify-between">
                    <h3 className="text-sm font-medium text-gray-700">Available Sheets</h3>
                    <Badge variant="outline" className="py-0 px-2 text-xs bg-gray-50">
                      {availableSheets[categoryId].length} sheet{availableSheets[categoryId].length !== 1 ? 's' : ''}
                    </Badge>
                  </div>
                  <div className="p-4 space-y-2">
                    {availableSheets[categoryId].map(sheet => (
                      <div key={`${sheet.fileId}-${sheet.id}`} className="flex items-center space-x-3 py-1.5 px-1 hover:bg-gray-50 rounded">
                        <Checkbox 
                          id={`sheet-${categoryId}-${sheet.fileId}-${sheet.id}`}
                          checked={isSheetSelected(categoryId, sheet.id, sheet.fileId)}
                          onCheckedChange={(checked) => {
                            // Direct approach to toggle single sheet without affecting others
                            setSelectedSheets(prev => {
                              const currentSelected = [...(prev[categoryId] || [])];
                              
                              if (checked) {
                                // Add the sheet if it's not already there
                                if (!currentSelected.some(s => s.id === sheet.id && s.fileId === sheet.fileId)) {
                                  return {
                                    ...prev,
                                    [categoryId]: [...currentSelected, sheet]
                                  };
                                }
                              } else {
                                // Remove the sheet if it's there
                                return {
                                  ...prev,
                                  [categoryId]: currentSelected.filter(
                                    s => !(s.id === sheet.id && s.fileId === sheet.fileId)
                                  )
                                };
                              }
                              
                              return prev;
                            });
                          }}
                          className="h-4 w-4"
                        />
                        <div className="w-full flex items-center justify-between">
                          <label 
                            htmlFor={`sheet-${categoryId}-${sheet.fileId}-${sheet.id}`}
                            className="text-sm text-gray-700 cursor-pointer flex items-center"
                          >
                            <span className="font-medium">{sheet.title}</span>
                            {sheet.fileName && sheet.fileName !== sheet.title && (
                              <span className="text-xs text-gray-500 ml-2">
                                {sheet.fileName}
                              </span>
                            )}
                          </label>
                          <div className="flex items-center space-x-2">
                            {sheet.headers?.length > 0 && (
                              <Badge variant="outline" className="py-0.5 px-2 text-xs bg-gray-50 text-gray-700">
                                {sheet.headers.length} columns
                              </Badge>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="bg-gray-50 border-t px-4 py-3 text-right">
                    <Button 
                      variant="outline" 
                      size="sm"
                      onClick={() => {
                        // Toggle all sheets
                        const allSelected = availableSheets[categoryId].every(
                          sheet => isSheetSelected(categoryId, sheet.id, sheet.fileId)
                        );
                        
                        if (allSelected) {
                          // Deselect all
                          setSelectedSheets(prev => {
                            const newSheets = { ...prev };
                            delete newSheets[categoryId];
                            return newSheets;
                          });
                        } else {
                          // Select all
                          setSelectedSheets(prev => ({
                            ...prev,
                            [categoryId]: [...availableSheets[categoryId]]
                          }));
                        }
                      }}
                    >
                      {availableSheets[categoryId].every(
                        sheet => isSheetSelected(categoryId, sheet.id, sheet.fileId)
                      ) 
                        ? "Deselect All" 
                        : "Select All"
                      }
                    </Button>
                  </div>
                </div>
              )}
              
              {selectedFiles[categoryId]?.length > 0 && !loading[categoryId] && (!availableSheets[categoryId] || availableSheets[categoryId].length === 0) && (
                <div className="bg-gray-50 border rounded-md p-4 text-center">
                  <p className="text-sm text-gray-500 mb-2">No sheets found in the selected Excel file.</p>
                  <p className="text-xs text-gray-500 mb-2">Please try a different file or check that the file contains worksheets.</p>
                  
                  {error && (
                    <div className="mt-2 p-3 bg-red-50 border border-red-100 rounded-md text-left">
                      <p className="text-xs font-medium text-red-800 mb-1">Error details:</p>
                      <p className="text-xs text-red-700">{error}</p>
                      <p className="text-xs text-gray-500 mt-2">
                        Try using a simpler Excel file without complex formulas or formatting.
                        Some newer Excel features may not be supported by the Microsoft Graph API.
                      </p>
                      {selectedFiles[categoryId]?.[0]?.service === 'microsoft' && error.includes('permission') && (
                        <div className="mt-2">
                          <p className="text-xs text-gray-700 mb-1">Having permission issues?</p>
                          <Button 
                            variant="outline" 
                            size="sm"
                            onClick={handleRefreshMicrosoftToken}
                            disabled={loading.microsoftRefresh}
                            className="mt-1 h-8 text-xs w-full"
                          >
                            {loading.microsoftRefresh ? (
                              <>
                                <Loader2 className="h-3 w-3 mr-1 animate-spin" />
                                Refreshing Permissions...
                              </>
                            ) : (
                              <>
                                <RefreshCcw className="h-3 w-3 mr-1" />
                                Refresh Microsoft Permissions
                              </>
                            )}
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                  
                  <Button
                    variant="outline" 
                    size="sm"
                    onClick={() => {
                      // Reset error state and remove the problematic file
                      setError(null);
                      if (selectedFiles[categoryId]?.length > 0) {
                        const fileId = selectedFiles[categoryId][0].id;
                        removeSelectedFile(categoryId, fileId);
                      }
                    }}
                    className="mt-4"
                  >
                    Try Different File
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        
        {/* Right column: Field mapping */}
        <div className="space-y-6">
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-4">Field Mapping</h3>
            {selectedSheets[categoryId]?.length > 0 ? (
              <div className="bg-white border rounded-md">
                <div className="border-b px-4 py-3 flex items-center justify-between">
                  <h4 className="text-sm font-medium">Map Property Fields</h4>
                  <Button 
                    variant="outline" 
                    size="sm"
                    onClick={() => {
                      // Auto-map fields with same names
                      const columns = getAvailableColumnsForMapping(categoryId);
                      const newMappings = {};
                      
                      category.fields.forEach(field => {
                        // Try to find a matching column
                        const matchingColumn = columns.find(col => 
                          col.label && !col.disabled && 
                          col.label.toLowerCase() === field.label.toLowerCase()
                        );
                        
                        if (matchingColumn) {
                          newMappings[field.id] = matchingColumn.value;
                        }
                      });
                      
                      setSelectedMappings(prev => ({
                        ...prev,
                        [categoryId]: { ...(prev[categoryId] || {}), ...newMappings }
                      }));
                    }}
                  >
                    Auto-Map Fields
                  </Button>
                </div>
                
                <div className="p-4">
                  <div className="grid grid-cols-[1fr,1.5fr,1fr] gap-4 text-sm font-medium text-gray-500 pb-2 mb-2 border-b">
                    <div>Property Field</div>
                    <div>Source Column</div>
                    <div>Preview</div>
                  </div>

                  <div className="space-y-5">
                    {category.fields.map((field) => (
                      <div key={field.id} className="grid grid-cols-[1fr,1.5fr,1fr] gap-4 items-center">
                        <div className="font-medium text-sm">{field.label}</div>
                        <Select 
                          value={selectedMappings[categoryId]?.[field.id] || ''} 
                          onValueChange={(value) => handleMappingChange(categoryId, field.id, value)}
                        >
                          <SelectTrigger className="bg-white">
                            <SelectValue placeholder="Select column" />
                          </SelectTrigger>
                          <SelectContent>
                            {getAvailableColumnsForMapping(categoryId).map(column => {
                              // For separators
                              if (column.disabled && column.value.startsWith('separator')) {
                                return (
                                  <SelectItem key={column.value} value={column.value} disabled className="border-b border-gray-200 py-1">
                                    <div className="text-xs text-gray-400">{column.label}</div>
                                  </SelectItem>
                                );
                              }
                              
                              // For file headers
                              if (column.isFileHeader) {
                                return (
                                  <SelectItem key={column.value} value={column.value} disabled className="pt-1 pb-0">
                                    <div className="font-medium text-sm text-gray-600">
                                      {column.label}
                                    </div>
                                  </SelectItem>
                                );
                              }
                              
                              // For actual column options
                              return (
                                <SelectItem key={column.value} value={column.value}>
                                  <div className="flex flex-col">
                                    <span>{column.label}</span>
                                    {column.multiFile ? (
                                      <span className="text-xs text-blue-600">
                                        Available in {column.sources.length} sheets across {column.sources.reduce((acc, src) => {
                                          acc.add(src.fileId);
                                          return acc;
                                        }, new Set()).size} files
                                      </span>
                                    ) : column.sources.length > 1 ? (
                                      <span className="text-xs text-gray-500">
                                        Available in {column.sources.length} sheets
                                      </span>
                                    ) : (
                                      <span className="text-xs text-gray-500">
                                        Sheet: {column.sources[0].sheetTitle}
                                      </span>
                                    )}
                                  </div>
                                </SelectItem>
                              );
                            })}
                          </SelectContent>
                        </Select>
                        <div className="text-sm text-gray-500 italic">{field.preview}</div>
                      </div>
                    ))}
                  </div>
                </div>
                
                <div className="bg-gray-50 border-t px-6 py-4 flex justify-end">
                  <Button 
                    onClick={() => handleSaveMapping(categoryId)} 
                    disabled={!selectedSheets[categoryId]?.length || !Object.keys(selectedMappings[categoryId] || {}).length}
                  >
                    Save Mapping
                  </Button>
                </div>
              </div>
            ) : (
              <div className="bg-gray-50 border rounded-md p-8 text-center">
                <p className="text-gray-500 mb-4">Select a file and at least one sheet to map fields</p>
                <Button 
                  variant="outline"
                  onClick={() => {
                    const preferredService =
                      (connectedServices.google && !connectedServices.microsoft ? 'google' : 
                       !connectedServices.google && connectedServices.microsoft ? 'microsoft' : 'google');
                       
                    if (preferredService === 'google' && connectedServices.google) {
                      openGoogleDrivePicker(categoryId);
                    } else if (preferredService === 'microsoft' && connectedServices.microsoft) {
                      handleSelectMicrosoftFile(categoryId);
                    }
                  }}
                  className="w-full flex items-center justify-center"
                  disabled={(connectedServices.google && !isGooglePickerLoaded) || 
                           (connectedServices.microsoft && !isMicrosoftPickerLoaded) || 
                           loading[categoryId]}
                >
                  {loading[categoryId] ? (
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  ) : (
                    <FolderOpen className="h-4 w-4 mr-2" />
                  )}
                  Select File
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const noServicesConnected = !connectedServices.google && !connectedServices.microsoft;

  // Create a custom Accordion component that uses div elements with click handlers
  // instead of the default Accordion component
  const renderAccordionItems = () => {
    return Object.entries(MAPPING_CATEGORIES).map(([categoryId, category]) => {
      const isOpen = openItems.includes(categoryId);
      
      return (
        <div key={categoryId} className="border rounded-lg overflow-hidden bg-gray-50 mb-4">
          <div 
            className="px-6 py-4 hover:bg-gray-100 cursor-pointer flex items-center justify-between"
            onClick={() => handleToggleItem(categoryId)}
          >
            <div className="flex items-center gap-3">
              <div className={`w-8 h-8 flex items-center justify-center ${category.colors.bg} rounded-md`}>
                <category.icon className={`h-5 w-5 ${category.colors.text}`} />
              </div>
              <span className="font-medium">{category.title}</span>
              {selectedSheets[categoryId]?.length > 0 && (
                <Badge variant="outline" className="ml-2 py-0 px-2 text-xs">
                  {selectedSheets[categoryId].length} sheet{selectedSheets[categoryId].length !== 1 ? 's' : ''}
                </Badge>
              )}
            </div>
            <div className="transform transition-transform duration-200" style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
          
          {isOpen && (
            <div className="px-6 py-6">
              {renderCategoryContent(categoryId, category)}
            </div>
          )}
        </div>
      );
    });
  };

  // Handler to force refresh Microsoft token and permissions
  const handleRefreshMicrosoftToken = async () => {
    try {
      setLoading(prev => ({ ...prev, microsoftRefresh: true }));
      setError('');
      
      // Force token refresh to get new permissions
      await microsoftService.forceTokenRefresh();
      
      // Update connection status
      setConnectedServices(prev => ({
        ...prev,
        microsoft: true
      }));
      
      // Reload the picker API
      if (!isMicrosoftPickerLoaded) {
        await loadMicrosoftFilePickerApi();
        setIsMicrosoftPickerLoaded(true);
      }
      
      alert("Microsoft permissions refreshed. Your Microsoft account permissions have been updated. Please try accessing files again.");
    } catch (error) {
      console.error('Error refreshing Microsoft token:', error);
      setError(`Failed to refresh Microsoft permissions: ${error.message}`);
    } finally {
      setLoading(prev => ({ ...prev, microsoftRefresh: false }));
    }
  };

  return (
    <div className="max-w-5xl mx-auto py-8">
      <div className="mb-6">
        <h2 className="text-xl font-semibold">Field Mapping</h2>
        <p className="text-sm text-gray-600">Map your data fields to columns in your data source</p>
      </div>

      {noServicesConnected ? (
        <Card>
          <CardContent className="py-8">
            <div className="text-center text-gray-500">
              <p className="mb-4">No connected data sources available.</p>
              <p>Please navigate to App Integrations and connect to Google Workspace or Microsoft Office 365 first.</p>
            </div>
          </CardContent>
        </Card>
      ) : (
        <div className="space-y-4">
          {renderAccordionItems()}
        </div>
      )}
    </div>
  );
};

export default ManageIntegrations; 