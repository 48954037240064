"use client"

import { Card, CardContent } from "../../ui/card.jsx"
import { Input } from "../../ui/input.jsx"
import { Label } from "../../ui/label.jsx"
import { Textarea } from "../../ui/textarea.jsx"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select.jsx"
import { MapPin, Home, Bed, Bath, PoundSterling } from "lucide-react"

export function PropertyInfoTab({ property, isEditing, onChange }) {
  if (!isEditing) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <MapPin className="h-5 w-5 text-muted-foreground mt-0.5" />
                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground">Address</h3>
                    <p className="text-base font-medium">{property.address}</p>
                    <p className="text-base">
                      {property.city}, {property.postcode}
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <PoundSterling className="h-5 w-5 text-muted-foreground mt-0.5" />
                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground">Price</h3>
                    <p className="text-base font-medium">
                      {new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "GBP",
                        maximumFractionDigits: 0,
                      }).format(property.price)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <Home className="h-5 w-5 text-muted-foreground mt-0.5" />
                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground">Property Type</h3>
                    <p className="text-base">{property.propertyType}</p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <Bed className="h-5 w-5 text-muted-foreground mt-0.5" />
                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground">Bedrooms</h3>
                    <p className="text-base">{property.bedrooms}</p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <Bath className="h-5 w-5 text-muted-foreground mt-0.5" />
                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground">Bathrooms</h3>
                    <p className="text-base">{property.bathrooms}</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-sm font-medium text-muted-foreground mb-2">Description</h3>
              <div className="p-4 bg-muted/20 rounded-md">
                <p className="text-base whitespace-pre-wrap">{property.description}</p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card>
      <CardContent className="pt-6 space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="address">Address</Label>
              <Input id="address" value={property.address} onChange={(e) => onChange({ address: e.target.value })} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="city">City</Label>
              <Input id="city" value={property.city} onChange={(e) => onChange({ city: e.target.value })} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="postcode">Postcode</Label>
              <Input id="postcode" value={property.postcode} onChange={(e) => onChange({ postcode: e.target.value })} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="price">Price (£)</Label>
              <Input
                id="price"
                type="number"
                value={property.price}
                onChange={(e) => onChange({ price: Number(e.target.value) })}
              />
            </div>
          </div>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="property-type">Property Type</Label>
              <Select value={property.propertyType} onValueChange={(value) => onChange({ propertyType: value })}>
                <SelectTrigger id="property-type">
                  <SelectValue placeholder="Select property type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="House">House</SelectItem>
                  <SelectItem value="Apartment">Apartment</SelectItem>
                  <SelectItem value="Bungalow">Bungalow</SelectItem>
                  <SelectItem value="Cottage">Cottage</SelectItem>
                  <SelectItem value="Flat">Flat</SelectItem>
                  <SelectItem value="Maisonette">Maisonette</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="bedrooms">Bedrooms</Label>
              <Input
                id="bedrooms"
                type="number"
                value={property.bedrooms}
                onChange={(e) => onChange({ bedrooms: Number(e.target.value) })}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="bathrooms">Bathrooms</Label>
              <Input
                id="bathrooms"
                type="number"
                value={property.bathrooms}
                onChange={(e) => onChange({ bathrooms: Number(e.target.value) })}
              />
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <Label htmlFor="description">Description</Label>
          <Textarea
            id="description"
            value={property.description}
            onChange={(e) => onChange({ description: e.target.value })}
            rows={4}
          />
        </div>
      </CardContent>
    </Card>
  )
} 