import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartCard = ({ title, data, labels, backgroundColor }) => {
  // Modern, vibrant colors
  const defaultColors = [
    '#4f46e5', // Indigo
    '#f97316', // Orange
    '#06b6d4', // Cyan
    '#ec4899', // Pink
    '#84cc16', // Lime
    '#8b5cf6', // Purple
    '#ef4444'  // Red
  ];
  
  const chartColors = backgroundColor || defaultColors;

  const chartData = {
    labels,
    datasets: [{
      data,
      backgroundColor: chartColors,
      borderWidth: 2,
      borderColor: '#ffffff',
      hoverBorderWidth: 0,
      hoverOffset: 7,
    }],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        align: 'center',
        labels: {
          font: {
            family: 'Inter, system-ui, sans-serif',
            size: 12,
            weight: '500',
          },
          color: '#4b5563',
          usePointStyle: true,
          pointStyle: 'rectRounded',
          padding: 20,
          boxWidth: 10,
          boxHeight: 10,
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            const labels = chart.data.labels;
            
            if (datasets.length && labels.length) {
              return labels.map((label, i) => {
                const meta = chart.getDatasetMeta(0);
                const style = meta.controller.getStyle(i);
                const value = datasets[0].data[i];
                const total = datasets[0].data.reduce((sum, val) => sum + val, 0);
                const percentage = Math.round((value / total) * 100);
                
                return {
                  text: `${label} · ${percentage}%`,
                  fillStyle: style.backgroundColor,
                  strokeStyle: '#fff',
                  lineWidth: 1,
                  hidden: false,
                  index: i
                };
              });
            }
            return [];
          }
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.raw || 0;
            const total = context.dataset.data.reduce((acc, data) => acc + data, 0);
            const percentage = Math.round((value / total) * 100);
            return `${label}: ${value} (${percentage}%)`;
          }
        },
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        titleColor: '#111827',
        bodyColor: '#4b5563',
        titleFont: {
          size: 13,
          weight: 'bold',
        },
        bodyFont: {
          size: 12,
        },
        padding: 12,
        cornerRadius: 6,
        boxPadding: 4,
        borderColor: 'rgba(229, 231, 235, 1)',
        borderWidth: 1,
        displayColors: true,
      }
    },
    animation: {
      animateRotate: true,
      animateScale: true,
      duration: 800,
      easing: 'easeOutQuart',
    },
    layout: {
      padding: {
        top: 5,
        bottom: 5,
        left: 10,
        right: 10
      }
    },
    elements: {
      arc: {
        borderWidth: 2,
        borderColor: '#ffffff',
      }
    },
    cutout: '0%',
    radius: '85%',
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="p-5">
        <h3 className="text-lg font-medium text-gray-800 mb-2">{title}</h3>
        <div className="border-t border-gray-100 pt-4 mt-2">
          <div className="h-64">
            <Pie data={chartData} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChartCard; 