import React, { useState, useEffect } from 'react';
import { Button } from '../ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card";
import { googleService } from '../../services/googleService';
import { microsoftService } from '../../services/microsoftService';
import googleLogo from '../../assets/google-logo.svg';
import microsoftLogo from '../../assets/microsoft-logo.svg';

const IntegrationCard = ({ 
  title, 
  description, 
  features, 
  logo, 
  isConnected, 
  onConnect 
}) => (
  <Card className="mb-6 transition-all duration-200 hover:shadow-lg">
    <CardHeader>
      <div className="flex items-center gap-3">
        <img 
          src={logo}
          alt={title} 
          className="w-8 h-8"
        />
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription className="mt-1">
            {description}
          </CardDescription>
        </div>
      </div>
    </CardHeader>
    <CardContent>
      <div className="space-y-4">
        <div className="text-sm text-gray-600">
          <ul className="list-disc list-inside space-y-1">
            {features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </div>
        <Button
          variant={isConnected ? "outline" : "default"}
          className={`w-full ${isConnected ? 'bg-green-50 text-green-600 hover:bg-green-100 border-green-200' : ''}`}
          onClick={onConnect}
        >
          {isConnected ? 'Connected' : 'Connect'}
        </Button>
      </div>
    </CardContent>
  </Card>
);

const AppIntegrations = () => {
  const [googleConnected, setGoogleConnected] = useState(false);
  const [microsoftConnected, setMicrosoftConnected] = useState(false);

  useEffect(() => {
    // Check initial connection states
    setGoogleConnected(googleService.isConnected());
    setMicrosoftConnected(microsoftService.isConnected());
  }, []);

  const handleGoogleConnect = async () => {
    try {
      if (googleConnected) {
        await googleService.disconnect();
        setGoogleConnected(false);
      } else {
        await googleService.getOAuthToken();
        setGoogleConnected(true);
      }
    } catch (error) {
      console.error('Failed to connect to Google:', error);
    }
  };

  const handleMicrosoftConnect = async () => {
    try {
      if (microsoftConnected) {
        await microsoftService.disconnect();
        setMicrosoftConnected(false);
      } else {
        await microsoftService.getOAuthToken();
        setMicrosoftConnected(true);
      }
    } catch (error) {
      console.error('Failed to connect to Microsoft:', error);
    }
  };

  return (
    <div className="max-w-3xl mx-auto py-8">
      <div className="mb-8">
        <h2 className="text-2xl font-bold">App Integrations</h2>
        <p className="text-sm text-gray-600 mt-1">
          Connect your apps to enable data synchronization
        </p>
      </div>

      <div className="space-y-6">
        <IntegrationCard
          title="Google Workspace"
          description="Access to Google Drive, Sheets, and Calendar"
          features={[
            'Store and manage files in Google Drive',
            'Access and update data in Google Sheets',
            'Manage calendar events and schedules'
          ]}
          logo={googleLogo}
          isConnected={googleConnected}
          onConnect={handleGoogleConnect}
        />

        <IntegrationCard
          title="Microsoft Office 365"
          description="Access to Excel, OneDrive, and Outlook Calendar"
          features={[
            'Access and update data in Excel files',
            'Store and manage files in OneDrive',
            'Manage calendar events in Outlook'
          ]}
          logo={microsoftLogo}
          isConnected={microsoftConnected}
          onConnect={handleMicrosoftConnect}
        />
      </div>
    </div>
  );
};

export default AppIntegrations; 