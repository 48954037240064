"use client"

import { useState } from "react"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs"
import { Button } from "../ui/button"
import { Switch } from "../ui/switch"
import { Label } from "../ui/label"
import { Edit, Save, X } from "lucide-react"
import { PropertyInfoTab } from "./tabs/PropertyInfoTab"
import { PropertyCalendarTab } from "./tabs/PropertyCalendarTab"
import { PropertyLeaseholdTab } from "./tabs/PropertyLeaseholdTab"
import { PropertyNotesTab } from "./tabs/PropertyNotesTab"

export function PropertyDetails({ property, onUpdate }) {
  const [isEditing, setIsEditing] = useState(false)
  const [currentProperty, setCurrentProperty] = useState(property)

  const handlePropertyChange = (updatedValues) => {
    setCurrentProperty({
      ...currentProperty,
      ...updatedValues,
    })
  }

  const handleSave = () => {
    onUpdate(currentProperty)
    setIsEditing(false)
  }

  const handleCancel = () => {
    setCurrentProperty(property)
    setIsEditing(false)
  }

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      maximumFractionDigits: 0,
    }).format(price)
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-3xl font-bold">{formatPrice(property.price)}</h2>
          <p className="text-muted-foreground">
            {property.propertyType} • {property.bedrooms} bed • {property.bathrooms} bath
          </p>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center space-x-2">
            <Switch
              id="agent-confirmation"
              checked={currentProperty.agentConfirmationRequired}
              onCheckedChange={(checked) => handlePropertyChange({ agentConfirmationRequired: checked })}
            />
            <Label htmlFor="agent-confirmation">Agent confirmation required</Label>
          </div>

          {isEditing ? (
            <div className="flex gap-2">
              <Button variant="outline" onClick={handleCancel} size="sm">
                <X className="h-4 w-4 mr-1" />
                Cancel
              </Button>
              <Button onClick={handleSave} size="sm">
                <Save className="h-4 w-4 mr-1" />
                Save
              </Button>
            </div>
          ) : (
            <Button onClick={() => setIsEditing(true)} size="sm" variant="outline">
              <Edit className="h-4 w-4 mr-1" />
              Edit
            </Button>
          )}
        </div>
      </div>

      <Tabs defaultValue="info">
        <TabsList className="grid w-full grid-cols-4">
          <TabsTrigger value="info">Property Info</TabsTrigger>
          <TabsTrigger value="calendar">Availability</TabsTrigger>
          <TabsTrigger value="leasehold">Leasehold Info</TabsTrigger>
          <TabsTrigger value="notes">Notes</TabsTrigger>
        </TabsList>

        <TabsContent value="info">
          <PropertyInfoTab property={currentProperty} isEditing={isEditing} onChange={handlePropertyChange} />
        </TabsContent>

        <TabsContent value="calendar">
          <PropertyCalendarTab property={currentProperty} isEditing={isEditing} onChange={handlePropertyChange} />
        </TabsContent>

        <TabsContent value="leasehold">
          <PropertyLeaseholdTab property={currentProperty} isEditing={isEditing} onChange={handlePropertyChange} />
        </TabsContent>

        <TabsContent value="notes">
          <PropertyNotesTab property={currentProperty} isEditing={isEditing} onChange={handlePropertyChange} />
        </TabsContent>
      </Tabs>
    </div>
  )
} 