"use client"

import { useState } from "react"
import { Card, CardContent } from "../ui/card.jsx"
import { Input } from "../ui/input.jsx"
import { Label } from "../ui/label.jsx"
import { Textarea } from "../ui/textarea.jsx"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select.jsx"
import { Button } from "../ui/button.jsx"
import { Switch } from "../ui/switch.jsx"

export function AddPropertyForm({ onSubmit }) {
  const [formData, setFormData] = useState({
    address: "",
    city: "",
    postcode: "",
    description: "",
    price: "",
    bedrooms: "",
    bathrooms: "",
    propertyType: "House",
    tenure: "Freehold",
    leaseYearsRemaining: "",
    serviceCharge: "",
    groundRent: "",
    agentConfirmationRequired: false,
    availabilityWindows: [],
    additionalNotes: "",
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit({
      ...formData,
      price: Number(formData.price),
      bedrooms: Number(formData.bedrooms),
      bathrooms: Number(formData.bathrooms),
      leaseYearsRemaining: formData.leaseYearsRemaining ? Number(formData.leaseYearsRemaining) : null,
      serviceCharge: formData.serviceCharge ? Number(formData.serviceCharge) : null,
      groundRent: formData.groundRent ? Number(formData.groundRent) : null,
    })
  }

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <Card>
        <CardContent className="pt-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="address">Address</Label>
                <Input
                  id="address"
                  required
                  value={formData.address}
                  onChange={(e) => handleChange("address", e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="city">City</Label>
                <Input
                  id="city"
                  required
                  value={formData.city}
                  onChange={(e) => handleChange("city", e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="postcode">Postcode</Label>
                <Input
                  id="postcode"
                  required
                  value={formData.postcode}
                  onChange={(e) => handleChange("postcode", e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="price">Price (£)</Label>
                <Input
                  id="price"
                  type="number"
                  required
                  value={formData.price}
                  onChange={(e) => handleChange("price", e.target.value)}
                />
              </div>
            </div>

            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="property-type">Property Type</Label>
                <Select value={formData.propertyType} onValueChange={(value) => handleChange("propertyType", value)}>
                  <SelectTrigger id="property-type">
                    <SelectValue placeholder="Select property type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="House">House</SelectItem>
                    <SelectItem value="Apartment">Apartment</SelectItem>
                    <SelectItem value="Bungalow">Bungalow</SelectItem>
                    <SelectItem value="Cottage">Cottage</SelectItem>
                    <SelectItem value="Flat">Flat</SelectItem>
                    <SelectItem value="Maisonette">Maisonette</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="bedrooms">Bedrooms</Label>
                <Input
                  id="bedrooms"
                  type="number"
                  required
                  value={formData.bedrooms}
                  onChange={(e) => handleChange("bedrooms", e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="bathrooms">Bathrooms</Label>
                <Input
                  id="bathrooms"
                  type="number"
                  required
                  value={formData.bathrooms}
                  onChange={(e) => handleChange("bathrooms", e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="tenure">Tenure</Label>
                <Select value={formData.tenure} onValueChange={(value) => handleChange("tenure", value)}>
                  <SelectTrigger id="tenure">
                    <SelectValue placeholder="Select tenure" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Freehold">Freehold</SelectItem>
                    <SelectItem value="Leasehold">Leasehold</SelectItem>
                    <SelectItem value="Share of Freehold">Share of Freehold</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>

          <div className="space-y-4 mt-6">
            <div className="space-y-2">
              <Label htmlFor="description">Description</Label>
              <Textarea
                id="description"
                required
                value={formData.description}
                onChange={(e) => handleChange("description", e.target.value)}
                rows={4}
              />
            </div>

            {formData.tenure === "Leasehold" && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="lease-years">Years Remaining on Lease</Label>
                  <Input
                    id="lease-years"
                    type="number"
                    value={formData.leaseYearsRemaining}
                    onChange={(e) => handleChange("leaseYearsRemaining", e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="service-charge">Service Charge (£ per year)</Label>
                  <Input
                    id="service-charge"
                    type="number"
                    value={formData.serviceCharge}
                    onChange={(e) => handleChange("serviceCharge", e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="ground-rent">Ground Rent (£ per year)</Label>
                  <Input
                    id="ground-rent"
                    type="number"
                    value={formData.groundRent}
                    onChange={(e) => handleChange("groundRent", e.target.value)}
                  />
                </div>
              </div>
            )}

            <div className="space-y-2">
              <Label htmlFor="notes">Additional Notes</Label>
              <Textarea
                id="notes"
                value={formData.additionalNotes}
                onChange={(e) => handleChange("additionalNotes", e.target.value)}
                rows={4}
              />
            </div>

            <div className="flex items-center space-x-2">
              <Switch
                id="agent-confirmation"
                checked={formData.agentConfirmationRequired}
                onCheckedChange={(checked) => handleChange("agentConfirmationRequired", checked)}
              />
              <Label htmlFor="agent-confirmation">Agent confirmation required</Label>
            </div>
          </div>
        </CardContent>
      </Card>

      <div className="flex justify-end">
        <Button type="submit">Add Property</Button>
      </div>
    </form>
  )
} 