// Cache for property mappings
let propertyMappingsCache = null;

// Cache for client registry
let clientRegistryCache = null;

const getGoogleConfig = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const apis = user?.orgConfig?.UserAPIs || {};
  return {
    apiKey: apis.google_sheets_key,
    listingsSheetId: apis.listings_sheet_id,
    bookingsSheetId: apis.bookings_sheet_id,
    clientRegistryId: apis.client_registry_id,
    callbackListId: apis.callback_list_id
  };
};

export const fetchPropertyMappings = async () => {
  try {
    if (propertyMappingsCache) {
      return propertyMappingsCache;
    }

    const { apiKey, listingsSheetId } = getGoogleConfig();
    
    const response = await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${listingsSheetId}/values/Sheet1!A2:A?key=${apiKey}`
    );

    if (!response.ok) {
      throw new Error('Failed to fetch property mappings');
    }

    const data = await response.json();
    const rows = data.values || [];
    
    // Create a mapping object
    const mappings = new Map();
    rows.forEach(row => {
      if (row[0]) {
        // Store exact format from sheet, use lowercase only for key
        const propertyName = row[0].trim();
        mappings.set(propertyName.toLowerCase(), propertyName);
      }
    });

    propertyMappingsCache = mappings;
    return mappings;
  } catch (error) {
    return new Map();
  }
};

export const getCanonicalPropertyName = (propertyName) => {
  if (!propertyName || !propertyMappingsCache) return propertyName;
  
  const normalizedInput = propertyName.toLowerCase().trim();
  
  // First try exact match
  const exactMatch = propertyMappingsCache.get(normalizedInput);
  if (exactMatch) return exactMatch;
  
  // If no exact match, try to find a partial match
  for (const [key, value] of propertyMappingsCache.entries()) {
    // Check if the input contains the key or vice versa
    if (normalizedInput.includes(key) || key.includes(normalizedInput)) {
      return value;
    }
  }
  
  // If no match found, return original
  return propertyName;
};

export const getCallerHistory = async () => {
  try {
    const { apiKey, bookingsSheetId } = getGoogleConfig();

    const response = await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${bookingsSheetId}/values/Main!A2:B?key=${apiKey}`
    );

    if (!response.ok) {
      throw new Error('Failed to fetch caller history');
    }

    const data = await response.json();
    const rows = data.values || [];
    
    // Create a Set of all phone numbers from the bookings sheet
    const callerNumbers = new Set();
    rows.forEach(row => {
      if (row[1] && row[1].trim()) {
        const bookingDate = new Date(row[0]);
        // Only add numbers from bookings before cutoff date
        if (!isNaN(bookingDate.getTime())) {
          callerNumbers.add({
            number: row[1].trim(),
            date: bookingDate
          });
        }
      }
    });

    return callerNumbers;
  } catch (error) {
    console.error('Error fetching caller history:', error);
    return new Set();
  }
};

export const fetchClientRegistry = async () => {
  try {
    const { apiKey, clientRegistryId } = getGoogleConfig();
    
    if (!clientRegistryId) {
      console.warn('No client registry ID provided');
      return new Map();
    }
    if (clientRegistryCache) {
      return clientRegistryCache;
    }

    const response = await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${clientRegistryId}/values/Sheet1!A2:A?key=${apiKey}`
    );

    if (!response.ok) {
      throw new Error('Failed to fetch client registry');
    }

    const data = await response.json();
    const rows = data.values || [];
    
    // Create a map of addresses
    const addressMap = new Map();
    
    // Find the address column index from headers
    const addressColumnIndex = rows[0]?.findIndex(header => 
      header.toLowerCase().includes('address') || 
      header.toLowerCase().includes('property')
    );
    
    if (addressColumnIndex === -1) {
      console.error('Could not find address column in client registry');
      return new Map();
    }
    
    // Skip header row
    for (let i = 1; i < rows.length; i++) {
      const row = rows[i];
      if (row[addressColumnIndex]) {
        // Store the exact format from the sheet
        const address = row[addressColumnIndex];
        addressMap.set(address.toLowerCase(), address);  // Use lowercase key for matching but keep original value
      }
    }

    clientRegistryCache = addressMap;
    return addressMap;
  } catch (error) {
    console.error('Error fetching client registry:', error);
    return new Map();
  }
};

export const fetchCallbackList = async () => {
  try {
    const { apiKey, callbackListId } = getGoogleConfig();
    
    // First, fetch the headers to find the column indices
    const headersResponse = await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${callbackListId}/values/Sheet1!A1:Z1?key=${apiKey}`
    );

    if (!headersResponse.ok) {
      throw new Error('Failed to fetch callback list headers');
    }

    const headersData = await headersResponse.json();
    const headers = headersData.values?.[0] || [];
    
    // Find column indices
    const callIdIndex = headers.findIndex(h => h.toLowerCase().includes('call id'));
    const handledIndex = headers.findIndex(h => h.toLowerCase().includes('handled'));
    
    console.log('Callback list headers:', {
      headers,
      callIdIndex,
      handledIndex
    });
    
    if (callIdIndex === -1 || handledIndex === -1) {
      console.error('Required columns not found in callback list. Headers:', headers);
      return new Set();
    }

    // Now fetch the actual data
    const dataResponse = await fetch(
      `https://sheets.googleapis.com/v4/spreadsheets/${callbackListId}/values/Sheet1!A2:Z?key=${apiKey}`
    );

    if (!dataResponse.ok) {
      throw new Error('Failed to fetch callback list data');
    }

    const data = await dataResponse.json();
    const rows = data.values || [];
    
    // Create a set of unhandled call IDs
    const unhandledCallbacks = new Set();
    
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      // Make sure the row has enough columns
      if (row && row.length > Math.max(callIdIndex, handledIndex)) {
        const callId = row[callIdIndex];
        const handled = row[handledIndex];
        
        if (callId && handled?.toLowerCase() === 'no') {
          unhandledCallbacks.add(callId);
          console.log(`Adding unhandled callback: ${callId}`);
        }
      }
    }
    
    console.log(`Found ${unhandledCallbacks.size} unhandled callbacks:`, Array.from(unhandledCallbacks));
    return unhandledCallbacks;
  } catch (error) {
    console.error('Error fetching callback list:', error);
    return new Set();
  }
};