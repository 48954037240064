import React from 'react';
import { X, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/button';
import { fetchCallAnalytics, getAssistantId, getAssistantIds } from '../../services/vapiService';
import { fetchWhatsAppConversations } from '../../services/whatsappService';
import { LoadingSpinner } from '../ui/loading';
import { getCanonicalPropertyName } from '../../services/propertyService';
import { getReasonColor, tagColors, getReasonDisplayText } from '../../lib/tagColors';
import { getTypeIcon, getStatusIcon, getTypeColor, getStatusColor } from '../../lib/statusIcons';

// Helper to manage read notifications in localStorage
const getReadNotificationsKey = () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    // Log the user object to debug
    console.log('User object for notifications key:', {
      user,
      userId: user?.id,
      hasUser: !!user,
      userString: localStorage.getItem('user')
    });
    
    // Use a consistent key that doesn't depend on user ID if possible
    // This ensures notifications stay read even if user ID changes
    const key = `read_notifications_${user?.id || 'default'}`;
    console.log(`Generated notifications key: ${key}`);
    return key;
  } catch (error) {
    console.error('Error generating notifications key:', error);
    return 'read_notifications_default';
  }
};

const getReadNotifications = () => {
  try {
    const key = getReadNotificationsKey();
    const stored = localStorage.getItem(key);
    
    // Try to parse the stored value
    let parsed = [];
    if (stored) {
      try {
        parsed = JSON.parse(stored);
      } catch (parseError) {
        console.error(`Error parsing stored notifications with key ${key}:`, parseError);
      }
    }
    
    // If we couldn't get any notifications from the user-specific key,
    // try the fixed key as a fallback
    if (!parsed || parsed.length === 0) {
      const fixedStored = localStorage.getItem('read_notifications_fixed');
      if (fixedStored) {
        try {
          const fixedParsed = JSON.parse(fixedStored);
          if (fixedParsed && fixedParsed.length > 0) {
            console.log(`Using fixed key fallback, found ${fixedParsed.length} read notifications`);
            parsed = fixedParsed;
          }
        } catch (fixedParseError) {
          console.error('Error parsing fixed notifications:', fixedParseError);
        }
      }
    }
    
    console.log(`Retrieved ${parsed.length} read notifications from localStorage with key ${key}`);
    return parsed;
  } catch (error) {
    console.error('Error retrieving read notifications:', error);
    return [];
  }
};

const markAsRead = (id) => {
  try {
    if (!id) {
      console.error('Attempted to mark undefined ID as read');
      return;
    }
    
    const key = getReadNotificationsKey();
    const read = getReadNotifications();
    
    // Check if the ID is already in the read list to avoid duplicates
    if (!read.includes(id)) {
      console.log(`Marking notification ${id} as read and storing in localStorage with key ${key}`);
      const updatedRead = [...read, id];
      
      // Store in localStorage
      localStorage.setItem(key, JSON.stringify(updatedRead));
      
      // Verify the storage was successful
      const verifyStorage = localStorage.getItem(key);
      console.log('Verification of localStorage update:', {
        key,
        updatedValue: updatedRead,
        storedValue: verifyStorage,
        parsed: verifyStorage ? JSON.parse(verifyStorage) : [],
        success: verifyStorage && JSON.parse(verifyStorage).includes(id)
      });
      
      // As a fallback, also store with a fixed key
      localStorage.setItem('read_notifications_fixed', JSON.stringify(updatedRead));
    } else {
      console.log(`Notification ${id} is already marked as read`);
    }
  } catch (error) {
    console.error('Error marking notification as read:', error);
  }
};

const NotificationItem = ({ notification, onDismiss, setNotifications, updateReadList }) => {
  const navigate = useNavigate();
  
  // Get the inbound assistant IDs from the getAssistantIds function
  const inboundAssistantIds = getAssistantIds('inbound');
  
  // Check if it's a WhatsApp conversation
  const isWhatsApp = notification.channel === 'whatsapp' || notification.platform === 'whatsapp';
  
  // Check if it's a squad call or if the assistantId/matchedAssistantId is in our inbound IDs
  const isInbound = !isWhatsApp && (notification.isSquadCall || 
                    inboundAssistantIds.includes(notification.assistantId) || 
                    (notification.matchedAssistantId && inboundAssistantIds.includes(notification.matchedAssistantId)));
  
  // Debug logging for notification type determination
  console.log('Notification type determination:', {
    id: notification.id,
    isInbound,
    isSquadCall: notification.isSquadCall,
    assistantId: notification.assistantId,
    matchedAssistantId: notification.matchedAssistantId,
    inboundAssistantIds,
    assistantIdMatch: inboundAssistantIds.includes(notification.assistantId),
    matchedIdMatch: notification.matchedAssistantId && inboundAssistantIds.includes(notification.matchedAssistantId)
  });
  
  // Get the appropriate summary text
  const summaryText = notification.analysis?.structuredData?.notification_summary || notification.summary;
  
  const handleClick = (e) => {
    e.preventDefault();
    console.log('Navigating to conversation:', {
      id: notification.id,
      isWhatsApp,
      channel: notification.channel,
      platform: notification.platform,
      assistantType: isWhatsApp ? 'text' : (isInbound ? 'inbound' : 'outbound')
    });

    // Always mark as read when clicked
    markAsRead(notification.id);
    // Update the read list in the parent component
    if (updateReadList) {
      updateReadList(notification.id);
    }
    // Only auto-dismiss if no callback required
    if (!notification.requiresCallback) {
      onDismiss(notification.id);
    }

    // Navigate to conversations with the correct state
    navigate('/conversations', { 
      state: { 
        selectedCall: {
          ...notification,
          // Ensure WhatsApp identifiers are preserved
          channel: isWhatsApp ? 'whatsapp' : undefined,
          platform: isWhatsApp ? 'whatsapp' : undefined
        },
        assistantType: isWhatsApp ? 'text' : (isInbound ? 'inbound' : 'outbound')
      },
      replace: true // Use replace to ensure proper history handling
    });
  };

  return (
    <div 
      className="bg-white rounded-lg shadow mb-3 overflow-hidden group cursor-pointer hover:bg-gray-50"
      onClick={handleClick}
    >
      <div className="p-4 flex justify-between items-start">
        <div>
          <div className="flex items-center gap-2 flex-wrap">
            <div className="font-medium text-gray-900">
              {new Date(notification.startedAt).toLocaleString()}
            </div>
            {/* Channel indicator */}
            <span className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 ${
              isWhatsApp 
                ? 'bg-green-100 text-green-700'
                : isInbound 
                  ? 'bg-blue-100 text-blue-700' 
                  : 'bg-cyan-100 text-cyan-700'
            }`}>
              {isWhatsApp ? 'WhatsApp' : (isInbound ? 'Inbound' : 'Outbound')}
            </span>
            {/* Property tag */}
            {notification.analysis?.structuredData?.property_name && (
              <span className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 ${tagColors.property_name}`}>
                {getTypeIcon('property')}
                {getCanonicalPropertyName(notification.analysis.structuredData.property_name)}
              </span>
            )}
            {/* Purpose/Reason tag */}
            {notification.analysis?.structuredData?.reason_of_call && (
              <span className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 ${getReasonColor(notification.analysis.structuredData.reason_of_call)}`}>
                {getTypeIcon(notification.analysis.structuredData.reason_of_call)}
                {getReasonDisplayText(notification.analysis.structuredData.reason_of_call)}
              </span>
            )}
            {/* Status tags */}
            {notification.endedReason === "assistant-forwarded-call" && !isWhatsApp && (
              <span className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 ${tagColors.forwarded}`}>
                {getStatusIcon('pending')}
                Call forwarded
              </span>
            )}
            {notification.requiresCallback && (
              <span className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 ${tagColors.callback}`}>
                {getStatusIcon('required')}
                Callback required
              </span>
            )}
            {/* Outcome tag for outbound calls */}
            {!isWhatsApp && !isInbound && notification.analysis?.structuredData?.call_outcome && (
              <span className={`text-xs px-2 py-1 rounded-full flex items-center gap-1 ${
                tagColors.call_outcome[notification.analysis.structuredData.call_outcome] || 
                tagColors.call_outcome.default
              }`}>
                {getStatusIcon(notification.analysis.structuredData.call_outcome === 'Confirmed' ? 'confirmed' : 
                              notification.analysis.structuredData.call_outcome === 'Cancelled' ? 'required' : 'pending')}
                {notification.analysis.structuredData.call_outcome}
              </span>
            )}
          </div>
          <div className="text-sm text-gray-500 mt-1">{summaryText}</div>
          <div className="text-xs text-gray-400 mt-2">
            {isWhatsApp ? notification.phoneNumber : (notification.customer?.number || 'Unknown caller')}
          </div>
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            // Mark as read when dismissed with X button
            markAsRead(notification.id);
            // Update the read list in the parent component
            if (updateReadList) {
              updateReadList(notification.id);
            }
            setNotifications(prev => prev.filter(n => n.id !== notification.id));
          }}
          className="text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity"
        >
          <X size={18} />
        </button>
      </div>
    </div>
  );
};

const NotificationsScreen = () => {
  const [notifications, setNotifications] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pollingInterval, setPollingInterval] = React.useState(null);
  const [readNotificationsList, setReadNotificationsList] = React.useState([]);

  // Load read notifications when component mounts
  React.useEffect(() => {
    const readList = getReadNotifications();
    console.log('Initial load of read notifications:', readList);
    setReadNotificationsList(readList);
  }, []);

  // Helper function to update the read list in state
  const updateReadList = (id) => {
    setReadNotificationsList(prev => {
      if (!prev.includes(id)) {
        console.log(`Adding ${id} to read notifications state`);
        return [...prev, id];
      }
      return prev;
    });
  };

  const loadRecentCalls = async () => {
    try {
      const [inboundData, outboundData, whatsappData] = await Promise.all([
        fetchCallAnalytics('inbound'),
        fetchCallAnalytics('outbound'),
        fetchWhatsAppConversations(100, 0)
      ]);
      
      // Debug log to see what we're getting from each assistant
      console.log('Notifications data:', {
        inboundCalls: inboundData.calls.map(call => ({
          id: call.id,
          assistantId: call.assistantId,
          matchedAssistantId: call.matchedAssistantId,
          isSquadCall: call.isSquadCall,
          time: call.startedAt
        })),
        outboundCalls: outboundData.calls.map(call => ({
          id: call.id,
          assistantId: call.assistantId,
          matchedAssistantId: call.matchedAssistantId,
          isSquadCall: call.isSquadCall,
          time: call.startedAt
        })),
        whatsappCalls: whatsappData.calls.map(call => ({
          id: call.id,
          channel: 'whatsapp',
          time: call.startedAt
        }))
      });
      
      // Get read notifications
      const readNotifications = getReadNotifications();
      
      // Debug log for read notifications
      console.log('Read notifications:', {
        readNotifications,
        readNotificationsKey: getReadNotificationsKey(),
        storedValue: localStorage.getItem(getReadNotificationsKey()),
        fixedStoredValue: localStorage.getItem('read_notifications_fixed'),
        readCount: readNotifications.length
      });
      
      // Log all localStorage keys and values for debugging
      console.log('All localStorage items:');
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        const value = localStorage.getItem(key);
        console.log(`${key}: ${value.substring(0, 50)}${value.length > 50 ? '...' : ''}`);
      }
      
      // Add channel/platform identifier to WhatsApp calls
      const whatsappCalls = whatsappData.calls.map(call => ({
        ...call,
        channel: 'whatsapp',
        platform: 'whatsapp'
      }));
      
      const allCallsWithMessages = [...inboundData.calls, ...outboundData.calls, ...whatsappCalls]
        .filter(call => call.messages && call.messages.length > 0);
        
      const allCallsWithSummaries = allCallsWithMessages
        .filter(call => call.summary && call.summary.trim() !== '');
        
      console.log('Filtering notifications:', {
        totalCalls: [...inboundData.calls, ...outboundData.calls, ...whatsappCalls].length,
        callsWithMessages: allCallsWithMessages.length,
        callsWithSummaries: allCallsWithSummaries.length,
        callsWithoutSummaries: allCallsWithMessages.length - allCallsWithSummaries.length
      });
      
      const allCalls = allCallsWithSummaries
        .filter(call => {
          // Get read notifications from both localStorage and state
          const readFromStorage = readNotifications;
          const combinedReadList = [...new Set([...readFromStorage, ...readNotificationsList])];
          
          // Check if this call ID is in the combined read notifications list
          const isRead = combinedReadList.includes(call.id);
          
          // Debug log for each call's read status
          console.log(`Call ${call.id} read status:`, {
            isRead,
            inLocalStorage: readFromStorage.includes(call.id),
            inState: readNotificationsList.includes(call.id),
            requiresCallback: call.requiresCallback,
            willBeShown: !isRead || call.requiresCallback,
            channel: call.channel || 'call'
          });
          
          // Keep if unread OR requires callback
          return !isRead || call.requiresCallback;
        });

      // Sort all calls by date, most recent first
      allCalls.sort((a, b) => new Date(b.startedAt) - new Date(a.startedAt));

      // Get the last 10 calls
      const recentCalls = allCalls.slice(0, 10);

      setNotifications(recentCalls);
      setLoading(false);
    } catch (error) {
      console.error('Error loading notifications:', error);
      setLoading(false);
    }
  };

  // Setup polling when component mounts
  React.useEffect(() => {
    // Initial load
    loadRecentCalls();

    // Setup polling every 30 seconds
    const interval = setInterval(loadRecentCalls, 30000);
    setPollingInterval(interval);

    // Cleanup on unmount
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  // Cleanup old notifications on unmount
  React.useEffect(() => {
    return () => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user?.id) {
        const key = getReadNotificationsKey();
        const read = getReadNotifications();
        // Keep only recent notifications (last 30 days)
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        const recentNotifications = read.filter(id => {
          const [timestamp] = id.split('_');
          return new Date(parseInt(timestamp)) > thirtyDaysAgo;
        });
        localStorage.setItem(key, JSON.stringify(recentNotifications));
      }
    };
  }, []);

  const handleDismiss = (id) => {
    // Mark the notification as read when dismissed
    markAsRead(id);
    // Update the read list in state
    setReadNotificationsList(prev => {
      if (!prev.includes(id)) {
        return [...prev, id];
      }
      return prev;
    });
    // Remove from UI unless it requires a callback
    setNotifications(prev => prev.filter(n => n.id !== id || n.requiresCallback));
  };

  const handleClearAll = () => {
    // Mark all notifications as read
    notifications.forEach(notification => {
      markAsRead(notification.id);
      // Update the read list in state
      setReadNotificationsList(prev => {
        if (!prev.includes(notification.id)) {
          return [...prev, notification.id];
        }
        return prev;
      });
    });
    // Clear the UI
    setNotifications([]);
  };

  return (
    <div className="h-full">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="container mx-auto px-4 py-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Notifications</h2>
            {notifications.length > 0 && (
              <Button
                variant="outline"
                size="sm"
                onClick={handleClearAll}
                className="flex items-center gap-2"
              >
                <Trash2 className="h-4 w-4" />
                Clear All
              </Button>
            )}
          </div>

          {notifications.length === 0 ? (
            <div className="text-center py-12 text-gray-500">
              No unread notifications
            </div>
          ) : (
            <div className="space-y-4">
              {notifications.map(notification => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  onDismiss={handleDismiss}
                  setNotifications={setNotifications}
                  updateReadList={updateReadList}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationsScreen; 