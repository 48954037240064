// API is hosted on a separate EC2 instance
const API_URL = process.env.REACT_APP_WHATSAPP_API_URL || 'https://api.doora.co.uk';

// Helper function to get the base URL based on environment
const getBaseUrl = () => {
  return API_URL.replace(/\/$/, '');
};

// Helper function to transform WhatsApp data into the expected format
const transformWhatsAppData = (rawData) => {
  // Extract conversations from the response
  const conversations = rawData.extracted_conversations || [];
  
  console.log('Transforming WhatsApp data:', {
    rawConversations: conversations.length,
    firstConv: conversations[0]
  });

  const transformed = conversations.map(conversation => ({
    id: conversation.conversation_id,
    startedAt: conversation.start_message_timestamp,
    endedAt: conversation.end_message_timestamp,
    duration: conversation.conversation_duration,
    channel: 'whatsapp',
    platform: 'whatsapp',
    threadId: conversation.thread_id,
    clientId: conversation.client_id,
    senderId: conversation.sender_id,
    phoneNumber: conversation.sender_id.split(':')[1], // Extract phone number from sender_id
    messages: conversation.message_logs.map(msg => ({
      role: msg.role,
      content: msg.content,
      message: msg.content, // Add this for compatibility with ChatInterface
      timestamp: msg.timestamp,
      messageId: msg.message_id,
      toolUsage: msg.tool_usage,
      tokenUsage: msg.token_usage,
      mediaType: msg.media_type,
      mediaUrl: msg.media_url,
      mediaFileName: msg.media_filename,
      status: msg.status
    })),
    messageCount: conversation.message_logs.length,
    summary: conversation.summary,
    purpose: conversation.purpose,
    toolCallCount: conversation.tool_call_count,
    toolUsage: conversation.tool_usage,
    analysis: {
      structuredData: {
        reason_of_call: conversation.purpose,
        property_name: extractPropertyFromToolCalls(conversation.tool_usage),
        call_outcome: determineCallOutcome(conversation.tool_usage)
      }
    },
    conversationDuration: conversation.conversation_duration,
    tokenUsage: conversation.conversation_token_usage,
    extractionDatetime: conversation.extraction_datetime
  }));

  console.log('Transformed conversations:', {
    count: transformed.length,
    conversations: transformed.map(c => ({
      id: c.id,
      startedAt: c.startedAt,
      summary: c.summary,
      phoneNumber: c.phoneNumber
    }))
  });

  return transformed;
};

// Helper function to extract property name from tool calls
const extractPropertyFromToolCalls = (toolUsage) => {
  if (!Array.isArray(toolUsage)) return null;
  
  for (const toolCallGroup of toolUsage) {
    for (const call of toolCallGroup) {
      if (call.name === 'check_property_listings' || call.name === 'book_viewing') {
        return call.args.property_address;
      }
    }
  }
  return null;
};

// Helper function to determine call outcome from tool calls
const determineCallOutcome = (toolUsage) => {
  if (!Array.isArray(toolUsage)) return null;
  
  for (const toolCallGroup of toolUsage) {
    for (const call of toolCallGroup) {
      if (call.name === 'book_viewing') {
        return 'Viewing Booked';
      }
    }
  }
  return null;
};

// Get the current user's company ID from localStorage
const getCurrentCompanyId = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user?.companyId;
};

// Helper function to try both HTTP and HTTPS
const fetchWithProtocolFallback = async (url, options = {}) => {
  try {
    console.log('Fetching from:', url);
    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors'
    });
    
    if (response.ok) {
      console.log('Fetch successful');
      return response;
    }
    
    const responseText = await response.text();
    console.warn('Fetch failed:', {
      url,
      status: response.status,
      statusText: response.statusText,
      responseText
    });
    throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};

// Fetch WhatsApp conversations with optional pagination
const fetchWhatsAppConversations = async (limit = 100, offset = 0, lastExtractionDatetime = null) => {
  try {
    const companyId = getCurrentCompanyId();
    if (!companyId) {
      throw new Error('No company ID found. Please log in again.');
    }

    // Build query parameters
    const queryParams = new URLSearchParams({
      client_id: companyId.toString()
    });

    if (lastExtractionDatetime) {
      queryParams.append('last_extraction_datetime', lastExtractionDatetime);
    }

    let response;
    let rawData;
    let fetchError;

    // First attempt with company ID
    try {
      response = await fetchWithProtocolFallback(
        `${getBaseUrl()}/conversations?${queryParams.toString()}`
      );

      if (response.ok) {
        rawData = await response.json();
      }
    } catch (error) {
      console.error('Error in initial fetch:', error);
      fetchError = error;
    }

    // Try fallback if:
    // 1. Company ID is 1 AND
    // 2. Either there was a fetch error, or response wasn't OK, or no conversations were found.
    if (companyId === 1 && (fetchError || !response?.ok || !rawData?.extracted_conversations?.length)) {
      console.log('No data found for company ID 1, trying demo_estate_agents fallback');
      
      const fallbackParams = new URLSearchParams({
        client_id: 'demo_estate_agents'
      });

      if (lastExtractionDatetime) {
        fallbackParams.append('last_extraction_datetime', lastExtractionDatetime);
      }

      try {
        response = await fetchWithProtocolFallback(
          `${getBaseUrl()}/conversations?${fallbackParams.toString()}`
        );

        if (response.ok) {
          rawData = await response.json();
        }
      } catch (fallbackError) {
        console.error('Error in fallback fetch:', fallbackError);
        throw fetchError || fallbackError;
      }
    }

    // Handle final error cases
    if (!response?.ok) {
      throw new Error(`Failed to fetch WhatsApp conversations: ${response?.status} ${response?.statusText}`);
    }

    // Validate the response structure
    if (!rawData || typeof rawData !== 'object') {
      console.error('Invalid response data:', rawData);
      throw new Error('Invalid response format from WhatsApp API');
    }

    const transformedData = transformWhatsAppData(rawData);

    return {
      calls: transformedData,
      processed: processAnalyticsData(transformedData)
    };
  } catch (error) {
    console.error('Error fetching WhatsApp conversations:', error);
    throw error; // Preserve the original error
  }
};

// Process analytics data for WhatsApp conversations
const processAnalyticsData = (conversations) => {
  const totalMessages = conversations.reduce((total, conv) => total + conv.messageCount, 0);
  const totalToolCalls = conversations.reduce((total, conv) => total + (conv.toolCallCount || 0), 0);
  const totalCost = conversations.reduce((total, conv) => total + (conv.tokenUsage?.total_cost || 0), 0);

  // Group conversations by date
  const conversationsByDate = conversations.reduce((acc, conv) => {
    const date = new Date(conv.startedAt).toISOString().split('T')[0];
    if (!acc[date]) {
      acc[date] = {
        count: 0,
        messages: 0,
        toolCalls: 0,
        cost: 0
      };
    }
    acc[date].count++;
    acc[date].messages += conv.messageCount;
    acc[date].toolCalls += conv.toolCallCount || 0;
    acc[date].cost += conv.tokenUsage?.total_cost || 0;
    return acc;
  }, {});

  // Aggregate tool usage across all conversations
  const toolUsage = conversations.reduce((acc, conv) => {
    if (conv.toolUsage) {
      conv.toolUsage.forEach(toolCallGroup => {
        toolCallGroup.forEach(call => {
          const toolName = call.name;
          acc[toolName] = (acc[toolName] || 0) + 1;
        });
      });
    }
    return acc;
  }, {});

  // Count conversation purposes
  const purposes = conversations.reduce((acc, conv) => {
    const purpose = conv.purpose;
    if (purpose) {
      acc[purpose] = (acc[purpose] || 0) + 1;
    }
    return acc;
  }, {});

  // Count conversation outcomes
  const outcomes = conversations.reduce((acc, conv) => {
    const outcome = conv.analysis?.structuredData?.call_outcome;
    if (outcome) {
      acc[outcome] = (acc[outcome] || 0) + 1;
    }
    return acc;
  }, {});

  // Calculate average durations
  const averageDuration = conversations.reduce((total, conv) => {
    const duration = typeof conv.duration === 'string' 
      ? parseDurationString(conv.duration)
      : conv.duration;
    return total + (duration || 0);
  }, 0) / conversations.length;

  return {
    totalConversations: conversations.length,
    totalMessages,
    totalToolCalls,
    totalCost: parseFloat(totalCost.toFixed(2)),
    averageCostPerConversation: parseFloat((totalCost / conversations.length).toFixed(2)),
    averageMessagesPerConversation: parseFloat((totalMessages / conversations.length).toFixed(2)),
    averageToolCallsPerConversation: parseFloat((totalToolCalls / conversations.length).toFixed(2)),
    averageDuration: formatDuration(averageDuration),
    conversationsOverTime: {
      labels: Object.keys(conversationsByDate),
      data: Object.values(conversationsByDate).map(day => day.count)
    },
    messagesOverTime: {
      labels: Object.keys(conversationsByDate),
      data: Object.values(conversationsByDate).map(day => day.messages)
    },
    toolCallsOverTime: {
      labels: Object.keys(conversationsByDate),
      data: Object.values(conversationsByDate).map(day => day.toolCalls)
    },
    costsOverTime: {
      labels: Object.keys(conversationsByDate),
      data: Object.values(conversationsByDate).map(day => parseFloat(day.cost.toFixed(2)))
    },
    toolUsage,
    purposes,
    outcomes
  };
};

// Helper function to parse duration string like "3 days, 2:42:51.216023"
const parseDurationString = (durationStr) => {
  const parts = durationStr.split(', ');
  let totalSeconds = 0;
  
  parts.forEach(part => {
    if (part.includes('days')) {
      totalSeconds += parseInt(part) * 24 * 60 * 60;
    } else {
      const [hours, minutes, seconds] = part.split(':');
      totalSeconds += parseInt(hours) * 60 * 60;
      totalSeconds += parseInt(minutes) * 60;
      totalSeconds += parseFloat(seconds);
    }
  });
  
  return totalSeconds;
};

// Helper function to format duration in seconds to human readable string
const formatDuration = (seconds) => {
  const days = Math.floor(seconds / (24 * 60 * 60));
  seconds %= (24 * 60 * 60);
  const hours = Math.floor(seconds / (60 * 60));
  seconds %= (60 * 60);
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;
  
  const parts = [];
  if (days > 0) parts.push(`${days} days`);
  if (hours > 0) parts.push(`${hours} hours`);
  if (minutes > 0) parts.push(`${minutes} minutes`);
  if (seconds > 0) parts.push(`${seconds.toFixed(1)} seconds`);
  
  return parts.join(', ');
};

export {
  fetchWhatsAppConversations,
  processAnalyticsData
}; 