import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Trash2 } from 'lucide-react';

const EditMemberDialog = ({ member, onUpdate, onDelete }) => {
  const [role, setRole] = useState(member.role);
  const [open, setOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdateRole(role);
  };

  const handleRemoveMember = async () => {
    try {
      setIsRemoving(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/organization/members/${member.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'x-api-key': process.env.REACT_APP_API_KEY
          },
          body: JSON.stringify({ userId: member.id, action: 'remove' })
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to remove member');
      }

      // Fetch updated members list
      const membersResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/organization/members`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'x-api-key': process.env.REACT_APP_API_KEY
          }
        }
      );
      
      if (!membersResponse.ok) {
        throw new Error('Failed to refresh members list');
      }
      
      const { members } = await membersResponse.json();
      onUpdate(members);
      setOpen(false);
    } catch (error) {
      console.error('Error removing member:', error);
      // TODO: Add toast notification for error
    } finally {
      setIsRemoving(false);
    }
  };

  const handleUpdateRole = async (newRole) => {
    try {
      setIsUpdating(true);
      console.log('Updating role:', { userId: member.id, role: newRole });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/organization/members/${member.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'x-api-key': process.env.REACT_APP_API_KEY
          },
          body: JSON.stringify({
            userId: member.id,
            role: newRole
          })
        }
      );

      const responseData = await response.json();
      console.log('Update response:', responseData);

      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to update member role');
      }

      // Fetch updated members list
      const membersResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/organization/members`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'x-api-key': process.env.REACT_APP_API_KEY
          }
        }
      );
      
      if (!membersResponse.ok) {
        throw new Error('Failed to refresh members list');
      }
      
      const { members } = await membersResponse.json();
      onUpdate(members);
      setOpen(false);
    } catch (error) {
      console.error('Error updating member role:', error);
      // Add toast notification for error
      alert(error.message); // Temporary error display
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm">
          Edit
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Team Member</DialogTitle>
        </DialogHeader>
        <div className="py-2">
          <div className="flex items-center gap-4 mb-6">
            <div className="h-10 w-10 bg-gray-200 rounded-full flex items-center justify-center text-sm">
              {member.email.split('@')[0].substring(0, 2).toUpperCase()}
            </div>
            <div>
              <p className="text-gray-900">{member.email}</p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="space-y-2">
              <Label htmlFor="role">Role</Label>
              <Select value={role} onValueChange={setRole} required>
                <SelectTrigger id="role" autoFocus={false}>
                  <SelectValue placeholder="Select a role" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Admin">Admin</SelectItem>
                  <SelectItem value="Member">Member</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center gap-3 mt-8">
              <button 
                type="button"
                onClick={handleRemoveMember}
                className="flex items-center text-red-600 hover:text-red-700 text-sm font-medium"
                disabled={isRemoving}
              >
                <Trash2 className="h-4 w-4 mr-1" />
                {isRemoving ? 'Removing...' : 'Remove Member'}
              </button>
              <div className="flex gap-3 ml-auto">
                <Button variant="outline" type="button" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button 
                  type="submit" 
                  className="bg-blue-600 hover:bg-blue-700"
                  disabled={isUpdating}
                >
                  {isUpdating ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Updating...
                    </>
                  ) : (
                    'Save Changes'
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditMemberDialog; 