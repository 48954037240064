const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Handle the response from Google Sign-In
const handleGoogleResponse = async (response) => {
  if (response.error) {
    throw new Error(response.error);
  }
  return response;
};

// Initialize Google OAuth
const initGoogleAuth = () => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.onload = () => {
      // Wait for google to be defined
      const checkGoogle = () => {
        if (window.google) {
          window.google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback: handleGoogleResponse
          });
          resolve();
        } else {
          setTimeout(checkGoogle, 100);
        }
      };
      checkGoogle();
    };
    document.body.appendChild(script);
  });
};

const API_URL = process.env.REACT_APP_API_URL || 'https://9rzs2dyfa4.execute-api.eu-west-2.amazonaws.com/prod';
const API_KEY = process.env.REACT_APP_API_KEY || 'XrVUluK2Zh62iw4WUD1OK6KJQZJYpRPh1xbgAgSk';

// Helper to simulate async API calls
const simulateApiCall = (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, 500); // Add a small delay to simulate network request
  });
};

const updateUser = async (userId, updates) => {
  const response = await fetch(`${API_URL}/auth/user/${userId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(updates)
  });

  if (!response.ok) {
    throw new Error('Failed to update user');
  }

  const data = await response.json();
  localStorage.setItem('user', JSON.stringify(data.user));
  return data;
};

export const authService = {
  async signIn(username, password) {
    console.log('Attempting login with:', {
      url: `${API_URL}/auth/login`,
      apiKey: API_KEY?.slice(0,5) + '...',  // Log first 5 chars only
      email: username
    });
    
    const response = await fetch(`${API_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY
      },
      body: JSON.stringify({ email: username, password }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to sign in');
    }

    const data = await response.json();
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
    return data;
  },

  async signUp(username, password) {
    const response = await fetch(`${API_URL}/auth/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY
      },
      body: JSON.stringify({ 
        email: username, 
        password,
        role: 'Member'
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to sign up');
    }

    const data = await response.json();
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
    return data;
  },

  async signInWithGoogle() {
    try {
      await initGoogleAuth();
      console.log('Google Auth initialized');
      const response = await new Promise((resolve) => {
        window.google.accounts.oauth2.initTokenClient({
          client_id: GOOGLE_CLIENT_ID,
          scope: 'email profile https://www.googleapis.com/auth/userinfo.profile',
          callback: (tokenResponse) => {
            console.log('Got token response:', tokenResponse ? 'success' : 'failed');
            resolve(tokenResponse);
          },
        }).requestAccessToken();
      });
      
      console.log('Getting user info from Google...');
      // Get user info from Google
      const userInfo = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${response.access_token}` }
      }).then(res => res.json());
      console.log('Got user info:', { email: userInfo.email, hasGoogleId: !!userInfo.sub });

      // Try to login first
      console.log('Attempting login with Google credentials...');
      const loginResponse = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY
        },
        body: JSON.stringify({
          email: userInfo.email,
          googleId: userInfo.sub  // Google's user ID
        })
      });

      console.log('Login response status:', loginResponse.status);
      const responseData = await loginResponse.json();
      console.log('Login response data:', responseData);

      // Add photoURL to user data before storing
      const userData = {
        ...responseData.user,
        photoURL: userInfo.picture
      };

      // Store auth data in localStorage
      localStorage.setItem('token', responseData.token);
      localStorage.setItem('user', JSON.stringify(userData));

      if (loginResponse.status === 401) {
        console.log('User not found, attempting signup...');
        const signupResponse = await fetch(`${API_URL}/auth/signup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
          },
          body: JSON.stringify({
            email: userInfo.email,
            googleId: userInfo.sub
          })
        });
        
        if (!signupResponse.ok) {
          const errorData = await signupResponse.json();
          console.error('Signup failed:', errorData);
          throw new Error(errorData.message || 'Failed to create account');
        }
        
        const signupData = await signupResponse.json();
        console.log('Signup response data:', signupData);
        
        // Add photoURL to signup data before storing
        const signupUserData = {
          ...signupData.user,
          photoURL: userInfo.picture
        };
        
        // Store auth data in localStorage for signup too
        localStorage.setItem('token', signupData.token);
        localStorage.setItem('user', JSON.stringify(signupUserData));
        
        return {
          ...signupData,
          user: signupUserData
        };
      }

      return {
        ...responseData,
        user: userData
      };
    } catch (error) {
      console.error('Google sign in error:', error);
      throw error;
    }
  },

  async signOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  },

  async getCurrentUser() {
    const user = localStorage.getItem('user');
    console.log('Raw user data from localStorage:', user);
    if (!user) return null;
    
    const parsedUser = JSON.parse(user);
    console.log('Parsed user data:', parsedUser);
    return parsedUser;
  },

  updateUser
};

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'));
}; 