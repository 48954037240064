import { useAuth } from '../contexts/AuthContext';

export const usePermissions = () => {
  const { user } = useAuth();

  const isAdmin = user?.role === 'Admin';

  const can = {
    manageUsers: isAdmin
  };

  return { isAdmin, can };
}; 