import React from 'react';
import { getCanonicalPropertyName } from '../../services/propertyService';
import { getReasonColor, tagColors } from '../../lib/tagColors';
import { Phone, Home, MapPin, Bookmark, Clock, Volume2, Play, Pause } from 'lucide-react';

const CallDetails = ({ call }) => {
  const isWhatsApp = call.channel === 'whatsapp' || call.platform === 'whatsapp';
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [duration, setDuration] = React.useState(0);
  const [currentTime, setCurrentTime] = React.useState(0);
  const audioRef = React.useRef(null);

  // Format phone number helper
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    // Remove any non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, '');
    // Format as +XX XXXX XXXXXX
    return `+${cleaned.slice(0, 2)} ${cleaned.slice(2, 6)} ${cleaned.slice(6)}`;
  };

  // Get phone number based on conversation type
  const getPhoneNumber = () => {
    if (isWhatsApp) {
      return call.phoneNumber;
    }
    return call.customer?.number;
  };

  // Format time for audio player (MM:SS)
  const formatTime = (time) => {
    if (isNaN(time)) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  // Handle audio player events
  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleSeek = (e) => {
    if (audioRef.current) {
      const seekTime = parseFloat(e.target.value);
      audioRef.current.currentTime = seekTime;
      setCurrentTime(seekTime);
    }
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
    setCurrentTime(0);
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }
  };

  return (
    <div className="h-screen overflow-y-auto overflow-x-hidden flex-shrink-0 flex flex-col relative">
      <div className="p-6 space-y-5">
        {/* Summary - At the top */}
        <div>
          <h2 className="text-xl font-semibold mb-3 text-gray-800">Summary</h2>
          <div className="bg-gradient-to-r from-blue-50 to-blue-50/50 p-5 rounded-lg shadow-sm border border-blue-100">
            {call.summary && call.summary.trim() !== '' ? (
              <p className="text-gray-700 text-base leading-relaxed">{call.summary}</p>
            ) : (
              <p className="text-gray-500 text-base italic">No summary available</p>
            )}
          </div>
        </div>

        {/* Compact Contact Information */}
        <div className="border-t pt-4">
          <h3 className="text-md font-semibold mb-2 text-gray-800">Contact Information</h3>
          <div className="flex items-center">
            <Phone className="h-4 w-4 text-blue-500 mr-2 flex-shrink-0" />
            <span className="text-gray-700 font-medium text-sm">
              {formatPhoneNumber(getPhoneNumber())}
            </span>
          </div>
          
          {isWhatsApp && call.contact?.name && (
            <div className="flex items-center mt-1.5">
              <Bookmark className="h-4 w-4 text-blue-500 mr-2 flex-shrink-0" />
              <span className="text-gray-700 text-sm">{call.contact.name}</span>
            </div>
          )}
        </div>

        {/* Compact Property Information */}
        {call.analysis?.structuredData?.property_name && (
          <div className="border-t pt-4">
            <h3 className="text-md font-semibold mb-2 text-gray-800">Property</h3>
            <div className="flex items-start">
              <Home className="h-4 w-4 text-blue-500 mr-2 mt-0.5 flex-shrink-0" />
              <div className="text-gray-900 font-medium text-sm">
                {getCanonicalPropertyName(call.analysis.structuredData.property_name)}
              </div>
            </div>
          </div>
        )}

        {/* Conversation Details */}
        <div className="border-t pt-4">
          <h3 className="text-md font-semibold mb-2 text-gray-800">Conversation Details</h3>
          
          {/* Status Tags - Fixed to prevent overflow */}
          <div className="space-y-2">
            {/* Purpose */}
            {call.analysis?.structuredData?.reason_of_call && (
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium text-gray-600">Purpose:</span>
                <div className="text-xs px-2.5 py-1 bg-indigo-100 text-indigo-700 rounded-full font-medium border border-indigo-200">
                  {call.analysis.structuredData.reason_of_call
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ')
                    .replace(/\?/g, '')}
                </div>
              </div>
            )}
            
            {/* Outcome */}
            {call.analysis?.structuredData?.call_outcome && (
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium text-gray-600">Outcome:</span>
                <div className={`text-xs px-2.5 py-1 rounded-full font-medium ${
                  call.analysis.structuredData.call_outcome === 'Viewing Booked' 
                    ? 'bg-emerald-100 text-emerald-700 border border-emerald-200' 
                    : 'bg-gray-100 text-gray-700 border border-gray-200'
                }`}>
                  {call.analysis.structuredData.call_outcome}
                </div>
              </div>
            )}
            
            {/* Status tags in a row */}
            <div className="flex flex-wrap gap-1.5">
              {call.requiresCallback && (
                <div className="text-xs px-2.5 py-1 bg-red-100 text-red-700 rounded-full font-medium border border-red-200">
                  Callback required
                </div>
              )}
              {call.endedReason === "assistant-forwarded-call" && (
                <div className="text-xs px-2.5 py-1 bg-amber-100 text-amber-700 rounded-full font-medium border border-amber-200">
                  Call forwarded
                </div>
              )}
              {isWhatsApp && call.mediaCount && (
                <div className="text-xs px-2.5 py-1 bg-blue-100 text-blue-700 rounded-full font-medium border border-blue-200">
                  {call.mediaCount} Media Files
                </div>
              )}
            </div>
          </div>

          {/* Timestamps if available */}
          {call.startedAt && (
            <div className="flex items-center mt-2 text-xs text-gray-500">
              <Clock className="h-3 w-3 mr-1 flex-shrink-0" />
              {new Date(call.startedAt).toLocaleString()}
            </div>
          )}
        </div>

        {/* Recording Section - Clean minimalist design */}
        {call.recordingUrl && (
          <div className="border-t pt-4">
            <h3 className="text-md font-semibold mb-2 text-gray-800 flex items-center">
              <Volume2 className="h-5 w-5 text-gray-700 mr-2" />
              Recording
            </h3>
            <div className="bg-gray-50 rounded-lg shadow-md overflow-hidden border border-gray-200">
              {/* Cleaner audio player with no waves */}
              <div className="flex items-center p-4 gap-4">
                {/* Play button with improved visual emphasis */}
                <button 
                  onClick={handlePlayPause}
                  className="flex-shrink-0 w-12 h-12 rounded-full flex items-center justify-center bg-gray-800 shadow-md hover:bg-gray-700 transition-colors"
                >
                  {isPlaying ? (
                    <Pause className="h-5 w-5 text-white" />
                  ) : (
                    <Play className="h-5 w-5 text-white ml-0.5" />
                  )}
                </button>
                
                {/* Time and progress bar with better visual design */}
                <div className="flex-grow">
                  <div className="flex justify-between text-xs font-medium text-gray-700 mb-1.5">
                    <span>{formatTime(currentTime)}</span>
                    <span>{formatTime(duration)}</span>
                  </div>
                  
                  <div className="relative">
                    {/* Custom progress bar for more visual appeal */}
                    <div className="w-full h-2.5 bg-gray-200 rounded-full overflow-hidden border border-gray-300">
                      <div 
                        className="h-full bg-gray-700 transition-all duration-100 rounded-full"
                        style={{ width: `${duration ? (currentTime / duration) * 100 : 0}%` }}
                      ></div>
                    </div>
                    
                    {/* Invisible range input for interaction */}
                    <input 
                      type="range" 
                      min="0" 
                      max={duration || 100}
                      value={currentTime} 
                      onChange={handleSeek}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              
              {/* Hidden audio element */}
              <audio 
                ref={audioRef} 
                src={call.recordingUrl} 
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
                onEnded={handleAudioEnded}
                className="hidden"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CallDetails; 