import React, { useEffect } from 'react';

/**
 * This component handles the callback from Microsoft OneDrive picker
 * It simply displays a message and attempts to close the window
 */
const MicrosoftCallback = () => {
  useEffect(() => {
    const closeWindow = () => {
      // Try to close the window if it's a popup
      if (window.opener) {
        try {
          // Send message to parent window before closing
          window.opener.postMessage('MICROSOFT_AUTH_COMPLETE', window.location.origin);
          // Close after a small delay to ensure the message is sent
          setTimeout(() => window.close(), 100);
        } catch (err) {
          console.error('Error closing popup window:', err);
        }
      } else {
        // If not a popup, redirect back to the main app
        window.location.href = '/';
      }
    };

    // Close the window after a short delay
    const timer = setTimeout(closeWindow, 500);
    
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <h1 className="text-xl font-bold mb-4">Authentication Complete</h1>
        <p className="text-gray-600 mb-4">
          Microsoft OneDrive authorization has been completed. This window will close automatically.
        </p>
        <p className="text-sm text-gray-500">
          If this window doesn't close automatically, you can safely close it and return to the application.
        </p>
      </div>
    </div>
  );
};

export default MicrosoftCallback; 