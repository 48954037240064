import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import ManageIntegrations from './ManageIntegrations';
import AppIntegrations from './AppIntegrations';

const IntegrationsScreen = () => {
  return (
    <div className="container mx-auto px-4 py-6">
      <Tabs defaultValue="app" className="w-full">
        <TabsList className="mb-4">
          <TabsTrigger value="app">App Integrations</TabsTrigger>
          <TabsTrigger value="manage">Manage App Integrations</TabsTrigger>
        </TabsList>
        
        <TabsContent value="app">
          <AppIntegrations />
        </TabsContent>
        
        <TabsContent value="manage">
          <ManageIntegrations />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default IntegrationsScreen; 