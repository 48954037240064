import React, { useState, useEffect } from 'react';
import { Button } from '../components/ui/button';
import AddMemberDialog from '../components/Organization/AddMemberDialog';
import EditMemberDialog from '../components/Organization/EditMemberDialog';
import { usePermissions } from '../hooks/usePermissions';
import { useAuth } from '../contexts/AuthContext';

const OrganizationPage = () => {
  const { can } = usePermissions();
  const { user } = useAuth();

  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        console.log('Fetching members...');  // Debug log
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/organization/members`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'x-api-key': process.env.REACT_APP_API_KEY
          }
        });
        
        console.log('Response:', response);  // Debug log
        if (!response.ok) throw new Error('Failed to fetch team members');
        
        const data = await response.json();
        console.log('Members data:', data);  // Debug log
        setMembers(data.members);
      } catch (err) {
        console.error('Error fetching members:', err);  // Better error logging
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user?.companyId) {
      fetchMembers();
    }
  }, [user?.companyId]);

  const handleUpdateMembers = (updatedMembers) => {
    setMembers(updatedMembers);
  };

  const handleDeleteMember = (id) => {
    setMembers(members.filter(member => member.id !== id));
  };

  const getInitials = (email) => {
    return email.split('@')[0].substring(0, 2).toUpperCase();
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold">Organization Settings</h1>
          <p className="text-gray-600">Manage your team members and roles</p>
        </div>
        {can.manageUsers && (
          <AddMemberDialog />
        )}
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-lg font-semibold mb-4">Team Members</h2>
        <p className="text-gray-600 mb-6">View team members and their roles</p>

        {loading ? (
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        ) : error ? (
          <div className="text-red-600 text-center py-4">{error}</div>
        ) : (
          <div className="space-y-4">
            {members.map((member) => (
              <div
                key={member.id}
                className="flex items-center justify-between p-4 border rounded-lg"
              >
                <div className="flex items-center gap-4">
                  <div className="h-10 w-10 bg-gray-200 rounded-full flex items-center justify-center text-sm">
                    {getInitials(member.email)}
                  </div>
                  <div>
                    <p className="text-gray-900">{member.email}</p>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-sm text-gray-600">{member.role}</span>
                  {can.manageUsers && (
                    <EditMemberDialog 
                      member={member}
                      onUpdate={handleUpdateMembers}
                      onDelete={handleDeleteMember}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationPage; 