import React, { useState, useEffect } from 'react';
import { Play, Volume2, Mic, FileText } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card";
import { Label } from "../ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Switch } from "../ui/switch";
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";
import { updateAssistant, getAssistantId, fetchAssistantConfig } from '../../services/vapiService';
import { fetchVoices } from '../../services/elevenLabsService';
import { useAssistant } from '../../contexts/AssistantContext';

const defaultVoices = [
  {
    name: "Rachel",
    accent: "American",
    age: "middle-aged",
    description: "professional and friendly",
    useCase: "customer service"
  },
  {
    name: "Drew",
    accent: "American",
    age: "middle-aged",
    description: "natural and conversational",
    useCase: "sales"
  }
];

const AgentConfiguration = () => {
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [settings, setSettings] = useState({
    officeNoise: "off",
    recording: true,
    transcription: true,
    agentConfirmation: false
  });
  const [selectedAssistant, setSelectedAssistant] = useState('outbound');
  const { assistantConfigs, voices, loading, refreshConfig } = useAssistant();

  useEffect(() => {
    console.log('Current state:', {
      selectedAssistant,
      loading,
      config: assistantConfigs[selectedAssistant],
      voices: voices.length
    });

    if (!loading && assistantConfigs[selectedAssistant]) {
      const config = assistantConfigs[selectedAssistant];
      
      console.log('Setting config for', selectedAssistant, ':', {
        backgroundSound: config.backgroundSound,
        recordingEnabled: config.artifactPlan?.recordingEnabled,
        transcriptionEnabled: config.artifactPlan?.transcriptPlan?.enabled,
        voiceId: config.voice?.voiceId
      });

      setSettings({
        officeNoise: config.backgroundSound || "off",
        recording: config.artifactPlan?.recordingEnabled || false,
        transcription: config.artifactPlan?.transcriptPlan?.enabled || false
      });
      
      if (voices.length > 0 && config.voice?.voiceId) {
        const currentVoice = voices.find(v => v.voice_id === config.voice.voiceId);
        console.log('Found voice:', currentVoice);
        if (currentVoice) {
          setSelectedVoice(currentVoice);
        }
      }
    } else if (!loading) {
      console.log('Using default settings for', selectedAssistant);
      setSettings({
        officeNoise: "off",
        recording: true,
        transcription: true
      });
      
      if (voices.length > 0) {
        setSelectedVoice(voices[0]);
      }
    }
  }, [selectedAssistant, assistantConfigs, voices, loading]);

  const handlePlayPreview = async () => {
    setIsPlaying(true);
    try {
      const audio = new Audio(selectedVoice.preview_url);
      await audio.play();
    } catch (error) {
      console.error('Error playing preview:', error);
      alert('Error playing preview: ' + error.message);
    }
    setIsPlaying(false);
  };

  const handleSaveChanges = async () => {
    if (!selectedVoice) {
      alert('Please select a voice before saving');
      return;
    }

    setIsPlaying(false);
    try {
      const assistantId = getAssistantId(selectedAssistant);
      const updates = {
        voice: {
          provider: "11labs",
          voiceId: selectedVoice.voice_id
        },
        artifactPlan: {
          recordingEnabled: settings.recording,
          transcriptPlan: {
            enabled: settings.transcription
          }
        },
        backgroundSound: settings.officeNoise
      };

      await updateAssistant(updates, assistantId);
      await refreshConfig(selectedAssistant);
      alert('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Error saving settings: ' + error.message);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 space-y-8">
      <div className="space-y-2">
        <h1 className="text-3xl font-bold tracking-tight">Agent Configuration</h1>
        <p className="text-muted-foreground">Configure your AI assistant's voice and behavior settings.</p>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Assistant Type</CardTitle>
          <CardDescription>Select the type of assistant you want to configure</CardDescription>
        </CardHeader>
        <CardContent>
          <Select value={selectedAssistant} onValueChange={setSelectedAssistant}>
            <SelectTrigger>
              <SelectValue placeholder="Select assistant type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="outbound">Outbound</SelectItem>
              <SelectItem value="inbound">Inbound</SelectItem>
            </SelectContent>
          </Select>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Voice Configuration</CardTitle>
          <CardDescription>Choose and customize the voice for your assistant</CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <Label>Select Voice</Label>
            <Select
              value={selectedVoice?.name?.toLowerCase() || ''}
              onValueChange={(value) => 
                setSelectedVoice(voices.find(v => v.name.toLowerCase() === value) || voices[0])
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a voice" />
              </SelectTrigger>
              <SelectContent>
                {voices.map((voice) => (
                  <SelectItem key={voice.name} value={voice.name.toLowerCase()}>
                    {voice.name.split('-')[0].trim()}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
            <div className="flex flex-col space-y-1.5 p-6">
              <h3 className="text-lg font-semibold">Voice Details</h3>
              <div className="grid gap-2 text-sm">
                <div className="flex justify-between py-1">
                  <span className="text-muted-foreground">Accent:</span>
                  <span className="font-medium">{selectedVoice?.accent || '-'}</span>
                </div>
                <div className="flex justify-between py-1">
                  <span className="text-muted-foreground">Age:</span>
                  <span className="font-medium">{selectedVoice?.age || '-'}</span>
                </div>
                <div className="flex justify-between py-1">
                  <span className="text-muted-foreground">Description:</span>
                  <span className="font-medium">{selectedVoice?.description || '-'}</span>
                </div>
              </div>
            </div>
            <Separator />
            <div className="p-6">
              <Button
                onClick={handlePlayPreview}
                disabled={isPlaying || !selectedVoice}
                className="w-full"
              >
                <Play className="mr-2 h-4 w-4" />
                {isPlaying ? "Playing..." : "Play Preview"}
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Additional Settings</CardTitle>
          <CardDescription>Configure additional features for your assistant</CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="flex items-center justify-between">
            <div className="space-y-0.5">
              <Label>Background Noise</Label>
              <p className="text-sm text-muted-foreground">
                Add ambient office sounds to calls
              </p>
            </div>
            <Switch
              checked={settings.officeNoise === "office"}
              onCheckedChange={(checked) => 
                setSettings(prev => ({ ...prev, officeNoise: checked ? "office" : "off" }))
              }
            />
          </div>
          <Separator />
          <div className="flex items-center justify-between">
            <div className="space-y-0.5">
              <Label>Agent Confirmation</Label>
              <p className="text-sm text-muted-foreground">
                Send request to agent for approval before confirming
              </p>
            </div>
            <Switch
              checked={settings.agentConfirmation}
              onCheckedChange={(checked) => 
                setSettings(prev => ({ ...prev, agentConfirmation: checked }))
              }
            />
          </div>
          <Separator />
          <div className="flex items-center justify-between">
            <div className="space-y-0.5">
              <div className="flex items-center space-x-2">
                <Label>Enable Recording</Label>
                <Mic className="h-4 w-4 text-muted-foreground" />
              </div>
              <p className="text-sm text-muted-foreground">
                Record all conversations
              </p>
            </div>
            <Switch
              checked={settings.recording}
              onCheckedChange={(checked) => 
                setSettings(prev => ({ ...prev, recording: checked }))
              }
            />
          </div>
          <Separator />
          <div className="flex items-center justify-between">
            <div className="space-y-0.5">
              <div className="flex items-center space-x-2">
                <Label>Enable Transcription</Label>
                <FileText className="h-4 w-4 text-muted-foreground" />
              </div>
              <p className="text-sm text-muted-foreground">
                Generate text transcripts
              </p>
            </div>
            <Switch
              checked={settings.transcription}
              onCheckedChange={(checked) => 
                setSettings(prev => ({ ...prev, transcription: checked }))
              }
            />
          </div>
        </CardContent>
      </Card>

      <Button className="w-full" size="lg" onClick={handleSaveChanges}>
        Save Changes
      </Button>
    </div>
  );
};

export default AgentConfiguration; 