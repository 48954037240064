import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchAssistantConfig, getAssistantId } from '../services/vapiService';
import { fetchVoices } from '../services/elevenLabsService';

const AssistantContext = createContext();

export const AssistantProvider = ({ children }) => {
  const [assistantConfigs, setAssistantConfigs] = useState({});
  const [voices, setVoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadAllData = async () => {
      try {
        setLoading(true);
        
        // Fetch voices
        const fetchedVoices = await fetchVoices();
        const allowedVoiceIds = [
          'zw6R54SloPifZabWdObm',  // James
          'lKqU0Pi4tWfYA871XVtN',  // Victoria
          'Qf7kavuK8OX2Qis4DdvA',  // Heather
          'WKWX31A0Kkv2Y4CQRsll',  // Chalaza Hypno
          'Fahco4VZzobUeiPqni1S',  // Archer
          'v9I7auPeR1xGKYRPwQGG'   // Tony
        ];
        
        // Filter voices to only include the allowed ones
        const filteredVoices = fetchedVoices.filter(voice => 
          allowedVoiceIds.includes(voice.voice_id)
        );
        
        // Add custom descriptions if needed
        const voicesWithDescriptions = filteredVoices.map(voice => {
          const descriptions = {
            'zw6R54SloPifZabWdObm': 'Middle-aged, British male, Calm and collected tone',
            'lKqU0Pi4tWfYA871XVtN': 'Classy & Mature',
            'Qf7kavuK8OX2Qis4DdvA': 'Mature British Lady',
            'WKWX31A0Kkv2Y4CQRsll': 'Yorkshire Woman',
            'Fahco4VZzobUeiPqni1S': 'Conversational, Calm British, thirty-something.',
            'v9I7auPeR1xGKYRPwQGG': 'Middle Aged, Male, Northern English'
          };
          
          return {
            ...voice,
            description: descriptions[voice.voice_id] || voice.description
          };
        });
        setVoices(voicesWithDescriptions);

        // Fetch configs for both assistants
        const inboundId = getAssistantId('inbound');
        const outboundId = getAssistantId('outbound');

        console.log('Assistant IDs:', { inboundId, outboundId });

        const [inboundConfig, outboundConfig] = await Promise.all([
          inboundId ? fetchAssistantConfig(inboundId) : null,
          outboundId ? fetchAssistantConfig(outboundId) : null
        ]);

        console.log('Fetched Configs:', {
          inbound: inboundConfig,
          outbound: outboundConfig
        });

        setAssistantConfigs({
          inbound: inboundConfig,
          outbound: outboundConfig
        });

        setLoading(false);
      } catch (err) {
        console.error('Error loading assistant data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    loadAllData();
  }, []);

  return (
    <AssistantContext.Provider value={{ 
      assistantConfigs, 
      voices, 
      loading, 
      error,
      refreshConfig: async (type) => {
        const assistantId = getAssistantId(type);
        const newConfig = await fetchAssistantConfig(assistantId);
        setAssistantConfigs(prev => ({
          ...prev,
          [type]: newConfig
        }));
      }
    }}>
      {children}
    </AssistantContext.Provider>
  );
};

export const useAssistant = () => useContext(AssistantContext); 