import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import AnimatedBackground from '../components/ui/animated-background';
import { authService } from '../services/authService';
import { useAuth } from '../contexts/AuthContext';
import dooraLogo from '../assets/doora-logo.png';

const API_URL = process.env.REACT_APP_API_URL || 'https://9rzs2dyfa4.execute-api.eu-west-2.amazonaws.com/prod';
const API_KEY = process.env.REACT_APP_API_KEY || 'XrVUluK2Zh62iw4WUD1OK6KJQZJYpRPh1xbgAgSk';

const JoinOrganisationPage = () => {
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { user, setUser } = useAuth();

  const handleJoin = async (organizationCode) => {
    setIsLoading(true);
    setError('');
    try {
      if (!user) {
        throw new Error('No user found. Please log in again.');
      }

      console.log('Sending request with user:', user);  // Temporary debug log
      const response = await fetch(`${API_URL}/organization`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY
        },
        body: JSON.stringify({ 
          action: 'verify',
          code: organizationCode,
          userId: user.id
        })
      });
      
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message);
      }
      
      const { user: updatedUser, token } = responseData;
      
      localStorage.setItem('user', JSON.stringify(updatedUser));
      localStorage.setItem('token', token);
      setUser(updatedUser);
      
      console.log('Updated user:', updatedUser);
      
      if (updatedUser && updatedUser.companyId) {
        navigate('/analytics');
      } else {
        throw new Error('Failed to update user organization');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleJoin(code);
  };

  return (
    <AnimatedBackground>
      <div className="min-h-screen flex items-center justify-center p-4 relative">
        <div className="absolute top-6 left-6">
          <img src={dooraLogo} alt="Doora Logo" className="h-8" />
        </div>
        <div className="bg-white/90 backdrop-blur-sm rounded-2xl shadow-lg w-full max-w-md p-8">
          <h1 className="text-2xl font-bold text-center mb-2">Join Organisation</h1>
          <p className="text-gray-600 text-center mb-8">Enter your organisation code to continue</p>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Organisation Code
              </label>
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Enter code"
                required
                disabled={isLoading}
              />
            </div>

            <Button 
              type="submit" 
              className="w-full" 
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Joining...
                </div>
              ) : (
                <>Join Organisation</>
              )}
            </Button>
            {error && (
              <div className="text-red-500 text-sm text-center mt-2">
                {error}
              </div>
            )}
          </form>
        </div>
      </div>
    </AnimatedBackground>
  );
};

export default JoinOrganisationPage; 