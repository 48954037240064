import {
  CalendarClock,
  Home,
  Ban,
  Mail,
  FileCheck,
  PhoneCall,
  Check,
  AlertCircle,
  HelpCircle,
  CalendarCheck,
  User,
  Timer,
  Calendar,
  Building,
  MapPin
} from "lucide-react";

/**
 * Returns an icon component based on the type of notification or booking
 * @param {string} type - The type of notification or booking
 * @returns {JSX.Element} Icon component
 */
export const getTypeIcon = (type) => {
  if (!type) return null;
  
  const lowerType = type.toLowerCase();
  
  if (lowerType.includes('viewing') || lowerType.includes('view')) {
    return <Home size={14} />;
  } else if (lowerType.includes('valuation')) {
    return <Home size={14} />;
  } else if (lowerType.includes('cancel')) {
    return <Ban size={14} />;
  } else if (lowerType.includes('reschedule')) {
    return <Calendar size={14} />;
  } else if (lowerType.includes('callback')) {
    return <PhoneCall size={14} />;
  } else if (lowerType.includes('booking') || lowerType.includes('new booking')) {
    return <CalendarCheck size={14} />;
  } else if (lowerType.includes('registration')) {
    return <User size={14} />;
  } else if (lowerType.includes('inquiry') || lowerType.includes('enquiry')) {
    return <Mail size={14} />;
  } else if (lowerType.includes('property') || lowerType.includes('address')) {
    return <MapPin size={14} />;
  }
  
  // Default icon
  return <HelpCircle size={14} />;
};

/**
 * Returns an icon component based on the status of notifications
 * @param {string} status - The status (confirmed, required, pending, etc)
 * @returns {JSX.Element} Icon component
 */
export const getStatusIcon = (status) => {
  if (!status) return null;
  
  const lowerStatus = status.toLowerCase();
  
  if (lowerStatus === 'confirmed') {
    return <Check size={14} />;
  } else if (lowerStatus === 'required' || lowerStatus === 'cancelled') {
    return <AlertCircle size={14} />;
  } else if (lowerStatus === 'pending' || lowerStatus === 'unsure') {
    return <Timer size={14} />;
  }
  
  // Default icon
  return <HelpCircle size={14} />;
};

/**
 * Returns a Tailwind color class based on the type
 * @param {string} type - The type of notification or booking
 * @returns {string} Tailwind color class
 */
export const getTypeColor = (type) => {
  if (!type) return 'bg-gray-100 text-gray-700';
  
  const lowerType = type.toLowerCase();
  
  if (lowerType.includes('viewing') || lowerType.includes('view')) {
    return 'bg-purple-100 text-purple-700';
  } else if (lowerType.includes('valuation')) {
    return 'bg-green-100 text-green-700';
  } else if (lowerType.includes('cancel')) {
    return 'bg-red-100 text-red-700';
  } else if (lowerType.includes('reschedule')) {
    return 'bg-amber-100 text-amber-700';
  } else if (lowerType.includes('callback')) {
    return 'bg-red-100 text-red-700';
  } else if (lowerType.includes('booking') || lowerType.includes('new booking')) {
    return 'bg-purple-100 text-purple-700';
  } else if (lowerType.includes('registration')) {
    return 'bg-emerald-100 text-emerald-700';
  } else if (lowerType.includes('inquiry') || lowerType.includes('enquiry')) {
    return 'bg-indigo-100 text-indigo-700';
  } else if (lowerType.includes('property') || lowerType.includes('address')) {
    return 'bg-sky-100 text-sky-700';
  }
  
  // Default color
  return 'bg-gray-100 text-gray-700';
};

/**
 * Returns a Tailwind color class based on the status
 * @param {string} status - The status (confirmed, required, pending, etc)
 * @returns {string} Tailwind color class
 */
export const getStatusColor = (status) => {
  if (!status) return 'bg-gray-100 text-gray-700';
  
  const lowerStatus = status.toLowerCase();
  
  if (lowerStatus === 'confirmed') {
    return 'bg-emerald-100 text-emerald-700';
  } else if (lowerStatus === 'required' || lowerStatus === 'cancelled') {
    return 'bg-red-100 text-red-700';
  } else if (lowerStatus === 'pending' || lowerStatus === 'unsure') {
    return 'bg-amber-100 text-amber-700';
  }
  
  // Default color
  return 'bg-gray-100 text-gray-700';
}; 