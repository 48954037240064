import React from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartCard = ({ title, subtitle, data, type = 'line' }) => {
  // Format dates to be like "01 Feb"
  const formattedLabels = data.labels.map(dateStr => {
    try {
      // Expect ISO date string (YYYY-MM-DD)
      const [year, month, day] = dateStr.split('-').map(Number);
      const date = new Date(year, month - 1, day); // month is 0-indexed in Date constructor
      
      if (isNaN(date.getTime())) {
        console.warn('Invalid date:', dateStr);
        return 'Invalid Date';
      }
      
      return date.toLocaleDateString('en-GB', { 
        day: '2-digit',
        month: 'short'
      });
    } catch (error) {
      console.warn('Error formatting date:', dateStr, error);
      return 'Invalid Date';
    }
  });

  const chartData = {
    labels: formattedLabels,
    datasets: [
      {
        label: 'Total',
        data: data.data,
        backgroundColor: type === 'bar' ? 'rgb(75, 192, 192)' : 'rgba(75, 192, 192, 0.1)',
        borderColor: 'rgb(75, 192, 192)',
        fill: type === 'line',
        tension: 0.4,
        borderWidth: 2,
        pointRadius: type === 'line' ? 0 : undefined,
        pointHoverRadius: type === 'line' ? 4 : undefined,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    layout: {
      padding: {
        bottom: 0
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        titleColor: '#1f2937',
        bodyColor: '#1f2937',
        borderColor: '#e5e7eb',
        borderWidth: 1,
        padding: 12,
        boxPadding: 4
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.05)',
          drawBorder: false,
        },
        ticks: {
          callback: function(value) {
            return value.toFixed(0);
          },
          padding: 10,
          color: '#6b7280'
        },
        border: {
          display: false
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          padding: 5,
          color: '#6b7280',
          callback: function(value, index) {
            return formattedLabels[index] === 'Invalid Date' ? '' : formattedLabels[index];
          }
        },
        border: {
          display: false
        }
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    }
  };

  const ChartComponent = type === 'line' ? Line : Bar;

  return (
    <div className="bg-white p-6 pt-6 pb-2 rounded-lg shadow">
      <h3 className="font-medium text-gray-900">{title}</h3>
      <p className="text-sm text-gray-500 mb-4">{subtitle}</p>
      <div className="mt-2 h-[300px]">
        <ChartComponent data={chartData} options={options} />
      </div>
    </div>
  );
};

export default ChartCard; 