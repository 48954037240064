const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const SCOPES = [
  'https://www.googleapis.com/auth/drive.file',
  'https://www.googleapis.com/auth/spreadsheets',
  'https://www.googleapis.com/auth/calendar'
].join(' ');

class GoogleService {
  constructor() {
    this.tokenClient = null;
  }

  async initGoogleAuth() {
    return new Promise((resolve) => {
      if (window.google) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        if (window.google) {
          resolve();
        }
      };
      document.head.appendChild(script);
    });
  }

  async getOAuthToken() {
    await this.initGoogleAuth();

    return new Promise((resolve, reject) => {
      if (!this.tokenClient) {
        this.tokenClient = window.google.accounts.oauth2.initTokenClient({
          client_id: GOOGLE_CLIENT_ID,
          scope: SCOPES,
          callback: (response) => {
            if (response.error) {
              reject(response);
              return;
            }

            localStorage.setItem('google_workspace_token', JSON.stringify({
              access_token: response.access_token,
              expires_at: Date.now() + (response.expires_in * 1000)
            }));
            resolve(response.access_token);
          },
          error_callback: (error) => {
            console.error('OAuth error:', error);
            reject(error);
          }
        });
      }

      const token = this.getStoredToken();
      if (token) {
        resolve(token.access_token);
        return;
      }

      this.tokenClient.requestAccessToken({
        prompt: 'consent'
      });
    });
  }

  // Get the OAuth access token for external APIs (like Google Drive Picker)
  async getOAuthAccessToken() {
    try {
      // Get the stored token or request a new one
      const token = this.getStoredToken();
      
      if (token && token.access_token) {
        return token.access_token;
      }
      
      // If no valid token is found, request a new one
      return await this.getOAuthToken();
    } catch (error) {
      console.error('Error getting OAuth access token:', error);
      throw error;
    }
  }

  getStoredToken() {
    try {
      const token = JSON.parse(localStorage.getItem('google_workspace_token'));
      if (!token) return null;
      
      if (token.expires_at <= Date.now()) {
        localStorage.removeItem('google_workspace_token');
        return null;
      }

      return token;
    } catch {
      return null;
    }
  }

  isConnected() {
    const token = this.getStoredToken();
    return !!token;
  }

  async disconnect() {
    const token = this.getStoredToken();
    if (token?.access_token) {
      try {
        await fetch(`https://oauth2.googleapis.com/revoke?token=${token.access_token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
      } catch (error) {
        console.error('Failed to revoke token:', error);
      }
    }
    localStorage.removeItem('google_workspace_token');
    this.tokenClient = null;
  }

  async listSheets(searchQuery = '') {
    try {
      const token = await this.getOAuthToken();
      const response = await fetch(
        `https://www.googleapis.com/drive/v3/files?q=mimeType='application/vnd.google-apps.spreadsheet' and name contains '${searchQuery}'`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      return data.files || [];
    } catch (error) {
      console.error('Error fetching Google Sheets:', error);
      throw error;
    }
  }

  async getSheetData(spreadsheetId) {
    try {
      const token = await this.getOAuthToken();
      
      console.log(`Fetching data for spreadsheet ID: ${spreadsheetId}`);
      
      const response = await fetch(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}?includeGridData=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Google API error (${response.status}): ${errorText}`);
      }
      
      const data = await response.json();
      
      if (!data || !data.sheets || !Array.isArray(data.sheets)) {
        console.error('Invalid sheet data format:', data);
        return { sheets: [] };
      }
      
      console.log(`Found ${data.sheets.length} sheets in the spreadsheet`);
      
      // Extract headers more carefully
      const processedSheets = data.sheets.map(sheet => {
        const sheetId = sheet.properties?.sheetId;
        const title = sheet.properties?.title || 'Unnamed Sheet';
        
        // Extract headers from the first row if available
        let headers = [];
        try {
          if (sheet.data && 
              sheet.data[0] && 
              sheet.data[0].rowData && 
              sheet.data[0].rowData[0] && 
              sheet.data[0].rowData[0].values) {
            
            headers = sheet.data[0].rowData[0].values
              .map(cell => cell.formattedValue || '')
              .filter(header => header !== ''); // Remove empty headers
              
            console.log(`Extracted ${headers.length} headers from sheet "${title}"`);
          } else {
            console.log(`No headers found in sheet "${title}"`);
          }
        } catch (err) {
          console.error(`Error extracting headers from sheet "${title}":`, err);
        }
        
        return {
          id: sheetId,
          title: title,
          headers: headers
        };
      });
      
      return {
        sheets: processedSheets
      };
    } catch (error) {
      console.error('Error fetching sheet data:', error);
      throw error;
    }
  }
}

export const googleService = new GoogleService(); 