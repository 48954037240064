import React from 'react';

const UsageBar = ({ used, limit, label }) => {
  // Round the used value to 1 decimal place
  const roundedUsed = Math.round(used * 10) / 10;
  const usagePercentage = Math.min((roundedUsed / limit) * 100, 100);
  const isNearLimit = usagePercentage > 80;
  
  return (
    <div className="mb-6 last:mb-0">
      <div className="flex mb-4 items-center justify-between">
        <div>
          <span className={`text-sm font-semibold inline-block py-1.5 px-3 rounded-lg
            ${isNearLimit ? 'text-red-600 bg-red-100' : 'text-teal-600 bg-teal-100'}`}>
            {roundedUsed.toLocaleString()} {label}
          </span>
        </div>
        <div className="text-right">
          <span className="text-sm font-semibold inline-block text-gray-600">
            {limit.toLocaleString()} limit
          </span>
        </div>
      </div>
      <div className="overflow-hidden h-3 mb-2 text-xs flex rounded-full bg-gray-100">
        <div 
          className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center transition-all duration-500
            ${isNearLimit ? 'bg-red-500' : 'bg-teal-500'}`}
          style={{ width: `${usagePercentage}%` }}
        />
      </div>
    </div>
  );
};

const CreditUsageCard = ({ callMinutes, textConversations, plan }) => {
  // These limits should come from the subscription tier in the future
  const LIMITS = {
    Entry: {
      callMinutes: 900,
      textConversations: 1000
    },
    // Add other plan tiers here when needed
    Standard: {
      callMinutes: 3000,
      textConversations: 5000
    }
  };
  
  const currentLimits = LIMITS[plan] || LIMITS.Standard;
  
  return (
    <div className="bg-white rounded-lg shadow p-8">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-semibold">Usage This Month</h3>
        <span className="text-base text-gray-600">{plan} Plan</span>
      </div>
      
      <UsageBar 
        used={callMinutes}
        limit={currentLimits.callMinutes}
        label="call minutes"
      />
      
      <UsageBar 
        used={textConversations}
        limit={currentLimits.textConversations}
        label="text conversations"
      />
    </div>
  );
};

export default CreditUsageCard; 