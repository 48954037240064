// Map reason text to display text
export const reasonTextMap = {
  'New Booking': 'Viewing',
  'Viewing': 'Viewing',
  'Cancellation': 'Cancellation',
  'Client Registration': 'Registration',
  'Inquiry': 'Inquiry',
  'Valuation': 'Valuation',
  'Reschedule': 'Reschedule'
};

// Map of reason for call to specific colors
export const reasonColors = {
  'new booking': 'bg-purple-100 text-purple-700',
  'viewing': 'bg-purple-100 text-purple-700',
  'cancellation': 'bg-red-100 text-red-700',
  'client registration': 'bg-emerald-100 text-emerald-700',
  'registration': 'bg-emerald-100 text-emerald-700',
  'inquiry': 'bg-indigo-100 text-indigo-700',
  'valuation': 'bg-green-100 text-green-700',
  'reschedule': 'bg-amber-100 text-amber-700',
  'other': 'bg-gray-100 text-gray-700'
};

// Helper function to get display text for a reason
export const getReasonDisplayText = (reason) => {
  if (!reason) return reason;
  return reasonTextMap[reason] || reason;
};

// Helper function to get color for a reason
export const getReasonColor = (reason) => {
  if (!reason) return reasonColors.other;
  const key = reason.toLowerCase();
  return reasonColors[key] || reasonColors.other;
};

// Colors for other tag types
export const tagColors = {
  property_name: 'bg-sky-100 text-sky-700',
  forwarded: 'bg-amber-100 text-amber-700',
  callback: 'bg-red-100 text-red-700',
  call_outcome: {
    'Unsure': 'bg-amber-100 text-amber-700',
    'Confirmed': 'bg-emerald-100 text-emerald-700',
    'Cancelled': 'bg-red-100 text-red-700',
    'Rescheduled': 'bg-amber-100 text-amber-700',
    'default': 'bg-gray-100 text-gray-700'
  }
}; 