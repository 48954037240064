"use client"

import { PropertiesDashboard } from "./PropertiesDashboard"

export default function PropertiesScreen() {
  return (
    <div className="container mx-auto px-4 py-6">
      <div className="bg-white rounded-xl shadow-sm border">
        <PropertiesDashboard />
      </div>
    </div>
  )
} 