import React, { useEffect } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card";
import CreditUsageCard from './CreditUsageCard';
import { fetchCallAnalytics } from '../../services/vapiService';
import { Button } from '../ui/button';
import { CreditCard } from 'lucide-react';
import { Skeleton } from "../ui/skeleton";

// Temporary data for text conversations until we have real data
const TEXT_ASSISTANT_DUMMY_DATA = {
  totalCalls: 70,
  totalCreditsUsed: 21,
  toolCalls: {
    'confirm_attendance': 7,
    'check_calendar': 15,
    'book_viewing_real': 15
  }
};

const UsageCardSkeleton = () => (
  <div className="bg-white rounded-lg shadow p-8 animate-pulse">
    <div className="flex justify-between items-center mb-6">
      <div className="h-7 w-40 bg-gray-200 rounded"></div>
      <div className="h-6 w-32 bg-gray-200 rounded"></div>
    </div>
    {[0, 1].map((i) => (
      <div key={i} className="mb-6 last:mb-0">
        <div className="flex mb-4 items-center justify-between">
          <div className="h-8 w-36 bg-gray-200 rounded-lg"></div>
          <div className="h-6 w-24 bg-gray-200 rounded"></div>
        </div>
        <div className="h-3 bg-gray-200 rounded-full"></div>
      </div>
    ))}
  </div>
);

const BillingScreen = () => {
  const [analyticsData, setAnalyticsData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  // Placeholder for subscription data
  const subscriptionData = {
    plan: 'Standard',
    status: 'Active',
    nextBilling: '2024-04-15',
    amount: '£1500.00'
  };

  useEffect(() => {
    const fetchUsageData = async () => {
      try {
        setIsLoading(true);
        // Get the start of the current month
        const now = new Date();
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        
        // Fetch all calls
        const { processed } = await fetchCallAnalytics('all');
        
        // Filter and calculate usage since start of month
        const monthlyUsage = {
          inboundMinutes: processed.inboundMinutes || 0,
          outboundMinutes: processed.outboundMinutes || 0,
          textConversations: TEXT_ASSISTANT_DUMMY_DATA.totalCalls // Using dummy data for now
        };

        setAnalyticsData(monthlyUsage);
      } catch (error) {
        console.error('Error fetching usage data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsageData();
  }, []);

  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold mb-6">Billing & Usage</h2>
      
      {/* Usage Section - Full Width */}
      <div className="mb-12">
        <div className="relative h-[240px]">
          <div className={`transition-opacity duration-300 absolute inset-0 ${isLoading ? 'opacity-100' : 'opacity-0'}`}>
            <UsageCardSkeleton />
          </div>
          <div className={`transition-opacity duration-300 absolute inset-0 ${isLoading ? 'opacity-0' : 'opacity-100'}`}>
            <CreditUsageCard
              callMinutes={analyticsData?.inboundMinutes + analyticsData?.outboundMinutes || 0}
              textConversations={analyticsData?.textConversations || 0}
              plan={subscriptionData.plan}
            />
          </div>
        </div>
      </div>

      {/* Subscription and Payment Section - Two Columns */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Subscription Card */}
        <Card>
          <CardHeader>
            <CardTitle>Current Subscription</CardTitle>
            <CardDescription>Manage your subscription and billing</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <div>
                  <p className="font-medium">{subscriptionData.plan} Plan</p>
                  <p className="text-sm text-gray-500">Status: {subscriptionData.status}</p>
                </div>
                <div className="text-right">
                  <p className="font-medium">{subscriptionData.amount}/month</p>
                  <p className="text-sm text-gray-500">Next billing: {new Date(subscriptionData.nextBilling).toLocaleDateString()}</p>
                </div>
              </div>
              <Button className="w-full">
                <CreditCard className="mr-2 h-4 w-4" />
                Manage Subscription
              </Button>
            </div>
          </CardContent>
        </Card>

        {/* Payment Method Card */}
        <Card>
          <CardHeader>
            <CardTitle>Payment Method</CardTitle>
            <CardDescription>Update your payment information</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <div className="h-8 w-12 bg-gray-100 rounded flex items-center justify-center">
                  <CreditCard className="h-4 w-4 text-gray-500" />
                </div>
                <div>
                  <p className="font-medium">•••• •••• •••• 4242</p>
                  <p className="text-sm text-gray-500">Expires 12/24</p>
                </div>
              </div>
              <Button variant="outline" className="w-full">
                Update Payment Method
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default BillingScreen; 