const ELEVEN_LABS_API_URL = 'https://api.elevenlabs.io/v1';
const API_KEY = process.env.REACT_APP_ELEVEN_LABS_API_KEY;

export const fetchVoices = async () => {
  try {
    const response = await fetch(`${ELEVEN_LABS_API_URL}/voices?show_legacy=true`, {
      headers: {
        'xi-api-key': API_KEY,
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch voices');
    }

    const data = await response.json();
    // Map the voices to include our needed properties
    return data.voices.map(voice => {
      // Helper function to properly format text
      const formatText = (text, defaultValue) => {
        return text?.split(/[-_]/)
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join('-') || defaultValue;
      };

      return {
        name: voice.name,
        voice_id: voice.voice_id,
        preview_url: voice.preview_url,
        // Add our custom metadata
        accent: formatText(voice.labels?.accent, "British"),
        age: formatText(voice.labels?.age, "Middle-aged"),
        description: voice.labels?.description || "Professional AI voice"
      };
    });
  } catch (error) {
    console.error('Error fetching voices:', error);
    throw error;
  }
}; 