import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import AnalyticsDashboard from './components/Analytics/AnalyticsDashboard';
import ConversationsScreen from './components/Conversations/ConversationsScreen';
import AgentConfiguration from './components/Settings/AgentConfiguration';
import NotificationsScreen from './components/Notifications/NotificationsScreen';
import OrganizationPage from './pages/OrganizationPage';
import LoginPage from './pages/LoginPage';
import JoinOrganisationPage from './pages/JoinOrganisationPage';
import { AuthProvider } from './contexts/AuthContext';
import { AssistantProvider } from './contexts/AssistantContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import PrivateRoute from './components/PrivateRoute';
import IntegrationsScreen from './components/Integrations/IntegrationsScreen';
import MicrosoftCallback from './components/Integrations/MicrosoftCallback';
import BillingScreen from './components/Billing/BillingScreen';
import PropertiesScreen from './components/Properties/PropertiesScreen';

function App() {
  return (
    <Router>
      <AuthProvider>
        <AssistantProvider>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/join-organisation" element={<JoinOrganisationPage />} />
            <Route path="/auth/microsoft-callback" element={<MicrosoftCallback />} />
            <Route path="/" element={<RequireAuth><Layout /></RequireAuth>}>
              <Route index element={<Navigate to="/analytics" />} />
              <Route path="analytics" element={<AnalyticsDashboard />} />
              <Route path="conversations" element={<ConversationsScreen />} />
              <Route path="notifications" element={<NotificationsScreen />} />
              <Route path="billing" element={<BillingScreen />} />
              <Route path="settings" element={<AgentConfiguration />} />
              <Route path="organization" element={<OrganizationPage />} />
              <Route path="integrations" element={<IntegrationsScreen />} />
              <Route path="properties" element={<PropertiesScreen />} />
            </Route>
          </Routes>
        </AssistantProvider>
      </AuthProvider>
    </Router>
  );
}

// Simple auth check component
const RequireAuth = ({ children }) => {
  // For now, just check if we have any value in localStorage
  const isAuthenticated = localStorage.getItem('user');
  
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  
  return children;
};

export default App; 