import React from 'react';

const AnimatedBackground = ({ children }) => {
  return (
    <div className="min-h-screen relative overflow-hidden bg-[#EEF1FF]">
      {/* Animated gradient blobs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="blob1 absolute right-[15%] top-[20%] w-[1200px] h-[1200px] rounded-full 
          bg-gradient-to-r from-blue-400/20 to-blue-500/20 
          blur-3xl mix-blend-multiply animate-blob1" />
        <div className="blob2 absolute right-[25%] bottom-[10%] w-[1000px] h-[1000px] rounded-full 
          bg-gradient-to-r from-blue-300/20 to-blue-600/20 
          blur-3xl mix-blend-multiply animate-blob2" />
        <div className="blob3 absolute right-[35%] top-[40%] w-[900px] h-[900px] rounded-full 
          bg-gradient-to-r from-blue-500/20 to-blue-400/20 
          blur-3xl mix-blend-multiply animate-blob3" />
      </div>

      {/* Content */}
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};

export default AnimatedBackground; 