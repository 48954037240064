import { PublicClientApplication } from '@azure/msal-browser';

const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID;

// Configuration object
const msalConfig = {
  auth: {
    clientId: MICROSOFT_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: `${window.location.origin}/integrations`,
    postLogoutRedirectUri: `${window.location.origin}/integrations`,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

// Define authentication request
const loginRequest = {
  scopes: [
    'User.Read',
    'Files.Read',
    'Files.ReadWrite',
    'Files.ReadWrite.All',
    'Sites.Read.All',
    // Excel API specific scopes
    'Files.Read.Selected',
    'Files.ReadWrite.Selected',
    'Sites.ReadWrite.All',
    'Sites.Selected',
    'ExcelServices.Read',
    'ExcelServices.Selected',
    // Add OpenID scopes
    'openid',
    'profile',
    'offline_access'
  ]
};

class MicrosoftService {
  constructor() {
    this.msalInstance = new PublicClientApplication(msalConfig);
    this.isInitialized = false;
    this.initializeMsal();
  }

  async initializeMsal() {
    if (!this.isInitialized) {
      await this.msalInstance.initialize();
      this.isInitialized = true;
    }
    return this.msalInstance;
  }

  async getOAuthToken(forceRefresh = false) {
    try {
      const msalClient = await this.initializeMsal();
      
      // Check if there are already accounts signed in
      const accounts = msalClient.getAllAccounts();
      
      if (accounts.length > 0 && !forceRefresh) {
        // Account exists, get tokens silently
        try {
          console.log('Attempting silent token acquisition with existing account');
          const response = await msalClient.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
            forceRefresh
          });
          console.log('Silent token acquisition succeeded');
          return this.handleAuthResponse(response);
        } catch (silentError) {
          // Silent token acquisition failed, fall back to interactive method
          console.warn('Silent token acquisition failed, trying popup:', silentError);
        }
      } else {
        if (forceRefresh) {
          console.log('Force refresh requested, skipping silent token acquisition');
        } else {
          console.log('No existing accounts found, need to sign in');
        }
      }

      // Configure popup options for better user experience
      const popupConfig = {
        popupPosition: { top: 100, left: 100 },
        popupSize: { width: 600, height: 600 },
        popupWindowName: "Microsoft Login Popup"
      };

      // No account exists or silent acquisition failed, try popup
      console.log('Starting interactive authentication popup');
      const response = await msalClient.loginPopup({
        ...loginRequest,
        ...popupConfig
      });
      console.log('Interactive authentication succeeded');
      return this.handleAuthResponse(response);
      
    } catch (error) {
      console.error('Microsoft login error:', error);
      throw error;
    }
  }

  handleAuthResponse(response) {
    // Store the authentication result
    localStorage.setItem('microsoft_office_token', JSON.stringify({
      access_token: response.accessToken,
      account: response.account,
      expires_at: Date.now() + (3600 * 1000) // Token typically expires in 1 hour
    }));

    return response.accessToken;
  }

  // Get the OAuth access token for external APIs (like OneDrive Picker)
  async getOAuthAccessToken() {
    try {
      // Get the stored token or request a new one
      const tokenData = JSON.parse(localStorage.getItem('microsoft_office_token') || 'null');
      
      if (tokenData && tokenData.access_token && tokenData.expires_at > Date.now()) {
        return tokenData.access_token;
      }
      
      // If no valid token is found, request a new one
      return await this.getOAuthToken();
    } catch (error) {
      console.error('Error getting Microsoft OAuth access token:', error);
      throw error;
    }
  }

  isConnected() {
    try {
      const token = JSON.parse(localStorage.getItem('microsoft_office_token') || 'null');
      if (!token) return false;
      
      // Check if token is expired
      if (token.expires_at <= Date.now()) {
        localStorage.removeItem('microsoft_office_token');
        return false;
      }
      
      return true;
    } catch (error) {
      console.error('Error checking connection status:', error);
      return false;
    }
  }

  async disconnect() {
    try {
      // Clear the stored token
      localStorage.removeItem('microsoft_office_token');
      
      // Sign out from MSAL
      if (this.msalInstance) {
        const accounts = this.msalInstance.getAllAccounts();
        if (accounts.length > 0) {
          await this.msalInstance.logoutPopup({
            account: accounts[0],
            postLogoutRedirectUri: window.location.origin
          });
        }
      }
      
      return true;
    } catch (error) {
      console.error('Error disconnecting from Microsoft:', error);
      throw error;
    }
  }

  async listExcelFiles(searchQuery = '') {
    try {
      const token = await this.getOAuthToken();
      const response = await fetch(
        `https://graph.microsoft.com/v1.0/me/drive/root/search(q='.xlsx')`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      return data.value.filter(file => 
        file.name.toLowerCase().includes(searchQuery.toLowerCase())
      ) || [];
    } catch (error) {
      console.error('Error fetching Excel files:', error);
      throw error;
    }
  }

  async getExcelSheets(fileId) {
    try {
      console.log('Fetching worksheets for Excel file ID:', fileId);
      
      // Get a fresh access token
      const accessToken = await this.getOAuthAccessToken();
      
      if (!accessToken) {
        throw new Error('No valid access token available');
      }
      
      console.log('Got access token, creating session for Excel file');

      // Create a session to work with the workbook
      const sessionResponse = await fetch(
        `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/workbook/createSession`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            persistChanges: false // We're only reading data
          })
        }
      );

      if (!sessionResponse.ok) {
        const errorText = await sessionResponse.text();
        console.error('❌ Session creation failed:', sessionResponse.status, errorText);
        throw new Error(`Failed to create Excel session: ${sessionResponse.statusText}`);
      }

      console.log('✅ Session created successfully, getting worksheets');
      const { id: sessionId } = await sessionResponse.json();

      try {
        // Get all worksheets in a single call using the session
        const response = await fetch(
          `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/workbook/worksheets`,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'workbook-session-id': sessionId
            }
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          console.error('❌ Worksheet fetch failed:', response.status, errorText);
          throw new Error(`Failed to get worksheets: ${response.statusText}`);
        }

        console.log('✅ Worksheets fetched successfully');
        const data = await response.json();
        console.log('Worksheet data:', data);
        
        if (!data.value || !Array.isArray(data.value)) {
          console.error('❌ Invalid worksheet data format:', data);
          throw new Error('Invalid response format from Excel API');
        }

        // Map worksheets to their basic info without fetching headers yet
        const sheets = data.value.map(sheet => ({
          id: sheet.id,
          title: sheet.name,
          position: sheet.position,
          visibility: sheet.visibility
        }));
        
        console.log('Found sheets:', sheets.length, sheets.map(s => s.title));

        // For the first visible sheet only, try to get headers
        const visibleSheet = sheets.find(s => s.visibility === 'Visible');
        if (visibleSheet) {
          try {
            console.log('Fetching headers for sheet:', visibleSheet.title);
            // Get just A1:Z1 range for the first visible sheet
            const headerResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/workbook/worksheets/${visibleSheet.id}/range(address='A1:Z1')`,
              {
                headers: {
                  'Authorization': `Bearer ${accessToken}`,
                  'workbook-session-id': sessionId
                }
              }
            );

            if (headerResponse.ok) {
              const headerData = await headerResponse.json();
              console.log('Header data received:', headerData);
              if (headerData.values && headerData.values[0]) {
                visibleSheet.headers = headerData.values[0].filter(h => h !== null && h !== '');
                console.log('Extracted headers:', visibleSheet.headers);
              } else {
                console.warn('No header values found in response');
              }
            } else {
              const errorText = await headerResponse.text();
              console.warn('Failed to fetch headers:', headerResponse.status, errorText);
            }
          } catch (headerError) {
            console.warn('Failed to fetch headers for first sheet:', headerError);
          }
        } else {
          console.warn('No visible sheets found, unable to fetch headers');
        }

        console.log('Returning sheets data:', { sheets });
        return { sheets };
      } finally {
        // Always close the session
        try {
          console.log('Closing Excel session:', sessionId);
          await fetch(
            `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/workbook/closeSession`,
            {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${accessToken}`,
                'workbook-session-id': sessionId
              }
            }
          );
          console.log('Session closed successfully');
        } catch (closeError) {
          console.warn('Failed to close Excel session:', closeError);
        }
      }
    } catch (error) {
      console.error('Error fetching Excel data:', error);
      return { sheets: [] };
    }
  }

  // Alternative method to get Excel sheet info by downloading content
  async getExcelSheetsAlternative(fileId) {
    try {
      console.log('Using alternative method to get Excel sheets for file ID:', fileId);
      
      // Get a fresh access token
      const accessToken = await this.getOAuthAccessToken();
      
      if (!accessToken) {
        console.error('No valid access token available');
        return { sheets: [] };
      }
      
      // First try to download the file content directly
      console.log('Attempting to download file content directly');
      const downloadResponse = await fetch(
        `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}/content`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );

      if (!downloadResponse.ok) {
        const errorText = await downloadResponse.text();
        console.error('Failed to download file content:', downloadResponse.status, errorText);
        
        // If direct download fails, try to get at least the file metadata
        console.log('Trying to get file metadata instead');
        const metadataResponse = await fetch(
          `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}`,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );
        
        if (metadataResponse.ok) {
          const metadata = await metadataResponse.json();
          console.log('File metadata:', metadata);
          
          // Create a basic mock sheet since we can't access the actual content
          return {
            sheets: [{
              id: 'sheet1',
              title: 'Default Sheet',
              position: 0,
              visibility: 'Visible',
              headers: ['Please use the Excel desktop app to verify headers']
            }]
          };
        } else {
          console.error('Failed to get file metadata:', await metadataResponse.text());
          return { sheets: [] };
        }
      } else {
        // Successfully downloaded content, but we can't parse Excel in the browser
        // Just return a mock sheet structure
        console.log('Successfully downloaded file content');
        return {
          sheets: [{
            id: 'sheet1',
            title: 'Downloaded Sheet',
            position: 0,
            visibility: 'Visible',
            headers: ['Content downloaded, please use desktop app to verify headers']
          }]
        };
      }
    } catch (error) {
      console.error('Error in alternative Excel sheet fetch method:', error);
      return { sheets: [] };
    }
  }

  // Force a token refresh, useful when permissions might have changed
  async forceTokenRefresh() {
    try {
      console.log('Forcing token refresh for Microsoft authentication');
      // Clear existing token
      localStorage.removeItem('microsoft_office_token');
      
      // Request a new token with full interactivity
      return await this.getOAuthToken(true);
    } catch (error) {
      console.error('Error during forced token refresh:', error);
      throw error;
    }
  }
}

export const microsoftService = new MicrosoftService(); 