"use client"

import { Card, CardContent } from "../../ui/card"
import { Input } from "../../ui/input"
import { Label } from "../../ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select"
import { Badge } from "../../ui/badge"
import { Building, Coins, Clock } from "lucide-react"

export function PropertyLeaseholdTab({ property, isEditing, onChange }) {
  if (!isEditing) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="space-y-6">
            <div className="flex items-start gap-3">
              <Building className="h-5 w-5 text-muted-foreground mt-0.5" />
              <div>
                <h3 className="text-sm font-medium text-muted-foreground">Tenure</h3>
                <div className="flex items-center gap-2 mt-1">
                  <Badge variant={property.tenure === "Freehold" ? "default" : "outline"}>{property.tenure}</Badge>
                </div>
              </div>
            </div>

            {property.tenure === "Leasehold" && (
              <>
                <div className="flex items-start gap-3">
                  <Clock className="h-5 w-5 text-muted-foreground mt-0.5" />
                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground">Years Remaining on Lease</h3>
                    <p className="text-base font-medium">{property.leaseYearsRemaining ?? "N/A"}</p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <Coins className="h-5 w-5 text-muted-foreground mt-0.5" />
                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground">Service Charge (Annual)</h3>
                    <p className="text-base">
                      {property.serviceCharge
                        ? new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0,
                          }).format(property.serviceCharge)
                        : "N/A"}
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <Coins className="h-5 w-5 text-muted-foreground mt-0.5" />
                  <div>
                    <h3 className="text-sm font-medium text-muted-foreground">Ground Rent (Annual)</h3>
                    <p className="text-base">
                      {property.groundRent
                        ? new Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                            maximumFractionDigits: 0,
                          }).format(property.groundRent)
                        : "N/A"}
                    </p>
                  </div>
                </div>
              </>
            )}

            {property.tenure === "Freehold" && (
              <div className="p-4 bg-muted/20 rounded-md">
                <p className="text-muted-foreground">
                  This is a freehold property. No leasehold information is applicable.
                </p>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card>
      <CardContent className="pt-6 space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="tenure">Tenure</Label>
              <Select
                value={property.tenure}
                onValueChange={(value) => {
                  const updates = { tenure: value }

                  // Reset leasehold fields if switching to Freehold
                  if (value === "Freehold") {
                    updates.leaseYearsRemaining = null
                    updates.serviceCharge = null
                    updates.groundRent = null
                  }

                  onChange(updates)
                }}
              >
                <SelectTrigger id="tenure">
                  <SelectValue placeholder="Select tenure" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Freehold">Freehold</SelectItem>
                  <SelectItem value="Leasehold">Leasehold</SelectItem>
                  <SelectItem value="Share of Freehold">Share of Freehold</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {property.tenure === "Leasehold" && (
              <>
                <div className="space-y-2">
                  <Label htmlFor="lease-years">Years Remaining on Lease</Label>
                  <Input
                    id="lease-years"
                    type="number"
                    value={property.leaseYearsRemaining ?? ""}
                    onChange={(e) =>
                      onChange({
                        leaseYearsRemaining: e.target.value ? Number(e.target.value) : null,
                      })
                    }
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="service-charge">Service Charge (£ per year)</Label>
                  <Input
                    id="service-charge"
                    type="number"
                    value={property.serviceCharge ?? ""}
                    onChange={(e) =>
                      onChange({
                        serviceCharge: e.target.value ? Number(e.target.value) : null,
                      })
                    }
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="ground-rent">Ground Rent (£ per year)</Label>
                  <Input
                    id="ground-rent"
                    type="number"
                    value={property.groundRent ?? ""}
                    onChange={(e) =>
                      onChange({
                        groundRent: e.target.value ? Number(e.target.value) : null,
                      })
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  )
} 