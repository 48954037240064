import React, { createContext, useContext, useEffect, useState } from 'react';
import { authService } from '../services/authService';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is already logged in
    const checkAuth = async () => {
      try {
        const userData = await authService.getCurrentUser();
        console.log('Loading user data:', userData);
        
        if (userData) {
          // Ensure user data is properly structured with all required fields
          const user = {
            ...userData,
            photoURL: userData.photoURL || null,
            email: userData.email || null,
            role: userData.role || null,
            companyId: userData.companyId || null,
            orgConfig: userData.orgConfig || null
          };
          
          console.log('Setting structured user data:', user);
          setUser(user);
          
          if (!user.companyId && window.location.pathname !== '/join-organisation') {
            navigate('/join-organisation');
          } else if (window.location.pathname === '/login') {
            navigate('/');
          }
        }
      } catch (error) {
        console.error('Auth check failed:', error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [navigate]);

  const signIn = async (email, password) => {
    const response = await authService.signIn(email, password);
    setUser(response.user);
    return response;
  };

  const signUp = async (email, password) => {
    const response = await authService.signUp(email, password);
    setUser(response.user);
    return response;
  };

  const signInWithGoogle = async () => {
    try {
      const response = await authService.signInWithGoogle();
      setUser(response.user);
      
      // Navigate based on CompanyID
      if (!response.user.companyId) {
        navigate('/join-organisation');
      } else {
        navigate('/analytics');
      }
      
    } catch (error) {
      console.error('Google sign in error:', error);
      setError(error.message);
    }
  };

  const signOut = () => {
    // Get current user ID before clearing
    const user = JSON.parse(localStorage.getItem('user'));
    if (user?.id) {
      // Clear user-specific data
      localStorage.removeItem(`read_notifications_${user.id}`);
    }
    
    // Clear auth data
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
    navigate('/login');
  };

  const value = {
    user,
    setUser,
    organization: JSON.parse(localStorage.getItem('organization')),
    signIn,
    signUp,
    signInWithGoogle,
    signOut
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}; 