"use client"

import { useState } from "react"
import { Card, CardContent } from "../../ui/card.jsx"
import { Button } from "../../ui/button.jsx"
import { Label } from "../../ui/label.jsx"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select.jsx"
import { format, addDays, addWeeks } from "date-fns"
import { PlusCircle, Trash2, Clock } from "lucide-react"
import { cn } from "../../../lib/utils"
import { Badge } from "../../ui/badge.jsx"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./datepicker-styles.css"

export function PropertyCalendarTab({ property, isEditing, onChange }) {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [newWindow, setNewWindow] = useState({
    startHour: "09",
    startMinute: "00",
    endHour: "17",
    endMinute: "00",
    recurrence: "none", // none, daily, weekly
    recurrenceCount: 1,
  })

  const addAvailabilityWindow = () => {
    if (!selectedDate) return

    let newWindows = []
    let currentDate = new Date(selectedDate)

    // Create windows based on recurrence
    for (let i = 0; i < (newWindow.recurrence === "none" ? 1 : newWindow.recurrenceCount); i++) {
      const start = new Date(currentDate)
      start.setHours(Number.parseInt(newWindow.startHour), Number.parseInt(newWindow.startMinute))

      const end = new Date(currentDate)
      end.setHours(Number.parseInt(newWindow.endHour), Number.parseInt(newWindow.endMinute))

      if (start < end) {
        newWindows.push({ start, end })
      }

      // Move to next occurrence
      if (newWindow.recurrence === "daily") {
        currentDate = addDays(currentDate, 1)
      } else if (newWindow.recurrence === "weekly") {
        currentDate = addWeeks(currentDate, 1)
      }
    }

    if (newWindows.length > 0) {
      onChange({
        availabilityWindows: [...property.availabilityWindows, ...newWindows],
      })

      // Reset form
      setNewWindow({
        startHour: "09",
        startMinute: "00",
        endHour: "17",
        endMinute: "00",
        recurrence: "none",
        recurrenceCount: 1,
      })
    }
  }

  const removeAvailabilityWindow = (index) => {
    const updatedWindows = [...property.availabilityWindows]
    updatedWindows.splice(index, 1)
    onChange({ availabilityWindows: updatedWindows })
  }

  // Get dates with availability windows
  const datesWithAvailability = property.availabilityWindows.map((window) => window.start.toDateString())

  // Function to check if a date has availability
  const hasAvailability = (date) => {
    return datesWithAvailability.includes(date.toDateString())
  }

  // Highlight dates with availability
  const highlightWithAvailability = (date) => {
    if (hasAvailability(date)) {
      return "highlight-date";
    }
    return undefined;
  }

  // Get availability windows for selected date
  const getWindowsForDate = (date) => {
    return property.availabilityWindows.filter((window) => window.start.toDateString() === date.toDateString())
  }

  const selectedDateWindows = selectedDate ? getWindowsForDate(selectedDate) : []

  return (
    <Card>
      <CardContent className="pt-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-h-[55vh] overflow-y-auto pr-2">
          <div>
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-base font-medium">Viewing Availability</h3>
              <Badge variant="outline" className="flex items-center gap-1">
                <Clock className="h-3 w-3" />
                {property.availabilityWindows.length} slots
              </Badge>
            </div>
            <div className="border rounded-lg p-4 flex justify-center">
              <div className="w-full max-w-[350px]">
                <DatePicker
                  selected={selectedDate}
                  onChange={setSelectedDate}
                  inline
                  dayClassName={highlightWithAvailability}
                />
              </div>
            </div>
            
            {isEditing && (
              <div className="mt-6">
                <h3 className="text-base font-medium mb-4">All Availability Windows</h3>
                {property.availabilityWindows.length > 0 ? (
                  <div className="space-y-2">
                    {property.availabilityWindows.map((window, index) => (
                      <div key={index} className="flex items-center justify-between p-2 border rounded-md text-sm">
                        <p className="font-medium">
                          {format(window.start, "MMM d")} {format(window.start, "h:mm a")} - {format(window.end, "h:mm a")}
                        </p>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() =>
                            removeAvailabilityWindow(
                              property.availabilityWindows.findIndex(
                                (w) =>
                                  w.start.getTime() === window.start.getTime() &&
                                  w.end.getTime() === window.end.getTime()
                              )
                            )
                          }
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-40 border rounded-md bg-muted/20">
                    <p className="text-muted-foreground">
                      No availability windows available
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>

          <div>
            <h3 className="text-base font-medium mb-4">
              {selectedDate ? `Availability for ${format(selectedDate, "PPP")}` : "Select a date to view availability"}
            </h3>

            {selectedDateWindows.length > 0 ? (
              <div>
                <div className="space-y-4 mb-6">
                  {selectedDateWindows.map((window, index) => (
                    <div key={index} className="flex items-center justify-between p-3 border rounded-md">
                      <div>
                        <p className="font-medium">
                          {format(window.start, "h:mm a")} - {format(window.end, "h:mm a")}
                        </p>
                        <p className="text-sm text-muted-foreground">
                          {format(window.end, "h:mm a") === format(window.start, "h:mm a")
                            ? "Invalid time window"
                            : `${Math.round((window.end.getTime() - window.start.getTime()) / (1000 * 60))} minutes`}
                        </p>
                      </div>
                      {isEditing && (
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() =>
                            removeAvailabilityWindow(
                              property.availabilityWindows.findIndex(
                                (w) =>
                                  w.start.getTime() === window.start.getTime() &&
                                  w.end.getTime() === window.end.getTime()
                              )
                            )
                          }
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
                
                {isEditing && (
                  <div className="border rounded-lg p-4 mb-6">
                    <h4 className="text-sm font-medium mb-4">Add Availability for {format(selectedDate, "MMM d, yyyy")}</h4>
                    <div className="space-y-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-2">
                          <Label>Start Time</Label>
                          <div className="flex gap-2">
                            <Select
                              value={newWindow.startHour}
                              onValueChange={(value) => setNewWindow({ ...newWindow, startHour: value })}
                            >
                              <SelectTrigger className="w-full">
                                <SelectValue placeholder="Hour" />
                              </SelectTrigger>
                              <SelectContent>
                                {Array.from({ length: 24 }).map((_, i) => (
                                  <SelectItem key={i} value={i.toString().padStart(2, "0")}>
                                    {i.toString().padStart(2, "0")}:00
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <Select
                              value={newWindow.startMinute}
                              onValueChange={(value) => setNewWindow({ ...newWindow, startMinute: value })}
                            >
                              <SelectTrigger className="w-full">
                                <SelectValue placeholder="Min" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="00">00</SelectItem>
                                <SelectItem value="15">15</SelectItem>
                                <SelectItem value="30">30</SelectItem>
                                <SelectItem value="45">45</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>

                        <div className="space-y-2">
                          <Label>End Time</Label>
                          <div className="flex gap-2">
                            <Select
                              value={newWindow.endHour}
                              onValueChange={(value) => setNewWindow({ ...newWindow, endHour: value })}
                            >
                              <SelectTrigger className="w-full">
                                <SelectValue placeholder="Hour" />
                              </SelectTrigger>
                              <SelectContent>
                                {Array.from({ length: 24 }).map((_, i) => (
                                  <SelectItem key={i} value={i.toString().padStart(2, "0")}>
                                    {i.toString().padStart(2, "0")}:00
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <Select
                              value={newWindow.endMinute}
                              onValueChange={(value) => setNewWindow({ ...newWindow, endMinute: value })}
                            >
                              <SelectTrigger className="w-full">
                                <SelectValue placeholder="Min" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="00">00</SelectItem>
                                <SelectItem value="15">15</SelectItem>
                                <SelectItem value="30">30</SelectItem>
                                <SelectItem value="45">45</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      </div>

                      <div className="space-y-2">
                        <Label>Recurrence</Label>
                        <div className="grid grid-cols-2 gap-4">
                          <Select
                            value={newWindow.recurrence}
                            onValueChange={(value) => setNewWindow({ ...newWindow, recurrence: value })}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select recurrence" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="none">One-time</SelectItem>
                              <SelectItem value="daily">Daily</SelectItem>
                              <SelectItem value="weekly">Weekly</SelectItem>
                            </SelectContent>
                          </Select>

                          {newWindow.recurrence !== "none" && (
                            <Select
                              value={newWindow.recurrenceCount.toString()}
                              onValueChange={(value) => setNewWindow({ ...newWindow, recurrenceCount: Number(value) })}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select count" />
                              </SelectTrigger>
                              <SelectContent>
                                {Array.from({ length: 12 }).map((_, i) => (
                                  <SelectItem key={i + 1} value={(i + 1).toString()}>
                                    {i + 1} {newWindow.recurrence === "daily" ? "days" : "weeks"}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          )}
                        </div>
                      </div>

                      <Button
                        onClick={addAvailabilityWindow}
                        className="w-full"
                        disabled={!selectedDate}
                      >
                        <PlusCircle className="mr-2 h-4 w-4" />
                        Add {newWindow.recurrence !== "none" ? "Recurring " : ""}Availability
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            ) : isEditing ? (
              <div className="border rounded-lg p-4">
                <h4 className="text-sm font-medium mb-4">Add Availability for {format(selectedDate, "MMM d, yyyy")}</h4>
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <Label>Start Time</Label>
                      <div className="flex gap-2">
                        <Select
                          value={newWindow.startHour}
                          onValueChange={(value) => setNewWindow({ ...newWindow, startHour: value })}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Hour" />
                          </SelectTrigger>
                          <SelectContent>
                            {Array.from({ length: 24 }).map((_, i) => (
                              <SelectItem key={i} value={i.toString().padStart(2, "0")}>
                                {i.toString().padStart(2, "0")}:00
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <Select
                          value={newWindow.startMinute}
                          onValueChange={(value) => setNewWindow({ ...newWindow, startMinute: value })}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Min" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="00">00</SelectItem>
                            <SelectItem value="15">15</SelectItem>
                            <SelectItem value="30">30</SelectItem>
                            <SelectItem value="45">45</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>

                    <div className="space-y-2">
                      <Label>End Time</Label>
                      <div className="flex gap-2">
                        <Select
                          value={newWindow.endHour}
                          onValueChange={(value) => setNewWindow({ ...newWindow, endHour: value })}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Hour" />
                          </SelectTrigger>
                          <SelectContent>
                            {Array.from({ length: 24 }).map((_, i) => (
                              <SelectItem key={i} value={i.toString().padStart(2, "0")}>
                                {i.toString().padStart(2, "0")}:00
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <Select
                          value={newWindow.endMinute}
                          onValueChange={(value) => setNewWindow({ ...newWindow, endMinute: value })}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Min" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="00">00</SelectItem>
                            <SelectItem value="15">15</SelectItem>
                            <SelectItem value="30">30</SelectItem>
                            <SelectItem value="45">45</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <Label>Recurrence</Label>
                    <div className="grid grid-cols-2 gap-4">
                      <Select
                        value={newWindow.recurrence}
                        onValueChange={(value) => setNewWindow({ ...newWindow, recurrence: value })}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select recurrence" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="none">One-time</SelectItem>
                          <SelectItem value="daily">Daily</SelectItem>
                          <SelectItem value="weekly">Weekly</SelectItem>
                        </SelectContent>
                      </Select>

                      {newWindow.recurrence !== "none" && (
                        <Select
                          value={newWindow.recurrenceCount.toString()}
                          onValueChange={(value) => setNewWindow({ ...newWindow, recurrenceCount: Number(value) })}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select count" />
                          </SelectTrigger>
                          <SelectContent>
                            {Array.from({ length: 12 }).map((_, i) => (
                              <SelectItem key={i + 1} value={(i + 1).toString()}>
                                {i + 1} {newWindow.recurrence === "daily" ? "days" : "weeks"}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    </div>
                  </div>

                  <Button
                    onClick={addAvailabilityWindow}
                    className="w-full"
                    disabled={!selectedDate}
                  >
                    <PlusCircle className="mr-2 h-4 w-4" />
                    Add {newWindow.recurrence !== "none" ? "Recurring " : ""}Availability
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex items-center justify-center h-40 border rounded-md bg-muted/20 mb-6">
                  <p className="text-muted-foreground">
                    No availability windows for this date
                  </p>
                </div>
                
                <h3 className="text-base font-medium mb-4">All Availability Windows</h3>
                {property.availabilityWindows.length > 0 ? (
                  <div className="space-y-2">
                    {property.availabilityWindows.map((window, index) => (
                      <div key={index} className="flex items-center justify-between p-2 border rounded-md text-sm">
                        <p className="font-medium">
                          {format(window.start, "MMM d")} {format(window.start, "h:mm a")} - {format(window.end, "h:mm a")}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-40 border rounded-md bg-muted/20">
                    <p className="text-muted-foreground">
                      No availability windows available
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  )
} 