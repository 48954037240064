"use client"

import { useState } from "react"
import { Search, Plus, X, Heart, Mail, Home, Bed, Bath, Calendar, ClipboardCheck, Building2, MapPin } from "lucide-react"
import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { Badge } from "../ui/badge"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogClose } from "../ui/dialog"
import { PropertyDetails } from "./PropertyDetails"
import { AddPropertyForm } from "./AddPropertyForm"

// Sample property data
const initialProperties = [
  {
    id: "1",
    address: "66 Kent Road",
    city: "London",
    postcode: "NW1 6HD",
    description:
      "A spacious 3-bedroom house with a garden and modern kitchen. Recently renovated with new appliances. Close to schools and transport links.",
    price: 750000,
    bedrooms: 3,
    bathrooms: 2,
    propertyType: "House",
    tenure: "Leasehold",
    leaseYearsRemaining: 85,
    serviceCharge: 1200,
    groundRent: 250,
    agentConfirmationRequired: true,
    availabilityWindows: [
      { start: new Date(2025, 0, 15, 10, 0), end: new Date(2025, 0, 15, 16, 0) },
      { start: new Date(2025, 0, 16, 12, 0), end: new Date(2025, 0, 16, 18, 0) },
      { start: new Date(2025, 0, 18, 9, 0), end: new Date(2025, 0, 18, 14, 0) },
    ],
    additionalNotes: "Recently renovated with new appliances. Close to schools and transport links.",
  },
  {
    id: "2",
    address: "24 Lindrick Way",
    city: "Manchester",
    postcode: "M4 5BG",
    description:
      "Modern 2-bedroom apartment with balcony and city views. Secure building with concierge service. Underground parking available.",
    price: 320000,
    bedrooms: 2,
    bathrooms: 1,
    propertyType: "Apartment",
    tenure: "Leasehold",
    leaseYearsRemaining: 120,
    serviceCharge: 1800,
    groundRent: 300,
    agentConfirmationRequired: false,
    availabilityWindows: [
      { start: new Date(2025, 0, 14, 9, 0), end: new Date(2025, 0, 14, 17, 0) },
      { start: new Date(2025, 0, 17, 10, 0), end: new Date(2025, 0, 17, 15, 0) },
    ],
    additionalNotes: "Secure building with concierge service. Underground parking available.",
  },
  {
    id: "3",
    address: "3 Box Park",
    city: "Bristol",
    postcode: "BS1 4TF",
    description:
      "Unique 4-bedroom detached house with large garden and garage. Recently refurbished with high-end finishes. Large garden perfect for families.",
    price: 550000,
    bedrooms: 4,
    bathrooms: 3,
    propertyType: "House",
    tenure: "Freehold",
    leaseYearsRemaining: null,
    serviceCharge: null,
    groundRent: null,
    agentConfirmationRequired: true,
    availabilityWindows: [
      { start: new Date(2025, 0, 13, 11, 0), end: new Date(2025, 0, 13, 16, 0) },
      { start: new Date(2025, 0, 19, 12, 0), end: new Date(2025, 0, 19, 17, 0) },
    ],
    additionalNotes: "Recently refurbished with high-end finishes. Large garden perfect for families.",
  },
]

export function PropertiesDashboard() {
  const [properties, setProperties] = useState(initialProperties)
  const [selectedProperty, setSelectedProperty] = useState(null)
  const [showAddForm, setShowAddForm] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")

  const handlePropertyUpdate = (updatedProperty) => {
    setProperties(properties.map((p) => (p.id === updatedProperty.id ? updatedProperty : p)))
    setSelectedProperty(updatedProperty)
  }

  const handleAddProperty = (newProperty) => {
    const propertyWithId = {
      ...newProperty,
      id: `${properties.length + 1}`,
    }
    setProperties([...properties, propertyWithId])
    setShowAddForm(false)
  }

  const filteredProperties = properties.filter(
    (property) =>
      property.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
      property.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
      property.postcode.toLowerCase().includes(searchQuery.toLowerCase()) ||
      property.description.toLowerCase().includes(searchQuery.toLowerCase()),
  )

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      maximumFractionDigits: 0,
    }).format(price)
  }

  return (
    <div className="p-6">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
          <h1 className="text-2xl font-bold">Properties</h1>
          <Button onClick={() => setShowAddForm(true)} className="flex items-center gap-1">
            <Plus className="h-4 w-4" />
            Add Property
          </Button>
        </div>

        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4" />
          <Input
            placeholder="Search by address, city, or postcode..."
            className="pl-10"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {searchQuery && (
            <Button
              variant="ghost"
              size="icon"
              className="absolute right-2 top-1/2 transform -translate-y-1/2 h-6 w-6"
              onClick={() => setSearchQuery("")}
            >
              <X className="h-4 w-4" />
            </Button>
          )}
        </div>

        {filteredProperties.length === 0 ? (
          <div className="flex flex-col items-center justify-center py-12 text-center">
            <Home className="h-12 w-12 text-muted-foreground mb-4" />
            <h3 className="text-lg font-medium">No properties found</h3>
            <p className="text-muted-foreground mt-1">Try adjusting your search or add a new property</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-4">
            {filteredProperties.map((property) => (
              <Dialog key={property.id}>
                <DialogTrigger asChild>
                  <div className="cursor-pointer group">
                    <div className="border rounded-lg overflow-hidden transition-all hover:shadow-md">
                      <div className="flex">
                        {/* Left side - Colored banner */}
                        <div className={`w-24 h-auto ${
                          property.propertyType === "House" 
                            ? "bg-emerald-500" 
                            : property.propertyType === "Apartment"
                            ? "bg-blue-500"
                            : "bg-indigo-500"
                        } flex flex-col items-center justify-center py-4`}>
                          <div className="text-white">
                            {property.propertyType === "Apartment" ? (
                              <Building2 className="h-7 w-7" />
                            ) : (
                              <Home className="h-7 w-7" />
                            )}
                          </div>
                          <div className="text-white text-sm mt-2 font-medium">
                            {property.propertyType}
                          </div>
                        </div>

                        {/* Right side - Property details */}
                        <div className="flex-1 p-4">
                          <div className="flex flex-col md:flex-row justify-between gap-2">
                            <div>
                              <h2 className="text-lg font-bold">{property.address}</h2>
                              <p className="text-muted-foreground text-sm flex items-center">
                                <MapPin className="h-3.5 w-3.5 mr-1" />
                                {property.city}, {property.postcode}
                              </p>
                            </div>
                            <div className="text-lg md:text-xl font-bold text-foreground">
                              {formatPrice(property.price)}
                            </div>
                          </div>

                          <div className="flex items-center gap-4 my-3">
                            <div className="flex items-center gap-1">
                              <Bed className="h-4 w-4 mr-1" />
                              <span>{property.bedrooms} Beds</span>
                            </div>
                            <div className="flex items-center gap-1">
                              <Bath className="h-4 w-4 mr-1" />
                              <span>{property.bathrooms} Baths</span>
                            </div>
                            <Badge variant="outline" 
                              className={`text-xs px-2 py-0.5 rounded-md ${
                                property.tenure === "Freehold" 
                                  ? "border-emerald-500 text-emerald-700" 
                                  : "border-gray-300 text-gray-700"
                              }`}>
                              {property.tenure}
                            </Badge>
                          </div>

                          <p className="text-muted-foreground text-sm line-clamp-2">{property.description}</p>

                          <div className="flex items-center justify-between mt-4 pt-3 border-t">
                            <div className="flex items-center">
                              <Calendar className="h-4 w-4 mr-2" />
                              <span className="text-sm text-muted-foreground">
                                {property.availabilityWindows.length} viewing slots
                              </span>
                            </div>
                            <div>
                              {property.agentConfirmationRequired ? (
                                <span className="text-sm flex items-center">
                                  <ClipboardCheck className="h-4 w-4 mr-1.5 text-muted-foreground" />
                                  Agent confirmation
                                </span>
                              ) : (
                                <span className="text-sm flex items-center">
                                  <ClipboardCheck className="h-4 w-4 mr-1.5 text-muted-foreground" />
                                  Instant booking
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogTrigger>
                <DialogContent className="max-w-4xl w-[90vw]">
                  <DialogHeader>
                    <DialogTitle className="text-2xl">
                      {property.address}, {property.city}
                    </DialogTitle>
                  </DialogHeader>
                  <PropertyDetails property={property} onUpdate={handlePropertyUpdate} />
                  <div className="flex justify-end gap-2 mt-4">
                    <Button className="gap-2">
                      <Mail className="h-4 w-4" />
                      Contact
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            ))}
          </div>
        )}
      </div>

      {/* Add Property Dialog */}
      <Dialog open={showAddForm} onOpenChange={setShowAddForm}>
        <DialogContent className="max-w-4xl w-[90vw]">
          <DialogHeader>
            <DialogTitle className="text-2xl">Add New Property</DialogTitle>
          </DialogHeader>
          <AddPropertyForm onSubmit={handleAddProperty} />
        </DialogContent>
      </Dialog>
    </div>
  )
} 